import React, { Component } from 'react';

export default class PageNotFoundContainer extends Component {

    render() {
        return (<div>
            <h1 style={{ color: "white" }}>404</h1>
            <p>Oops! Something is wrong.</p>
            <button className="buttoncss"><i className="icon-home"></i> Go back in initial page, is better.</button>
        </div>)
    }
}