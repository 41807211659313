import request from '../api/request';
import userAuth from '../api/authActions';

export function postSellCommodity(data) {

    // {
    //     "userid":1,
    //     "variety":"abc",
    //     "quantity":7.8,
    //     "unit":"kg",
    //     "color":"red",
    //     "moisture":"6.8",
    //     "shelf_life":" 30 'days",
    //     "foreign_particles":" external ",
    //     "fob_price_in_inr": 4000.5
    // }
    return request({
        url: "/seller/postSellCommodity",
        method: 'post',
        headers: { 'Authorization': 'Bearer ' + userAuth.getToken() },
        data: data
    });
}

export function postSellCommodityEnquiry(data) {
    // {
    //     sell_id : number
    //     buyer_id : number
    //     buyer_quantity : number
    //     buyer_unit : string
    //     buyer_shipment : string
    //     buyer_payment_type : string
    //     buyer_advance_percentage : number
    //     buyer_comments : string
    //     offer_status : string
    //     buyer_price: number
    //     buyer_currency : string
    // }
    return request({
        url: "/buyer/postSellCommodityEnquiry",
        method: 'post',
        headers: { 'Authorization': 'Bearer ' + userAuth.getToken() },
        data: data
    });
}

// http://localhost:3000/seller/enquiryList/1?offset=0&limit=10
export function getSellerEnquiryList(id, param) {
    return request({
        url: "/seller/enquiryList/" + id,
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + userAuth.getToken() },
        params: param
    });
}

// http://localhost:3000/buyer/enquiryList/1?offset=0&limit=10
export function getBuyerEnquiryList(id, param) {
    return request({
        url: "/buyer/enquiryList/" + id,
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + userAuth.getToken() },
        params: param
    });
}

export function postBuyCommodity(data,id) {
    // {
    //     sell_id : number
    //     buyer_id : number
    //     buyer_quantity : number
    //     buyer_unit : string
    //     buyer_shipment : string
    //     buyer_payment_type : string
    //     buyer_advance_percentage : number
    //     buyer_comments : string
    //     offer_status : string
    //     buyer_price: number
    //     buyer_currency : string
    // }
    return request({
        url: `/buyer/postRequirement/${id}`,
        method: 'post',
        headers: { 'Authorization': 'Bearer ' + userAuth.getToken() },
        data: data
    });
}




     

