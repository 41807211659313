import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Loader from '../common/Loader';
import { getSellerEnquiryList } from '../../services/postSellService';
import CounterOfferModal from '../modals/CounterOfferModal';
import { makeCounterOffer } from '../../services/commonService';
import right_arrow from '../../assests/icons/right_arrow.svg';
import { Auth } from 'aws-amplify';
import NoEnquires from '../common/NoEnquires';
import phone from '../../assests/icons/phone.svg';
import { withRouter } from 'react-router-dom';


const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        verticalAlign: "middle",
        marginRight: theme.spacing.unit,
        fontSize: 10,
    },
    tableCell: {
        paddingLeft: '4px',
        paddingRight: '1px',
        textAlign: 'center',
        maxWidth: '200px',
        fontSize: '13px !important'
    },
    titleText: { width: '50%', textAlign: 'left', paddingLeft: '15px', paddingTop: '7px', fontFamily: 'lato !important', },
    defaultTemplate: { height: '30vh', paddingTop: '10vh', },
    defaultSpan: { display: 'grid', fontSize: '25px' },
    defaultIcon: { fontSize: '65px', color: "#384952" },
    editIcon: { fontSize: '20px', color: "#1e459c", paddingLeft: 3, cursor: 'pointer', marginRight: '2px', float: 'left' },
    infoIcon: { color: '#d46262', fontSize: '18px', cursor: 'pointer' },
    cellDiv: {
        maxWidth: '180px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    root: {
        width: '100%',
        minHeight: '80vh'
    },
    lightTooltip: {
        fontSize: '15px',
        maxWidth: 'none',
    },
    info: {
        fontSize: '14px',
        marginLeft: '5px',
        color: '#fd0671',
        cursor: 'pointer'
    },
    textEllpses: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxWidth: "110px",
        lineHeight: "18px",
        display: "block"
    },
    textEllipseForAppid: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        direction: 'rtl',
        // textAlign: 'left',
        maxWidth: '75px',
        textOverflow: 'ellipsis'
    }
});


class SellerEnquiryList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tableHeadData: ["Trade Number", "Offer number", "Trade Status", "Commodity", "Quantity requested by Buyer", "Terms quoted by buyer", "Offer Status", "your counter offer", "Do you want to accept the counter offer or Reject the counter Offer?"],
            tableHeadDataKeys: ["id", "app_order_id", "buyer_name", "supplier_name", "unsettled_amount_pltf", "createdtime", "source_location", "commodity"],
            tableBodyData: [
            ],
            totalDataCount: this.props.totalDataCount || 0,
            currentOffset: this.props.currentOffset || 0,
            rawTableBodyData: [
                { "tradeNo": "A", "tradeStatus": "B", "Quantity": "Quantity", "Terms": "Terms", "Offer": "Offer", "youroffer": "ok", "status": "A" }
            ],
            searchedText: "",
            editableData: {},
            showServerDialog: false,
            showOptionModal: false,
            anchorEl: null,
            showUserModal: false,
            userData: {},
            // userId: null,
            payload: null,
            showAddModal: false,
            infoData: null,
            open: false,

            rowsPerPage: 50,
            page: 0,

            showSupportingInvoiceModal: false,
            invoiceModalData: [],

            showAddOrderModal: false,

            showEditDataModal: false,
            commodityList: { options: [], optionN_E: {}, optionE_N: {} },
            showUploader: false,

            showPayoutModal: false,
            payoutData: undefined,

            showUserInfo: false,
            userInfoData: undefined,
            isLimitUpdate: false,
            userId: undefined,

            showSweetAlert: false,
            sweetAlertData: {
                "type": "",
                "title": "",
                "text": ""
            },

            sortKeys: this.props.sortKeys,
            sortKeysOrder: this.props.sortKeysOrder,
            sortingEnabled: ["id", "unsettled_amount_pltf", "createdtime", "bijak_amt"],
            showPaymentsOfOrderModal: false,
            orderInfo: undefined,
            isTableDataLoading: true,
            sellerInfoData: [],
            showCounterOfferModal: false,
            selectedEnquery: {},
            showLoader: false,
            sellerId: '',
            loading: false
        }
        // this.getCommodityNames();
    }

    componentDidMount() {
        this.setState({
            showLoader: true
        }, () => {
            this.getId();
        })

    }


    async getId() {
        await Auth.currentAuthenticatedUser().then((user) => {
            console.log(user)
            if (user.attributes && user.attributes["custom:id"]) {
                this.setState({ sellerId: user.attributes["custom:id"] }, () => {
                    this.getSellerEnquiryList(user.attributes["custom:id"]);
                })
            }
        }).catch((err) => {
            this.props.history.push("/")
        });
    }

    async getSellerEnquiryList(sellerId) {
        try {
            // let sellerId = '1';
            let param = { offset: 0, limit: 100 }
            let resp = await getSellerEnquiryList(sellerId, param);
            console.log(resp);
            let data = [];
            if (resp && resp.type === 1) {
                data = resp.data;
            }
            this.setState({ tableBodyData: data, showLoader: false });
        } catch (err) {
            console.log(err);
        }
    }

    getTableCellClass(classes, index) {
        return classes.tableCell;
    }


    showPaymentsHistory(data) {
        this.setState({
            orderInfo: data,
            showPaymentsOfOrderModal: true
        })
    }

    async makeCounterOffer(id, status) {
        try {
            let payload =
            {
                id: id,
                offer_status: status
            }
            let resp = await makeCounterOffer(payload);
            console.log(resp);
            if (resp && resp.type === 1) {

            }

            // alert(resp.message);

            this.setState({ showLoader: true, loading: false }, () => {
                this.getSellerEnquiryList(this.state.sellerId);
            })

        } catch (err) {
            console.log(err);
        }
    }

    getTheStatusClass(obj) {
        try {

            if (obj.offer_status && obj.offer_status === "counter offer" &&
                obj.seller_co_status && obj.seller_co_status === "bijak") {
                return 'enquiryStatus';
            } else if (obj.offer_status && obj.offer_status === "counter offer" &&
                obj.seller_co_status && obj.seller_co_status === "accepted") {
                return 'enquiryStatus';
            } else if (obj.offer_status && obj.offer_status === "counter offer" &&
                obj.seller_co_status && obj.seller_co_status === "rejected") {
                return 'enquiryStatus_red';
            } else if (obj.offer_status && obj.offer_status === "pending") {
                return 'enquiryStatus_grey';
            } else if (obj.offer_status && obj.offer_status === "accepted") {
                return 'enquiryStatus';
            } else if (obj.offer_status && obj.offer_status === "rejected") {
                return 'enquiryStatus_red';
            } if (obj.offer_status && obj.offer_status === "counter offer") {
                return "enquiryStatus_yellow";
            } else {
                console.log(obj.offer_status + "<=>" + obj.seller_co_status)
                return 'enquiryStatus';
            }
        } catch (error) {
            console.log(error);
            return 'enquiryStatus_grey';
        }
    }


    getStatus(obj) {

        if (obj.offer_status && obj.offer_status === "counter offer" &&
            obj.seller_co_status && obj.seller_co_status === "bijak") {
            return "Informed Bijak";
        } else if (obj.offer_status && obj.offer_status === "counter offer" &&
            obj.seller_co_status && obj.seller_co_status === "accepted") {
            return "Accepted by Buyer";
        } else if (obj.offer_status && obj.offer_status === "counter offer" &&
            obj.seller_co_status && obj.seller_co_status === "rejected") {
            return "Rejected by Buyer";
        } else

            if (obj.offer_status && obj.offer_status === "pending") {
                return "Offer Received";
            } else if (obj.offer_status && obj.offer_status === "accepted") {
                return "Accepted by Seller";
            } else if (obj.offer_status && obj.offer_status === "rejected") {
                return "Rejected by Seller";
            } if (obj.offer_status && obj.offer_status === "counter offer") {
                return "Counter Offer By Seller";
            } else {
            return obj.offer_status.toUpperCase();
        }
    }

    showDetails(e, obj) {
        e.preventDefault();
        try {
            this.props.history.push({
                pathname: '/ProductDetailsContainer',
                search: '',
                state: { detail: obj }
            })
        } catch (error) {
            console.log(error)
        }
    }


    render() {
        // const { classes } = this.props;
        const { showLoader, tableBodyData, loading } = this.state;
        return (
            <>

                {!showLoader && tableBodyData && tableBodyData.length > 0 && <>
                    {tableBodyData && tableBodyData.length > 0 &&
                        tableBodyData.map((obj, index) =>
                            <div key={index + "small_poi"} className="card mt-3 d_lg_none_sm myPost_card" style={{ cursor: 'pointer' }}>
                                <div className="card-body ">
                                    <div className="row" >
                                        <div className="col-6">
                                            <p className="font-weight-bold mypost_id cursor-pointer" onClick={(e) => this.showDetails(e, obj)} >#{obj.id}</p>
                                        </div>
                                        {/* <div className="col-6">
                                            <div className={obj.trade_status && obj.trade_status.toLowerCase() === "live" ? "mypost_status_box" : "mypost_status_box_closed"}>
                                                <p className={" cus_light_green text-right " + obj.trade_status && obj.trade_status.toLowerCase() === "live" ? "mypost_status" : "mypost_status_closed"}    >{obj.trade_status && obj.trade_status.toUpperCase()}</p>
                                            </div>
                                        </div> */}

                                        <div className="col-9 mypost_equity_terms">
                                            <h5 className="mypost_comm_desc">{obj.name}</h5>
                                        </div>

                                        <div className="col-12 pt-3">
                                            <p className="font-weight-normal mypost_volume">VOLUME PER MONTH: &nbsp;
                                        <span className="font-weight-bold mypost_volume_val">{obj.buyer_quantity}&nbsp;{obj.buyer_unit}</span>
                                            </p>
                                        </div>

                                        {obj.trade_status && obj.trade_status.toLowerCase() !== "live" &&
                                            <div className="col-12 mt-2 mb-1">
                                                <span className=" font-weight-normal mypost_equity_terms">Terms Quoted by Buyer</span>
                                            </div>}

                                        <div className="col-12">
                                            <div><span className="myPost_sub">Est. Price:</span><span className="commodityWithTitle">
                                                {/* {obj.price}&nbsp;{obj.price_currency}&nbsp;per&nbsp;{obj.price_unit} */}
                                                &nbsp;{obj.buyer_price + " " + obj.buyer_currency + " per " + obj.buyer_price_unit}
                                            </span></div>
                                            <div><span className="myPost_sub">Shipment:</span><span className="commodityWithTitle">{obj.buyer_shipment}</span></div>
                                            <div><span className="myPost_sub">Payment:</span> <span className="commodityWithTitle">{obj.buyer_payment_type}</span></div>
                                        </div>

                                        <div className="col-12 mt-1 mb-1">
                                            <span className="font-weight-normal mypost_equity_terms">Offer Status</span>
                                        </div>

                                        <div className="col-12 mt-1 mb-1 p-0">
                                            <div className={"th_title " + this.getTheStatusClass(obj)} style={{ width: "fit-content" }}>
                                                {this.getStatus(obj)}
                                            </div>
                                        </div>

                                        <div className="col-12 mt-1 mb-1">
                                            <span className="font-weight-normal mypost_equity_terms">Your Offer to Buyer</span>
                                        </div>

                                        {obj.offer_status && obj.offer_status !== 'counter offer' ?
                                            <div className="col-12">
                                                <div><span className="myPost_sub">Est. Price:</span><span className="commodityWithTitle">
                                                    {/* {obj.price}&nbsp;{obj.price_currency}&nbsp;per&nbsp;{obj.price_unit} */}
                                                    &nbsp; {obj.price_currency === "INR" ? "₹ " + obj.price + " per " + obj.price_unit :
                                                        obj.price + " " + obj.price_currency + " per " + obj.price_unit}
                                                </span></div>
                                                <div><span className="myPost_sub">Payment:</span> <span className="commodityWithTitle">{obj.payment_type}</span></div>
                                            </div> :
                                            <div className="col-12">
                                                <div><span className="myPost_sub">Est. Price:</span><span className="commodityWithTitle">
                                                    {/* {obj.price}&nbsp;{obj.price_currency}&nbsp;per&nbsp;{obj.price_unit} */}
                                                &nbsp; {obj.seller_co_price + " " + obj.seller_co_currency + " per " + obj.seller_co_price_unit}
                                                </span></div>
                                                {/* <div><span className="myPost_sub">Shipment:</span><span className="commodityWithTitle">{obj.shipment}</span></div> */}
                                                <div><span className="myPost_sub">Payment:</span> <span className="commodityWithTitle">{obj.seller_co_payment_type}</span></div>
                                            </div>}

                                        {obj.offer_status && obj.offer_status === 'pending' &&
                                            <div className="col-12 mt-4">
                                                <button className="btn btn-outline-success border-white text-white"
                                                    disabled={loading}
                                                    onClick={() => {
                                                        this.setState({ loading: true }, () => {
                                                            this.makeCounterOffer(obj.id, 'accepted')
                                                        })
                                                    }}
                                                    style={{ background: "green" }}>
                                                    Accept <img src={right_arrow} alt="" /></button>
                                                <button className="btn btn-default rejectBtn mt-1 ml-2"
                                                    disabled={loading}
                                                    onClick={() => {
                                                        this.setState({ loading: true }, () => {
                                                            this.makeCounterOffer(obj.id, 'rejected')
                                                        })
                                                    }}>
                                                    Reject
                                                                    </button>
                                                <button className="btn btn-default send_offer_btn mt-1 ml-2"
                                                    onClick={() => { this.setState({ showCounterOfferModal: true, selectedEnquery: obj }) }}>
                                                    Send offer
                                                                    </button>
                                            </div>}
                                        {/* : <div>N/A</div>} */}

                                    </div>
                                </div>
                            </div>)}
                    <div className="card d575_none">
                        <div className="card-body " style={{ padding: "0px" }}>
                            <table className="table table-hover">
                                <thead style={{ background: "#E7F6EC" }}>
                                    <tr>
                                        <th className="th_title th_120 myPosts_thead">Trade Number</th>
                                        <th className="th_title th_120 myPosts_thead">Commodity</th>
                                        <th className="th_title th_100 myPosts_thead" >Quantity Requested by Buyer</th>
                                        <th className="th_title th_430 myPosts_thead">Terms Quoted by Buyer</th>
                                        <th className="th_title th_100 myPosts_thead">Offer Status</th>
                                        <th className="th_title th_430 myPosts_thead">Your Offer to Buyer</th>
                                        <th className="th_title th_140 myPosts_thead">Take Action on Trade</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableBodyData && tableBodyData.length > 0 &&
                                        tableBodyData.map((obj, index) =>
                                            <tr key={index + "ss"}>
                                                <td className="th_title cursor-pointer" style={{ textAlign: "center" }} onClick={(e) => this.showDetails(e, obj)}>{obj.id}</td>
                                                <td className="commodityWithTitle">{obj.name}</td>
                                                <td className="commodityWithTitle">{obj.buyer_quantity}&nbsp;{obj.buyer_unit}</td>
                                                <td className="enquiryTerms">
                                                    <div style={{ display: "grid" }}>
                                                        <div>
                                                            <span className="th_title">Est. Price:</span>
                                                            <span className="commodityWithTitle">&nbsp;{obj.buyer_price + " " + obj.buyer_currency + " per " + obj.buyer_price_unit}
                                                            </span>
                                                        </div>
                                                        <div><span className="th_title">Shipment:</span><span className="commodityWithTitle">{obj.buyer_shipment}</span></div>
                                                        <div><span className="th_title">Payment:</span> <span className="commodityWithTitle">{obj.buyer_payment_type}</span></div>
                                                    </div>
                                                </td>
                                                <td className="th_title" ><div>
                                                    <div className={this.getTheStatusClass(obj)}>
                                                        {this.getStatus(obj)}
                                                    </div>
                                                </div></td>
                                                <td className="th_title" style={{ fontWeight: "bold" }}>
                                                    {obj.offer_status && obj.offer_status !== 'counter offer' ?
                                                        <div style={{ display: "grid" }}>
                                                            <div>
                                                                <span className="th_title">Est. Price:</span>
                                                                <span className="commodityWithTitle">&nbsp;{obj.price + " " + obj.price_currency + " per " + obj.price_unit}
                                                                </span></div>
                                                            <div><span className="th_title">Payment:</span>&nbsp;<span className="commodityWithTitle">{obj.payment_type}</span></div>
                                                        </div> :
                                                        <div style={{ display: "grid" }}>
                                                            <div>
                                                                <span className="th_title">Est. Price:</span>
                                                                <span className="commodityWithTitle">&nbsp;{obj.seller_co_price + " " + obj.seller_co_currency + " per " + obj.seller_co_price_unit}
                                                                </span></div>
                                                            <div><span className="th_title">Payment:</span>&nbsp;<span className="commodityWithTitle">{obj.seller_co_payment_type}</span></div>
                                                        </div>
                                                    }
                                                </td>
                                                <td className="th_title" style={{ color: "#198548" }}>
                                                    {obj.offer_status && obj.offer_status === 'pending' ?
                                                        <div>
                                                            <button className="btn btn-outline-success border-white text-white"
                                                                disabled={loading}
                                                                onClick={() => {
                                                                    this.setState({ loading: true }, () => {
                                                                        this.makeCounterOffer(obj.id, 'accepted')
                                                                    })
                                                                }}
                                                                style={{ background: "green" }}>
                                                                Accept <img src={right_arrow} alt="" /></button>
                                                            <button className="btn btn-default rejectBtn mt-1"
                                                                disabled={loading}
                                                                onClick={() => {
                                                                    this.setState({ loading: true }, () => {
                                                                        this.makeCounterOffer(obj.id, 'rejected')
                                                                    })
                                                                }}>
                                                                Reject
                                                                    </button>
                                                            <button className="btn btn-default send_offer_btn mt-1"
                                                                onClick={() => { this.setState({ showCounterOfferModal: true, selectedEnquery: obj }) }}>
                                                                Send offer
                                                                    </button>
                                                        </div> :
                                                        <div style={{ textTransform: "uppercase" }}> -
                                                        </div>}
                                                </td>
                                            </tr>)}
                                </tbody>
                            </table>
                        </div>
                    </div> </>}

                {!showLoader && tableBodyData && tableBodyData.length === 0 &&
                    <NoEnquires
                        redirectToPath={''}
                        buttonText={""}
                        titleText={"No Trades"}
                        headerTxt={"Please submit enquiries or send offers to start negotiations."} />}

                {showLoader && <Loader background="#f1f4f7" />}
                {!showLoader && tableBodyData && tableBodyData.length > 0 &&
                    <div className="pt-2 d575_none" >
                        <div className="row mb-4" style={{ paddingTop: "24px" }}>
                            <label className="col-sm-3 footer_mypost">Connect with Bijak Team on </label>
                            <span className="col-sm-3 font-weight-bold footer_mypost_number"><img src={phone} alt="" /> &nbsp;+91-8585958484</span>
                        </div>
                    </div>}
                {!showLoader && tableBodyData && tableBodyData.length > 0 &&
                    <div className="pt-2 d_lg_none_sm" >
                        <div className="row mb-4" style={{ paddingTop: "24px", textAlign: 'center' }}>
                            <label className="col-sm-3 footer_mypost">Connect with Bijak Team on </label>
                            <span className="col-sm-3 font-weight-bold footer_mypost_number"><img src={phone} alt="" /> &nbsp;+91-8585958484</span>
                        </div>
                    </div>}
                {this.state.showCounterOfferModal &&
                    <CounterOfferModal
                        open={this.state.showCounterOfferModal}
                        selectedEnquery={this.state.selectedEnquery}
                        onEnquiryAdded={(event) => {
                            this.setState({ showCounterOfferModal: false, showLoader: true });
                            this.getSellerEnquiryList(this.state.sellerId);
                        }}
                        onAddModalCancel={(event) => {
                            this.setState({ showCounterOfferModal: false });
                            // this.props.onAddOrderModalClosed()
                        }}
                    />}

            </>
        );
    }
}

SellerEnquiryList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(SellerEnquiryList));