import React from 'react';
// import bijakLogo from '../../assests/icons/logo.svg';
import bijakLogo from '../../assests/images/bijakglobal_logo.svg';
import { Auth } from 'aws-amplify';
import SearchContainer from '../../views/common/SearchContainer';
import CommonUtil from '../../shared/CommonUtil';
import contacticon from '../../assests/img/icons/user.png';
import user_sm from '../../assests/img/icons/user_sm.png';
import post_enquiry from '../../assests/img/icons/post_enquiry.svg';
import enquiries from '../../assests/img/icons/enquiries.png';
import logout_img from '../../assests/icons/logout_img.svg';
import logout_imgdd from '../../assests/img/icons/logout.png';
import { withRouter } from 'react-router-dom';
import nav_contact from '../../assests/icons/nav_contact.svg';
import doc_nav from '../../assests/icons/doc_nav.svg';
import crop_nav from '../../assests/icons/crop_nav.svg';
import my_profile from '../../assests/icons/my_profile.svg';
import { Link, NavLink } from 'react-router-dom';
class Header extends React.Component {


  logout = async () => {
    try {
      await Auth.signOut();
      // history.push(`/login`);
      window.localStorage.setItem('loggedInUserName', '');
      window.localStorage.setItem('is_user_loggedIn', "false");
      this.switchToRoute('login');

    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  switchToRoute = (routeParam) => {
    this.closeNavBars("bijakNav");
    this.props.history.push(`/` + routeParam);
  }

  closeNavBars = (id) => {
    var element = document.getElementById(id);
    element.classList.remove("show");
  }

  closeNavAndRedirectToId = (e, modalid, divId) => {
    e.preventDefault();
    this.closeNavBars(modalid);
    if (window.screen.width < 576) {
      divId = divId + "Mob";
    }

    if (CommonUtil.CheckIfUserLoggedIn().isLoggedIn) {
      if (window.location.pathname !== "/home") {
        this.props.history.push({
          pathname: '/home',
          scrollToId: divId,
        })

      }
    } else {
      if (window.location.pathname !== "/") {
        this.props.history.push({
          pathname: '/',
          scrollToId: divId,
        });
      }
    }

    try {

      if (document.getElementById(divId)) {
        document.getElementById(divId).scrollIntoView({
          behavior: 'smooth'
        });
      }

    } catch (err) {
      console.log(err);
    }


  }


  render() {
    const userInfo = CommonUtil.CheckIfUserLoggedIn() || {};
    return (<>
      <header className="cus_bg_green header_nav fixed-top">
        <nav className="container navbar navbar-expand-lg navbar-light">
          <div className="row w-100 m-0 align-items-center">
            <div className="col-lg-3 col-8 d-flex justify-content-center justify-content-lg-start order-12 order-lg-1">
              <a className="navbar-brand" href="#">
                <img src={bijakLogo} alt="Bijak"
                  onClick={(e) => {
                    e.preventDefault();
                    if (userInfo.isLoggedIn) {
                      this.props.history.push(`/home`)
                    } else {
                      this.props.history.push(`/`)
                    }
                  }} />
              </a>
            </div>
            <div className="col-2 d-flex justify-content-start d_lg_none order-1">
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#bijakNav" aria-controls="bijakNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>

            {userInfo.isLoggedIn &&
              <a className="d-block d-lg-none order-12 col-2 d-flex justify-content-end" href="#"
                data-toggle="collapse" data-target="#bijakNavRight" aria-controls="bijakNavRight" aria-expanded="false" aria-label="Toggle navigation">
                <img src={user_sm} alt="User" />
              </a>}


            {/* ----------------------Right Drawer --------------------------------------- */}
            <div className="col-lg-9 collapse navbar-collapse p-lg-0 order-lg-12" id="bijakNavRight"
              style={{
                paddingLeft: "24px"
              }}
            >
              <div className="closebtnRight d-flex d-lg-none" style={{ display: "flex", marginTop: "28px" }}>
                <div className="navBar_username">{userInfo.loggedInUserName}</div>
                <div style={{ width: "10%", color: "#fff" }}> <div className="closebtnRight d-block d-lg-none"
                  data-toggle="collapse"
                  data-target="#bijakNavRight" aria-expanded="true">×</div></div>
              </div>



              <ul className="navbar-nav">
                <li className="nav-item">
                  <div className="nav-link d-flex cursor-pointer"

                    onClick={(e) => { e.preventDefault(); this.closeNavBars("bijakNavRight"); this.props.history.push(`/myprofile`) }}>
                    <img src={nav_contact} width="35px" alt="" /> <div className="navBarRight_option">My Profile</div>
                  </div>
                </li>
                <li className="nav-item mt-3">
                  <div className="nav-link d-flex cursor-pointer"
                    onClick={(e) => { e.preventDefault(); this.closeNavBars("bijakNavRight"); this.props.history.push(`/SellerEnquiryContainer`) }}>
                    <img src={crop_nav} width="35px" alt="" /><div className="navBarRight_option">Post Sell Enquiry</div>
                  </div>
                </li>
                <li className="nav-item mt-3">
                  <div className="nav-link d-flex cursor-pointer"
                    onClick={(e) => { e.preventDefault(); this.closeNavBars("bijakNavRight"); this.props.history.push(`/BuyerEnquiryContainer`) }}>
                    <img src={crop_nav} width="35px" alt="" /><div className="navBarRight_option">Post Buy Enquiry</div>
                  </div>
                </li>
                <li className="nav-item mt-3">
                  <div className="nav-link d-flex cursor-pointer"
                    onClick={(e) => { e.preventDefault(); this.closeNavBars("bijakNavRight"); this.props.history.push(`/myposts`) }}>
                    <img src={doc_nav} width="35px" alt="" /><div className="navBarRight_option">My Enquiries</div>
                  </div>
                </li>


                <li className="nav-item mt-3">
                  <div className="nav-link d-flex cursor-pointer"
                    onClick={(e) => { e.preventDefault(); this.closeNavBars("bijakNavRight"); this.logout(); }}>
                    <img src={logout_img} width="35px" alt="" /> <div className="navBarRight_option">Logout</div>
                  </div>
                </li>
              </ul>
            </div>
            {/* --------------------------------------Right Drawer End--------------------------------------- */}
            {/* --------------------------------------Left Drawer --------------------------------------- */}

            <div className="col-lg-9 collapse navbar-collapse p-lg-0 order-lg-12" id="bijakNav">
              <div className="closebtn d-block d-lg-none"
                data-toggle="collapse"
                data-target="#bijakNav" aria-expanded="true">×</div>
              <ul className="navbar-nav mr-auto" id="nav_left">
                {/* mobile ---view */}
                <li className="nav-item">
                  {/* <Link className="nav-link" href="/login" >About Us</Link> */}
                  {/* <Link className="nav-link"  to="/login">About Us</Link> */}
                  <NavLink className="nav-link" to="/" onClick={(e) => this.closeNavAndRedirectToId(e, "bijakNav", "aboutus")}>About Us</NavLink>
                </li>
                <li className="nav-item">
                  {/* <a className="nav-link" href="#" data-toggle="collapse" data-target="#bijakNav" aria-expanded="true" >Features</a> */}
                  <NavLink className="nav-link" to="/" onClick={(e) => this.closeNavAndRedirectToId(e, "bijakNav", "features")} >Features</NavLink>
                </li>
                <li className="nav-item">
                  {/* <a className="nav-link" href="#" data-toggle="collapse" data-target="#bijakNav" aria-expanded="true"  >Contact Us</a> */}
                  <NavLink className="nav-link" to="/" onClick={(e) => this.closeNavAndRedirectToId(e, "bijakNav", "contactus")} >Contact Us</NavLink>

                </li>



                {/* <li className="nav-item d_lg_none">
                  <NavLink className="nav-link" to="/" onClick={() => this.closeNavBars("bijakNav")}  >Blog & News</NavLink>
                </li> */}
                {userInfo.isLoggedIn &&
                  <li className="nav-item">
                    <a className="nav-link" href="#" data-toggle="collapse"
                      // data-target="#bijakNav"
                      aria-expanded="true"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/BuyerEnquiryList`);
                      }}>My Trades</a>
                  </li>}
              </ul>
              {!userInfo.isLoggedIn && <div>
                <button className="btn btn-outline-success border-white text-white"
                  onClick={() => this.switchToRoute('signUp')}>Sign Up for Free</button>
                <button className="btn btn-default ml-3" style={{ background: "#FFFFFF" }}
                  onClick={() => this.switchToRoute('login')}>Login</button>
              </div>}
              {userInfo.isLoggedIn &&
                <div className="dropdown d575_none d768_none d992_none show">

                  <a className="btn bg-white dropdown-toggle px-3 py-2" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img src={contacticon} alt="User" />
                    <span className="px-2 text-dark user_name name_ellipse">{userInfo.loggedInUserName}</span>
                  </a>

                  <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">

                    <a className="dropdown-item" href="#"
                      onClick={(e) => { e.preventDefault(); this.props.history.push(`/myprofile`) }}>
                      <img src={my_profile} className="pr-3" width="30" alt="" />My Profile
                </a>
                    <a className="dropdown-item" href="#"
                      onClick={(e) => { e.preventDefault(); this.props.history.push(`/SellerEnquiryContainer`) }}>
                      <img src={post_enquiry} className="pr-3" width="30" height="21px" style={{ objectFit: "cover" }} alt="" />Post Sell Enquiry
                    </a>
                    <a className="dropdown-item" href="#"
                      onClick={(e) => { e.preventDefault(); this.props.history.push(`/BuyerEnquiryContainer`) }}>
                      <img src={post_enquiry} className="pr-3" width="30" height="21px" style={{ objectFit: "cover" }} alt="" />Post Buy Enquiry
                    </a>
                    <a className="dropdown-item" href="#"
                      onClick={(e) => { e.preventDefault(); this.props.history.push(`/myposts`) }}>
                      <img src={enquiries} className="pr-3" width="30" alt="" />My Enquiries</a>
                    <a className="dropdown-item" href="#"
                      onClick={(e) => { e.preventDefault(); this.logout() }}>
                      <img src={logout_imgdd} className="pr-3" width="30" alt="" />Logout</a>
                  </div>
                </div>}
            </div>

            {/* --------------------------------------Left Drawer End--------------------------------------- */}

          </div>
        </nav>
      </header>

      <SearchContainer onProductSelected={(obj) => {
        CommonUtil.saveDataToLocal("SubListCommodity", obj);
        CommonUtil.saveDataToSession("SubListCommodity", {
          commodity_id: [obj.id],
          commodityNames: [obj.name],
          offerExpiry: "",
          currency: "INR",
          offset: 0,
          limit: 50
        });

        this.props.history.push({
          pathname: '/SubListCommodity',
          search: '',
          state: { detail: obj }
        })
      }} />
    </>
    )
  }
}

export default withRouter(Header);