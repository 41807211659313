import React, { Component, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import LoginContainer from '../views/login/LoginContainer';
import PageNotFoundContainer from '../views/common/PageNotFoundContainer';
import MainPanel from './MainPanel';
import appRoutes from './RouteMappings';
// import LoginContainer from '../views/login/LoginContainer';
// import SignUpContainer from '../views/signup/SignUpContainer';
// import PreLoginPage from '../views/common/PreLoginPage';
import ScrollIntoView from './ScrollIntoView';
import userAuth from '../api/authActions';
import Auth from '@aws-amplify/auth';
// let isloggedin = false;

export default class RouteHandeler extends Component {

  async componentDidMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    userAuth.startTokenGenaerationAfter45Min();
    userAuth.setup();

  }

  UNSAFE_componentWillMount() {
    // Utils.setDbName(this.state.providerName);
    Auth.currentAuthenticatedUser()
      .then(user => {
        var authk = user.signInUserSession.idToken.jwtToken;
        var username = user.signInUserSession.idToken.payload.name;
        // window.sessionStorage.setItem('bijak_token', authk);
        // window.sessionStorage.setItem('username', username);
        window.localStorage.setItem('loggedInUserName', user.attributes.name);
        var authk = user.signInUserSession.idToken.jwtToken;
        window.localStorage.setItem('bijakGlobalToken', authk);
        window.localStorage.setItem('is_user_loggedIn', "true");
        window.localStorage.setItem("bijakGlobalExpTime", (new Date()).getTime());
        // this.getBijakAppVersion();
      })
      .catch(err => {
        console.log(err);
        window.localStorage.removeItem('bijakGlobalToken');
        window.localStorage.removeItem('username');
      });
  }


  render() {
    console.log("------------------------------------------------------------------------------------------------------")
    return (
      <Router
      // basename={"/optionalString"}
      >
        <ScrollIntoView>
          <Suspense fallback={<h1>Still Loading…</h1>}>
            <Switch>

              {/* <Route exact path="/" render={() => (<Redirect to="/login" />)} /> */}
              {/* <Route exact path="/" component={PreLoginPage} />
              <Route exact path="/forgotpassword" component={DummyContainer} />
              <Route exact path="/signUp" component={SignUpContainer} />
              <Route path="/login" component={LoginContainer} /> */}
              {/* {appRoutes.preLoginUrls.map((val, index) =>
              <Route key={val.redirectPath} path={val.path} exact component={PreLoginPanel} />
            )} */}

              {appRoutes.routeMappings.map((val, index) =>
                <Route key={val.sideNaveName} path={val.path} exact component={MainPanel} />
              )}

              {appRoutes.redirectUrl.map((obj, ind) =>
                <Route key={obj.redirectPath} path={obj.redirectPath} exact component={MainPanel} />
              )}

              <Route component={PageNotFoundContainer} />

            </Switch>
          </Suspense>
        </ScrollIntoView>
      </Router>

    )
  }
}
