import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SellerEnquiryViewList from './SellerEnquiryViewList';
import BuyerEnquiryViewList from './BuyerEnquiryViewList';
import './table.css';
import '../myposts/myposts.css';

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        verticalAlign: "middle",
        marginRight: theme.spacing.unit,
        fontSize: 10,
    },
    tableCell: {
        paddingLeft: '4px',
        paddingRight: '1px',
        textAlign: 'center',
        maxWidth: '200px',
        fontSize: '13px !important'
    },
    titleText: { width: '50%', textAlign: 'left', paddingLeft: '15px', paddingTop: '7px', fontFamily: 'lato !important', },
    defaultTemplate: { height: '30vh', paddingTop: '10vh', },
    defaultSpan: { display: 'grid', fontSize: '25px' },
    defaultIcon: { fontSize: '65px', color: "#384952" },
    editIcon: { fontSize: '20px', color: "#1e459c", paddingLeft: 3, cursor: 'pointer', marginRight: '2px', float: 'left' },
    infoIcon: { color: '#d46262', fontSize: '18px', cursor: 'pointer' },
    cellDiv: {
        maxWidth: '180px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    root: {
        width: '100%',
        minHeight: '80vh'
    },
    lightTooltip: {
        fontSize: '15px',
        maxWidth: 'none',
    },
    info: {
        fontSize: '14px',
        marginLeft: '5px',
        color: '#fd0671',
        cursor: 'pointer'
    },
    textEllpses: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxWidth: "110px",
        lineHeight: "18px",
        display: "block"
    },
    textEllipseForAppid: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        direction: 'rtl',
        // textAlign: 'left',
        maxWidth: '75px',
        textOverflow: 'ellipsis'
    }
});


class BuyerEnquiryList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tableHeadData: ["Trade Number", "Offer number", "Trade Status", "Commodity", "Quantity requested by You", "Terms quoted by seller", "Offer Status", "your offer to seller", "counter offer", "Do you want to accept the counter offer or Reject the counter Offer?"],
            tableHeadDataKeys: ["id", "app_order_id", "buyer_name", "supplier_name", "unsettled_amount_pltf", "createdtime", "source_location", "commodity"],
            tableBodyData: [

            ],
            totalDataCount: this.props.totalDataCount || 0,
            currentOffset: this.props.currentOffset || 0,
            rawTableBodyData: [
                { "tradeNo": "A", "tradeStatus": "B", "Quantity": "Quantity", "Terms": "Terms", "Offer": "Offer", "youroffer": "ok", "status": "A" }
            ],
            searchedText: "",
            editableData: {},
            showServerDialog: false,
            showOptionModal: false,
            anchorEl: null,
            showUserModal: false,
            userData: {},
            // userId: null,
            payload: null,
            showAddModal: false,
            infoData: null,
            open: false,

            rowsPerPage: 50,
            page: 0,

            showSupportingInvoiceModal: false,
            invoiceModalData: [],

            showAddOrderModal: false,

            showEditDataModal: false,
            commodityList: { options: [], optionN_E: {}, optionE_N: {} },
            showUploader: false,

            showPayoutModal: false,
            payoutData: undefined,

            showUserInfo: false,
            userInfoData: undefined,
            isLimitUpdate: false,
            userId: undefined,

            showSweetAlert: false,
            sweetAlertData: {
                "type": "",
                "title": "",
                "text": ""
            },

            sortKeys: this.props.sortKeys,
            sortKeysOrder: this.props.sortKeysOrder,
            sortingEnabled: ["id", "unsettled_amount_pltf", "createdtime", "bijak_amt"],
            showPaymentsOfOrderModal: false,
            orderInfo: undefined,
            isTableDataLoading: true,
            showLoader: false,
            selectedType: 'seller',
            buyerId: '',
            loading: false
        }
        // this.getCommodityNames();
    }

    componentDidMount() {
        if (this.props.location.state && this.props.location.state.detail &&
            this.props.location.state.detail.hasOwnProperty("landOn")) {
            this.setState({
                selectedType: this.props.location.state.detail["landOn"]
            });
        }
    }

    render() {
        // const { classes } = this.props;
        const { selectedType } = this.state;
        return (
            <section className="sec_bg my_account_section" style={{ minHeight: "70vh" }}>
                <div className="container tb_padding" style={{ padding: "68px 15px" }}>
                    {/* <div className="container-fluid" style={{ background: "#E5E5E5", paddingTop: "216px", paddingBottom: "100px" }}>
                            <div className="container"> */}

                    {/* <div className="card"> */}
                    <div >
                        {/* <div className="title_myPost"></div> */}
                        <div className="row mb-2" style={{ background: "rgb(241 244 247)" }}>
                            <label className="col-sm-3 title_myPost">View Details From account</label>
                            <span className="col-sm-3 font-weight-bold footer_mypost_number">
                                <button
                                    onClick={() => this.setState({ selectedType: 'seller' })}
                                    className={selectedType === "seller" ? "btn btn-default myPost_btn mt-1" : "btn btn-default default_btn mt-1"}>Seller</button>
                                <button
                                    onClick={() => this.setState({ selectedType: 'buyer' }, () => {
                                        // this.getBuyerEnquiryList(this.state.buyerId);
                                    })}
                                    className={selectedType === "buyer" ? "btn btn-default myPost_btn" : "btn btn-default default_btn"}>Buyer</button>
                            </span>
                        </div>
                        {selectedType === 'buyer' && <BuyerEnquiryViewList />}
                        {selectedType === 'seller' && <SellerEnquiryViewList />}
                    </div>
                    {/* < /div>      
              </div>      */}
                </div >
            </section >



        );
    }
}

BuyerEnquiryList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BuyerEnquiryList);
