// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

// AWS Mobile Hub Project Constants  

const awsmobile = {
        'aws_cognito_identity_pool_id': 'ap-south-1:49f59724-2753-4314-b31e-37c0c1aef111',
        'aws_cognito_region': 'ap-south-1',
        'aws_project_id': 'krjr5k03rv7m0hmos6vuir1v3',
        'aws_project_name': 'bijakglobal-prod',
        'aws_project_region': 'ap-south-1',
        'aws_sign_in_enabled': 'enable',
        'aws_user_pools': 'enable',
        'aws_user_pools_id': "ap-south-1_8B0IIBiQl",
        'aws_user_pools_web_client_id': 'krjr5k03rv7m0hmos6vuir1v3',
        // 'aws_user_files': 'enable',
        // 'aws_user_files_s3_bucket': 'bijakglobal-userfiles-dev',
        // 'aws_user_files_s3_bucket_region': 'ap-south-1',
        // "oauth": {
        //         "domain": "demotestemail.auth.ap-south-1.amazoncognito.com",
        //         "scope": [
        //                 'email',
        //                 'openid',
        //                 //         'aws.cognito.signin.user.admin'
        //         ],
        //         redirectSignIn: "http://localhost:3001/",
        //         redirectSignOut: "http://localhost:3001/",
        //         // redirect_uri:'http://localhost:3001',
        //         "responseType": "token"
        // }

}

export default awsmobile;
