import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import RouteHandeler from './routes/RouteHandeler';
import Amplify from '@aws-amplify/core'
// import awsmobile from './aws-exports';
import { getAwsConfig } from "./config/appConfig";
Amplify.configure(getAwsConfig())

ReactDOM.render(
  <React.StrictMode>
    <RouteHandeler/>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
