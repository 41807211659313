import React, { Component, useLayoutEffect } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from "@material-ui/core/styles/withStyles";
import './prelogin.css';
import { getMyProfile } from '../../services/commonService';
import { Auth } from 'aws-amplify';

import gulf_food from "../../assests/images/gulf_food.png";
import section1_dollar from "../../assests/images/section1_dollar.png";
import section1_location from "../../assests/images/section1_location.png";
import section2_1 from "../../assests/images/section2_1.png";
import section2_2 from "../../assests/images/section2_2.png";
import section2_3 from "../../assests/images/section2_3.png";
import section2_4 from "../../assests/images/section2_4.png";
import section2_5 from "../../assests/images/section2_5.png";
import arrow_right from "../../assests/images/arrow_right.svg";
import section3_icon1 from "../../assests/images/section3_icon1.svg";
import section3_icon2 from "../../assests/images/section3_icon2.svg";
import section3_icon3 from "../../assests/images/section3_icon3.svg";
import section3_icon4 from "../../assests/images/section3_icon4.svg";
import section3_icon5 from "../../assests/images/section3_icon5.svg";
import section3_icon6 from "../../assests/images/section3_icon6.svg";

import section4_img1 from "../../assests/images/section4_img1.png";
import section5_img from "../../assests/images/section5_img.png";
import section6_img from "../../assests/images/section6_img.png";
import section7_img from "../../assests/images/section7_img.png";
import section8_img from "../../assests/images/section8_img.png";
import section9_img from "../../assests/images/section9_img.png";
import section10_img from "../../assests/images/section10_img.png";
import bijak_logo_white from "../../assests/images/bijak_logo_white.png";
import footer_phone from "../../assests/images/footer_phone.svg";
import section1_bg from "../../assests/images/section1_bg.png";
import mobile_section1_bg from "../../assests/images/mobile_section1_bg.png";
import section1_mobile_bg2 from "../../assests/images/section1_mobile_bg2.png";
import at_the_rate from "../../assests/images/at_the_rate.svg";
import location_white_icon from "../../assests/images/location_white_icon.svg";
import youtube from "../../assests/images/youtube.svg";
// import twitter from "../../assests/images/twitter.svg";
import fb_icon from "../../assests/icons/fb_icon.svg";
import linkedin from "../../assests/images/linkedin.svg";
import section3_bg from "../../assests/images/section3_bg.png";
import section3_mobile_bg from "../../assests/images/section3_mobile_bg.png";
import section10_mobile_img from "../../assests/images/section10_mobile_img.png";


import section_6_step1_icon from "../../assests/images/section_6_step1_icon.svg";
import section_6_step2_icon from "../../assests/images/section_6_step2_icon.svg";
import section_6_step3_icon from "../../assests/images/section_6_step3_icon.svg";
import section_6_step4_icon from "../../assests/images/section_6_step4_icon.svg";
import section_6_step5_icon from "../../assests/images/section_6_step5_icon.svg";
import CommonUtil from '../../shared/CommonUtil';





const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class PreLoginPage extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    };


    async componentDidMount() {
        console.log(this.props.location.scrollToId)
        if (this.props.location.scrollToId) {
            try {
                let scollId = this.props.location.scrollToId;
                if (window.screen.width < 576) {
                    scollId = scollId + "Mob";
                }
                setTimeout(() => {
                    document.getElementById(scollId).scrollIntoView({
                        behavior: 'smooth'
                    });
                }, 100);

            } catch (err) {
                console.log(err);
            }
        }


        this.setState({ showLoader: true }, () => {
            this.getId();
        });
    }

    async getId() {
        await Auth.currentAuthenticatedUser().then((user) => {
            console.log(user)
            if (user.attributes && user.attributes["custom:id"]) {
                this.getMyProfileData(user.attributes["custom:id"]);
            }

        });
    }
    async getMyProfileData(userid) {
        try {
            let resp = await getMyProfile(userid);
            console.log(resp);
            let data = [{}];
            if (resp && resp.type === 1 && resp.data.length > 0) {
                data = resp.data;
            }
            sessionStorage.setItem("profileData", JSON.stringify(data[0]));
        } catch (err) {
            console.log(err);
        }
    }



    render() {
        // const { classes } = this.props;  
        // return(<div></div>)      
        // return(<PostLoginPage/>)
        //d575_none == small device hide

        return (<>
            <div className="d_lg_none_sm">
                <section id="section1" className="simple_steps" style={{ backgroundImage: 'url(' + section1_mobile_bg2 + ')' }}>
                    <div className="container">
                        <div className="row m-0">
                            <div className="col-lg-6 order-1 order-lg-2 hero-img">
                                <div className="headertxt text-left">India’s largest agri marketplace goes global to bridge the food gap between GCC and India</div>
                                <div className="row mt-46 ">
                                    <div className="col-3">
                                        <img alt="" src={gulf_food} className="sectionBox" />
                                    </div>
                                    <div className="col-8">

                                        <div className="sectionBoxText1 text-left">
                                            We hope to see you at Gulfood 2021!
                                        </div>

                                    </div>
                                    <div className="sectionBoxText2 p-2">
                                        Please do visit the Bijak Global stall
                                        (Z3-B47 in Za’abeel hall)
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <section id="section1" className="simple_steps" style={{ backgroundImage: 'url(' + mobile_section1_bg + ')', backgroundColor: "#000000" }}>
                    <div className="container">
                        <div className="row m-0">
                            <div className="col-lg-6 order-1 order-lg-2 hero-img">
                                <div className="headertxt" style={{ visibility: "hidden", height: "30rem" }}>India’s largest agri marketplace goes global to bridge the food gap between GCC and India</div>
                                <div className="row mt-72 mb-200" style={{ visibility: "hidden" }}>
                                    <div className="col-4">
                                        <img alt="" src={gulf_food} className="sectionBox" />
                                    </div>
                                    <div className="col-8">
                                        <div className="sectionBoxText1">
                                            We hope to see you at Gulfood 2021!
                                            </div>
                                        <div className="sectionBoxText2 pr-5">
                                            Please do visit the Bijak Global stall
                                            (Z3-B47 in Za’abeel hall)
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card w-80 m-auto card1" id="aboutusMob" >
                        <div className="card-body">
                            <div className="row m-0">
                                <div className="col-sm-6 text-center card1_gText p-0">
                                    <div><img alt="" src={section1_dollar} /></div>
                                    <div className="card1_gText pt-4 pr-4 pl-4">USD 200 million</div>
                                    <div className="card1_btext pt-2 pr-5 pl-5">transactions annually on Bijak</div>
                                </div>
                                <div className="col-sm-6 text-center pt-56">
                                    <div><img alt="" src={section1_location} /></div>
                                    <div className="card1_gText pt-4 pr-4 pl-4">900+ regions</div>
                                    <div className="card1_btext pt-2 pr-3 pl-3">Our presence spans across 27 states & Union Territories</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="section2">
                    <div className="row justify-content-center ml-0 mr-0" style={{ marginTop: "25rem" }}>
                        <div className="col-12 text-center heading pt-40 ">
                            Partners
                        </div>
                        <div className="col-6 text-right">
                            <img alt="" src={section2_1} className="brand_logo" />
                        </div>
                        <div className="col-6 text-left">
                            <img alt="" src={section2_2} className="brand_logo" />
                        </div>
                        <div className="col-6 text-right mt-4">
                            <img alt="" src={section2_3} className="brand_logo" />
                        </div>
                        <div className="col-6 text-left mt-4">
                            <img alt="" src={section2_4} className="brand_logo" />
                        </div>
                        <div className="col-6 text-center mt-4">
                            <img alt="" src={section2_5} className="brand_logo" />
                        </div>
                    </div>
                    <div id={"featuresMob"}></div>
                </section>

                <section id="section3" >
                    <div className="row m-0" >
                        <div className="col-sm-6 pl-4 pr-4">
                            <div className="col-12 pl-0">
                                <div className="heading mt-24 pr-2">Discover the key features</div>
                            </div>
                            {!CommonUtil.CheckIfUserLoggedIn()["isLoggedIn"] &&
                                <div className="col-12 mt-16 pl-0">
                                    <div className="smallheading pl-0"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            this.props.history.push(`/signUp`);
                                        }}
                                    >Register as a trader today!&nbsp;<img alt="" src={arrow_right} /></div>
                                </div>}
                        </div>
                    </div>
                    <div className="row justify-content-center m-0">
                        <div className="col p-0 m-0">
                            <img alt="" src={section3_mobile_bg} className="mt-40 w-100" />
                        </div>
                    </div>

                    <div className="row justify-content-center mt-24 ml-0 mr-0">
                        <div className="col-lg-4 col-sm-10 mb-3 pr-4 pl-4">
                            <div className="card card_row ">
                                <div className="card-body p-40">
                                    <div><img alt="" src={section3_icon1} /></div>
                                    <div className="card_text pt-40">Reliable marketplace</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-10 mb-3 pr-4 pl-4">
                            <div className="card card_row">
                                <div className="card-body p-40">
                                    <div><img alt="" src={section3_icon2} /></div>
                                    <div className="card_text pt-40">e-Negotiation platform</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-10 mb-3 pr-4 pl-4">
                            <div className="card card_row">
                                <div className="card-body p-40">
                                    <div><img alt="" src={section3_icon3} /></div>
                                    <div className="card_text pt-40">Assured quality</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center pb-58 m-0">
                        <div className="col-lg-4 col-sm-10 mb-3 pr-4 pl-4">
                            <div className="card card_row">
                                <div className="card-body p-40">
                                    <div><img alt="" src={section3_icon4} /></div>
                                    <div className="card_text pt-40">Secured payments</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-10 mb-3 pr-4 pl-4">
                            <div className="card card_row">
                                <div className="card-body p-40">
                                    <div><img alt="" src={section3_icon5} /></div>
                                    <div className="card_text pt-40">User verification</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-10 mb-3 pr-4 pl-4">
                            <div className="card card_row">
                                <div className="card-body p-40">
                                    <div><img alt="" src={section3_icon6} /></div>
                                    <div className="card_text pt-40">End-to-end logistics services</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {!CommonUtil.CheckIfUserLoggedIn()["isLoggedIn"] && <div className="text-center pb-58">
                        <button className="btn btn-outline-success border-white text-white registerBtn"
                            style={{ padding: "14px 54px" }}
                            onClick={() => {
                                this.props.history.push(`/signUp`);
                            }}
                        >Register as a trader today!
                            </button>
                    </div>}
                </section>
                <section id="section4">
                    <div className="row justify-content-center pb-58 pt-3 m-0">

                        <div className="col-lg-7">
                            <img alt="" src={section4_img1} width="100%" />
                        </div>
                        <div className="col-sm-6 pl-4">
                            <div className="heading mt-24">Reliable marketplace</div>
                            <ul className="pl-4">
                                <li className="textValue mt-16  pl-4 pr-4">Access a network of 25,000+ reliable Indian suppliers based on their past transaction history and ratings</li>
                                <li className="textValue mt-16  pl-4 pr-4">Choose from 100+ commodities of the highest quality.</li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section id="section4" >
                    <div className="row justify-content-center pb-58 pt-3 m-0">

                        <div className="col-lg-7">
                            <img alt="" src={section5_img} width="100%" />
                        </div>
                        <div className="col-sm-6 pl-4">
                            <div className="heading mt-24">E-negotiation platform</div>
                            <ul className="pl-4">
                                <li className="textValue mt-16  pl-4 pr-4">Digitally negotiate all the terms seamlessly on the platform including price, payment & shipping terms</li>
                                <li className="textValue mt-16  pl-4 pr-4">Track all your open trades and bids from counterparties on one single portal</li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section id="section4" >
                    <div className="row justify-content-center pb-58 pt-3 m-0">

                        <div className="col-lg-7">
                            <img alt="" src={section6_img} width="100%" />
                        </div>
                        <div className="col-sm-6 pl-4">
                            <div className="heading mt-24">Secured Payments</div>
                            <ul className="pl-4">
                                <li className="textValue mt-16  pl-4 pr-4">Make secured payments to your counterparties through our Escrow services</li>
                                <li className="textValue mt-16  pl-4 pr-4">Bijak is backed by 10+ international banks & 4 global payment gateways</li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section id="section4" >
                    <div className="row justify-content-center pb-58 pt-3 m-0">

                        <div className="col-lg-7">
                            <img alt="" src={section7_img} width="100%" />
                        </div>
                        <div className="col-sm-6 pl-4">
                            <div className="heading mt-24">End-to-end logistics services</div>
                            <ul className="pl-4">
                                <li className="textValue mt-16  pl-4 pr-4">Just choose the commodity, quantity and quality, and let Bijak handle rest of the process</li>
                                <li className="textValue mt-16  pl-4 pr-5">We will ensure optimized shipping services at the lowest cost through our trusted shipping partners including tracking services for all your consignments</li>
                            </ul>
                        </div>
                    </div>
                </section>


                <section id="section4" >
                    <div className="row justify-content-center pb-58 pt-3 m-0">

                        <div className="col-lg-7">
                            <img alt="" src={section8_img} width="100%" />
                        </div>
                        <div className="col-sm-6 pl-4">
                            <div className="heading mt-24">User verification</div>
                            <ul className="pl-4">
                                <li className="textValue mt-16  pl-4 pr-4">Each and every seller has been verified & tracked on the Bijak platform over the past 2 years and only the top rated ones are allowed on the global platform</li>
                                <li className="textValue mt-16  pl-4 pr-5">Additionally, the buyers are thoroughly vetted as well to ensure trust for the sellers</li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section id="section4" >
                    <div className="row justify-content-center pb-58 pt-3 m-0">

                        <div className="col-lg-7">
                            <img alt="" src={section9_img} width="100%" />
                        </div>
                        <div className="col-sm-6 pl-4">
                            <div className="heading mt-24">Assured quality</div>
                            <ul className="pl-4">
                                <li className="textValue mt-16  pl-4 pr-4">Only suppliers with highest quality produce are onboarded</li>
                                <li className="textValue mt-16  pl-4 pr-5">Moreover, Bijak also facilitate product quality checks through leading testing agencies such as SGS</li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section id="section4" >
                    <div className="row justify-content-center pb-58 pt-3 m-0">
                        <div className="section10_title">How it works</div>
                        <div className="col-lg-7 mt-40">
                            <img alt="" src={section10_mobile_img} width="100%" />
                        </div>
                        <div className="col-sm-6">
                            <div className=" mt-48 text-center">
                                <img alt="" src={section_6_step1_icon} />
                                <div className="mt-24">
                                    <div className="step_text">Step 1</div>
                                    <div className="step_text_val pt-2 pr-5 pl-5">Register and verify yourself as a buyer or seller</div>
                                </div>
                            </div>
                            <div className=" mt-48 text-center">
                                <img alt="" src={section_6_step2_icon} />
                                <div className="mt-24">
                                    <div className="step_text">Step 2</div>
                                    <div className="step_text_val pt-2  pr-5 pl-5">Find trusted  counter parties to Buy or sell commodites</div>
                                </div>
                            </div>

                            <div className=" mt-48 text-center">
                                <img alt="" src={section_6_step3_icon} />
                                <div className="mt-24">
                                    <div className="step_text">Step 3</div>
                                    <div className="step_text_val pt-2  pr-5 pl-5">Negotiate price, payment, shipping terms on our platform</div>
                                </div>
                            </div>

                            <div className=" mt-48 text-center">
                                <img alt="" src={section_6_step4_icon} />
                                <div className="mt-24">
                                    <div className="step_text">Step 4</div>
                                    <div className="step_text_val pt-2 pr-5 pl-5">Make secured payment to the counter parties through Bijak</div>
                                </div>
                            </div>

                            <div className=" mt-48 text-center">
                                <img alt="" src={section_6_step5_icon} />
                                <div className="mt-24">
                                    <div className="step_text">Step 5</div>
                                    <div className="step_text_val pt-2 pr-5 pl-5">Bijak will take care of the rest of the trade process</div>
                                </div>
                            </div>

                        </div>
                        {!CommonUtil.CheckIfUserLoggedIn()["isLoggedIn"] && <div className=" mt-72">
                            <button className="btn btn-success text-uppercase cus_bg_green become_traderBtn"
                                style={{ width: "20rem" }}

                                onClick={() => {
                                    this.props.history.push(`/signUp`);
                                }}
                            >Become A Trader</button>
                        </div>}
                    </div>
                </section>

                <footer className="bijak-footer" id="contactusMob">
                    <div className="container">
                        <div className="row justify-content-center pb-58 pt-80 m-0">
                            <div className="col-sm-7">
                                <div><img alt="" src={bijak_logo_white} /></div>
                                <div className="d-flex pt-60">
                                    <img alt="" src={footer_phone} />
                                    <a className="bijak-footer-contacttext ml-24" href="tel:+918585958484">+91 8585958484</a>
                                </div>
                                <div className="d-flex pt-26">
                                    <img alt="" src={at_the_rate} />
                                    <a className="bijak-footer-contacttext ml-24" href="mailto:trade@bijak.in">trade@bijak.in</a>
                                </div>
                                <div className="d-flex pt-26">
                                    <img alt="" src={location_white_icon} />
                                    <span className="bijak-footer-contacttext ml-24" style={{ width: "320px" }}>90B, Delhi - Jaipur Expy, Udyog Vihar, Sector 18, Gurugram, Haryana 122008</span>
                                </div>
                            </div>
                            <div className="col-sm-4  mt-30">
                                {/* <div className="d-flex mt-30">
                                    <span className="bijak-footer-contacttext">About</span>
                                </div> */}
                                <div className="d-flex  pt-26">
                                    <span className="bijak-footer-contacttext">Terms & Condition</span>
                                </div>
                                <div className="d-flex pt-26">
                                    <span className="bijak-footer-contacttext">Visit: <a className="bijak-footer-contacttext" href="https://www.bijak.in/" target="_blank">bijak.in</a></span>

                                </div>

                            </div>

                        </div>
                        <hr className="bijak_footer_hr" />

                        <div className="bijak_footer_endTxt col-md-12 text-center text-md-left text-white pt-4">Follow us on: </div>
                        <div className="text-center pt-26">
                            <a className="bijak-footer-contacttext" href="https://www.facebook.com/BijakIndia" target="_blank">
                                <img alt="" src={fb_icon} />
                            </a>
                            <a className="bijak-footer-contacttext" href="https://www.youtube.com/channel/UC25O6DoBy_cn__D0I2agkug" target="_blank">
                                <img alt="" src={youtube} className="ml-48" />
                            </a>
                            <a className="bijak-footer-contacttext" href="https://www.linkedin.com/company/bijakapp/" target="_blank">
                                <img alt="" src={linkedin} className="ml-48" />
                            </a>

                        </div>
                        <div className="bijak_footer_endTxt mt-60 pb-58 col-md-12 text-center text-md-left text-white">© 2020 Bijak | Krishiacharya Technologies Private Limited. All Rights Reserved.</div>

                    </div>
                </footer>
                <div style={{ width: "100%" }} className="mob_float_btn_div">
                    {/* <a href="https://docs.google.com/forms/d/e/1FAIpQLSfS_GQ1V4ezpEVvXaGXcDvIuj72wTf98tD4R-ELsffIt0y3Ug/viewform"
                        target="_blank"
                        className="mob_float_btn">
                        Tell us your requirements
                    </a> */}
                    <button className="mob_float_btn btn btn-outline-success border-white text-white"
                    onClick={(e) => {
                        e.preventDefault();
                        CommonUtil.openLinkInNewTab("https://docs.google.com/forms/d/e/1FAIpQLSfS_GQ1V4ezpEVvXaGXcDvIuj72wTf98tD4R-ELsffIt0y3Ug/viewform")
                    }}>Tell us your requirements</button>
                </div>
            </div>

            <div className="d575_none">
                <section id="section1" style={{ backgroundImage: ' url(' + section1_bg + ")" }} className="simple_steps">
                    <div className="container">
                        <div className="row m-0">
                            <div className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center" >
                                <div className="headertxt">India’s largest agri marketplace goes global to bridge the food gap between GCC and India</div>
                                <div className="row mt-72 mb-200">
                                    <div className="col-4">
                                        <img alt="" src={gulf_food} className="sectionBox" />
                                    </div>
                                    <div className="col-8">

                                        <div className="sectionBoxText1" >
                                            We hope to see you at Gulfood 2021!
                                        </div>
                                        <div className="sectionBoxText2 pr-5" id="aboutus">
                                            Please do visit the Bijak Global stall
                                            (Z3-B47 in Za’abeel hall)
                                </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 hero-img" >
                            </div>
                        </div>
                    </div>
                    <div className="card w-80 m-auto card1"  >
                        <div className="card-body">
                            <div className="row m-0">
                                <div className="col-6 text-center card1_gText p-0">
                                    <div><img alt="" src={section1_dollar} /></div>
                                    <div className="card1_gText mt-16">USD 200 million</div>
                                    <div className="card1_btext">transactions annually on Bijak</div>
                                </div>
                                <div className="col-6 text-center p-0 ">
                                    <div><img alt="" src={section1_location} /></div>
                                    <div className="card1_gText mt-16">900+ regions</div>
                                    <div className="card1_btext">Our presence spans across 27 states & Union Territories</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="section2">
                    <div className="row justify-content-center m-0">
                        <div className="col-12 text-center heading ">
                            Partners
                    </div>
                        <div className="col-2">
                            <img alt="" src={section2_1} />
                        </div>
                        <div className="col-2">
                            <img alt="" src={section2_2} />
                        </div>
                        <div className="col-2">
                            <img alt="" src={section2_3} />
                        </div>
                        <div className="col-2">
                            <img alt="" src={section2_4} />
                        </div>
                        <div className="col-2">
                            <img alt="" src={section2_5} />
                        </div>
                    </div>
                </section>
                <section id="section3">
                    <div className="container" id="features">
                        <div className="row ">
                            <div className="col-6">
                                <div className="col-10 pl-0">
                                    <div className="heading mt-64">Discover the key features</div>
                                </div>
                                {!CommonUtil.CheckIfUserLoggedIn()["isLoggedIn"] &&
                                    <div className="col-12 pl-0">
                                        <div className="smallheading pl-0"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                this.props.history.push(`/signUp`);
                                            }}
                                        >Register as a trader today!&nbsp;<img alt="" src={arrow_right} /></div>
                                    </div>}
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col">
                                <img alt="" src={section3_bg} className="mt-64" />
                            </div>
                        </div>

                        <div className="row justify-content-center mt-80">
                            <div className="col-lg-4 col-sm-10 mb-3">
                                <div className="card card_row ">
                                    <div className="card-body p-40">
                                        <div><img alt="" src={section3_icon1} /></div>
                                        <div className="card_text pt-40">Reliable marketplace</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-10 mb-3">
                                <div className="card card_row">
                                    <div className="card-body p-40">
                                        <div><img alt="" src={section3_icon2} /></div>
                                        <div className="card_text pt-40">e-Negotiation platform</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-10 mb-3">
                                <div className="card card_row">
                                    <div className="card-body p-40">
                                        <div><img alt="" src={section3_icon3} /></div>
                                        <div className="card_text pt-40">Assured quality</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-30 pb-58">
                            <div className="col-lg-4 col-sm-10 mb-3">
                                <div className="card card_row">
                                    <div className="card-body p-40">
                                        <div><img alt="" src={section3_icon4} /></div>
                                        <div className="card_text pt-40">Secured payments</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-10 mb-3">
                                <div className="card card_row">
                                    <div className="card-body p-40">
                                        <div><img alt="" src={section3_icon5} /></div>
                                        <div className="card_text pt-40">User verification</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-10 mb-3">
                                <div className="card card_row">
                                    <div className="card-body p-40">
                                        <div><img alt="" src={section3_icon6} /></div>
                                        <div className="card_text pt-40">End-to-end logistics services</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!CommonUtil.CheckIfUserLoggedIn()["isLoggedIn"] && <div className="text-center pb-58">
                            <button className="btn btn-outline-success border-white text-white registerBtn"
                                onClick={() => {
                                    this.props.history.push(`/signUp`);
                                }}
                            >Register as a trader today!
                        </button>
                        </div>}
                    </div>
                </section>
                <section id="section4" className="bg-white">
                    <div className="row justify-content-center pb-58 pt-80 m-0">
                        <div className="col-5 pl-120">
                            <div className="heading mt-114">Reliable marketplace</div>
                            <ul className="pl-4">
                                <li className="textValue mt-40">Access a network of 25,000+ reliable Indian suppliers based on their past transaction history and ratings</li>
                                <li className="textValue mt-40">Choose from 100+ commodities of the highest quality.</li>
                            </ul>
                        </div>
                        <div className="col-lg-7 pr-0 pl-5">
                            <img alt="" src={section4_img1} width="100%" />
                        </div>
                    </div>
                </section>
                <section id="section4" className="bg-white">
                    <div className="row justify-content-center pb-58 pt-80 m-0">

                        <div className="col-lg-7 pr-5 pl-0">
                            <img alt="" src={section5_img} width="100%" />
                        </div>
                        <div className="col-5 pr-120">
                            <div className="heading mt-114">E-negotiation platform</div>
                            <ul className="pl-4">
                                <li className="textValue mt-40">Digitally negotiate all the terms seamlessly on the platform including price, payment & shipping terms</li>
                                <li className="textValue mt-40">Track all your open trades and bids from counterparties on one single portal</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section id="section4" className="bg-white">
                    <div className="row justify-content-center pb-58 pt-80 m-0">
                        <div className="col-5 pl-120">
                            <div className="heading mt-114">Secured Payments</div>
                            <ul className="pl-4">
                                <li className="textValue mt-40">Make secured payments to your counterparties through our Escrow services</li>
                                <li className="textValue mt-40">Bijak is backed by 10+ international banks & 4 global payment gateways</li>
                            </ul>
                        </div>
                        <div className="col-lg-7 pr-0 pl-5">
                            <img alt="" src={section6_img} width="100%" />
                        </div>
                    </div>
                </section>
                <section id="section4" className="bg-white">
                    <div className="row justify-content-center pb-58 pt-80 m-0">

                        <div className="col-lg-7 pr-5 pl-0">
                            <img alt="" src={section7_img} width="100%" />
                        </div>
                        <div className="col-5 pr-120">
                            <div className="heading mt-114">End-to-end logistics services</div>
                            <ul className="pl-4">
                                <li className="textValue mt-40">Just choose the commodity, quantity and quality, and let Bijak handle rest of the process</li>
                                <li className="textValue mt-40">We will ensure optimized shipping services at the lowest cost through our trusted shipping partners including tracking services for all your consignments</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section id="section4" className="bg-white">
                    <div className="row justify-content-center pb-58 pt-80 m-0">
                        <div className="col-5 pl-120">
                            <div className="heading mt-114">User verification</div>
                            <ul className="pl-4">
                                <li className="textValue mt-40">Each and every seller has been verified & tracked on the Bijak platform over the past 2 years and only the top rated ones are allowed on the global platform</li>
                                <li className="textValue mt-40">Additionally, the buyers are thoroughly vetted as well to ensure trust for the sellers</li>
                            </ul>
                        </div>
                        <div className="col-lg-7 pr-0 pl-5">
                            <img alt="" src={section8_img} width="100%" />
                        </div>
                    </div>
                </section>
                <section id="section4" className="bg-white">
                    <div className="row justify-content-center pb-58 pt-80 m-0">

                        <div className="col-lg-7 pr-5 pl-0">
                            <img alt="" src={section9_img} width="100%" />
                        </div>
                        <div className="col-5 pr-120">
                            <div className="heading mt-114">Assured quality</div>
                            <ul className="pl-4">
                                <li className="textValue mt-40">Only suppliers with highest quality produce are onboarded</li>
                                <li className="textValue mt-40">Moreover, Bijak also facilitate product quality checks through leading testing agencies such as SGS</li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section id="section4" className="bg-white">
                    <div className="row justify-content-center pb-58 pt-64 m-0">
                        <div className="col-6 pl-120">
                            <div className="heading">How it works</div>

                            <div className="d-flex mt-40">
                                <img alt="" src={section_6_step1_icon} />
                                <div className="ml-40">
                                    <div className="step_text">Step 1</div>
                                    <div className="step_text_val pt-2">Register and verify yourself as a buyer or seller</div>
                                </div>
                            </div>

                            <div className="d-flex mt-30">
                                <img alt="" src={section_6_step2_icon} />
                                <div className="ml-40">
                                    <div className="step_text">Step 2</div>
                                    <div className="step_text_val pt-2">Find trusted  counter parties to Buy or sell commodites</div>
                                </div>
                            </div>

                            <div className="d-flex mt-30">
                                <img alt="" src={section_6_step3_icon} />
                                <div className="ml-40">
                                    <div className="step_text">Step 3</div>
                                    <div className="step_text_val pt-2">Negotiate price, payment, shipping terms on our platform</div>
                                </div>
                            </div>

                            <div className="d-flex mt-30">
                                <img alt="" src={section_6_step4_icon} />
                                <div className="ml-40">
                                    <div className="step_text">Step 4</div>
                                    <div className="step_text_val pt-2">Make secured payment to the counter parties through Bijak</div>
                                </div>
                            </div>

                            <div className="d-flex mt-30">
                                <img alt="" src={section_6_step5_icon} />
                                <div className="ml-40">
                                    <div className="step_text">Step 5</div>
                                    <div className="step_text_val pt-2">Bijak will take care of the rest of the trade process</div>
                                </div>
                            </div>

                            {!CommonUtil.CheckIfUserLoggedIn()["isLoggedIn"] &&
                                <div className=" mt-72 d575_none">
                                    <button className="btn btn-success text-uppercase cus_bg_green become_traderBtn"
                                        onClick={() => {
                                            this.props.history.push(`/signUp`);
                                        }}
                                    >Become A Trader At Bijak</button>
                                </div>}
                        </div>
                        <div className="col-6 pr-0 mt-72 pl-5">
                            <img alt="" src={section10_img} width="100%" />
                        </div>
                    </div>

                </section>

                <footer className="bijak-footer" id="contactus">
                    <div className="container">
                        <div className="row justify-content-center pb-58 pt-80 m-0">
                            <div className="col-sm-7">
                                <div><img alt="" src={bijak_logo_white} /></div>
                                <div className="d-flex pt-60">
                                    <img alt="" src={footer_phone} />
                                    <a className="bijak-footer-contacttext ml-24" href="tel:+918585958484">+91 8585958484</a>
                                </div>
                                <div className="d-flex pt-26">
                                    <img alt="" src={at_the_rate} />
                                    <a className="bijak-footer-contacttext ml-24" href="mailto:trade@bijak.in">trade@bijak.in</a>
                                </div>
                                <div className="d-flex pt-26">
                                    <img alt="" src={location_white_icon} />
                                    <span className="bijak-footer-contacttext ml-24" style={{ width: "320px" }}>90B, Delhi - Jaipur Expy, Udyog Vihar, Sector 18, Gurugram, Haryana 122008</span>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="d-flex mt-80">
                                    <span className="bijak-footer-contacttext">Terms & Condition</span>
                                </div>
                                <div className="d-flex pt-26">
                                    <span className="bijak-footer-contacttext">Visit: <a className="bijak-footer-contacttext" href="https://www.bijak.in/" target="_blank">bijak.in</a></span>

                                </div>
                                <div className="d-flex pt-26">
                                    <a className="bijak-footer-contacttext" href="https://www.facebook.com/BijakIndia" target="_blank">
                                        <img alt="" src={fb_icon} />
                                    </a>
                                    <a className="bijak-footer-contacttext" href="https://www.youtube.com/channel/UC25O6DoBy_cn__D0I2agkug" target="_blank">
                                        <img alt="" src={youtube} className="ml-48" />
                                    </a>
                                    <a className="bijak-footer-contacttext" href="https://www.linkedin.com/company/bijakapp/" target="_blank">
                                        <img alt="" src={linkedin} className="ml-48" />
                                    </a>

                                </div>
                            </div>

                        </div>
                        <hr className="bijak_footer_hr" />
                        <div className="bijak_footer_endTxt mt-60 pb-58 col-md-12 text-center text-md-left text-white">© 2020 Bijak | Krishiacharya Technologies Private Limited. All Rights Reserved.</div>

                    </div>
                </footer>
                {/* <a href="https://docs.google.com/forms/d/e/1FAIpQLSfS_GQ1V4ezpEVvXaGXcDvIuj72wTf98tD4R-ELsffIt0y3Ug/viewform"
                    target="_blank"
                    className="float_btn">
                    Tell us your requirements
                </a> */}

                <button className="float_btn btn btn-outline-success border-white text-white"
                    onClick={(e) => {
                        e.preventDefault();
                        CommonUtil.openLinkInNewTab("https://docs.google.com/forms/d/e/1FAIpQLSfS_GQ1V4ezpEVvXaGXcDvIuj72wTf98tD4R-ELsffIt0y3Ug/viewform")
                    }}>Tell us your requirements</button>

            </div>
        </>);
    }
}

export default withStyles(styles)(withRouter(PreLoginPage))