import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { getMyPosts } from '../../services/commonService';
import Loader from '../common/Loader';
import '../table/table.css'
import { Auth } from 'aws-amplify';
import NoEnquires from '../common/NoEnquires';
import id_go from '../../assests/icons/id_go.svg';
import './myposts.css'
import Tooltip from '@material-ui/core/Tooltip';

var moment = require('moment');

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        verticalAlign: "middle",
        marginRight: theme.spacing.unit,
        fontSize: 10,
    },
    tableCell: {
        paddingLeft: '4px',
        paddingRight: '1px',
        textAlign: 'center',
        maxWidth: '200px',
        fontSize: '13px !important'
    },
    titleText: { width: '50%', textAlign: 'left', paddingLeft: '15px', paddingTop: '7px', fontFamily: 'lato !important', },
    defaultTemplate: { height: '30vh', paddingTop: '10vh', },
    defaultSpan: { display: 'grid', fontSize: '25px' },
    defaultIcon: { fontSize: '65px', color: "#384952" },
    editIcon: { fontSize: '20px', color: "#1e459c", paddingLeft: 3, cursor: 'pointer', marginRight: '2px', float: 'left' },
    infoIcon: { color: '#d46262', fontSize: '18px', cursor: 'pointer' },
    cellDiv: {
        maxWidth: '180px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    root: {
        width: '100%',
        minHeight: '80vh'
    },
    lightTooltip: {
        fontSize: '15px',
        maxWidth: 'none',
    },
    info: {
        fontSize: '14px',
        marginLeft: '5px',
        color: '#fd0671',
        cursor: 'pointer'
    },
    textEllpses: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxWidth: "110px",
        lineHeight: "18px",
        display: "block"
    },
    textEllipseForAppid: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        direction: 'rtl',
        // textAlign: 'left',
        maxWidth: '75px',
        textOverflow: 'ellipsis'
    },
    tableHead_120: {
        width: '120px'
    },
    tooltipCls: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.4px",
        color: "rgba(255, 255, 255, 0.88)",
        flex: "none",
        order: 1,
        flexGrow: 0,
        margin: "0px 8px"
    }
});


class MyPostsContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tableHeadData: ["Enquiry number", "Enquiry Status", "Commodity with Title", "Volume", "Enquiry Terms", "Date of Posting", "Offer Expiry Date"],
            tableHeadDataKeys: ["id", "app_order_id", "buyer_name", "supplier_name", "unsettled_amount_pltf", "createdtime", "source_location", "commodity"],
            tableBodyData: [
            ],
            totalDataCount: this.props.totalDataCount || 0,
            currentOffset: this.props.currentOffset || 0,
            rawTableBodyData: [

            ],
            searchedText: "",
            editableData: {},
            showServerDialog: false,
            showOptionModal: false,
            anchorEl: null,
            showUserModal: false,
            userData: {},
            // userId: null,
            payload: null,
            showAddModal: false,
            infoData: null,
            open: false,

            rowsPerPage: 50,
            page: 0,

            showSupportingInvoiceModal: false,
            invoiceModalData: [],

            showAddOrderModal: false,

            showEditDataModal: false,
            commodityList: { options: [], optionN_E: {}, optionE_N: {} },
            showUploader: false,

            showPayoutModal: false,
            payoutData: undefined,

            showUserInfo: false,
            userInfoData: undefined,
            isLimitUpdate: false,
            userId: undefined,

            showSweetAlert: false,
            sweetAlertData: {
                "type": "",
                "title": "",
                "text": ""
            },

            sortKeys: this.props.sortKeys,
            sortKeysOrder: this.props.sortKeysOrder,
            sortingEnabled: ["id", "unsettled_amount_pltf", "createdtime", "bijak_amt"],
            showPaymentsOfOrderModal: false,
            orderInfo: undefined,
            isTableDataLoading: true,
            sellerInfoData: [],
            showCounterOfferModal: false,
            selectedEnquery: {},
            showLoader: false,

        }
        // this.getCommodityNames();
    }

    componentDidMount() {
        this.setState({ showLoader: true }, () => {
            this.getId();
        });
    }

    async getId() {
        await Auth.currentAuthenticatedUser().then((user) => {
            if (user.attributes && user.attributes["custom:id"]) {
                this.getMyPosts(user.attributes["custom:id"]);
            }

        });
        // this.getMyPosts(13);
    }
    async getMyPosts(userid) {
        try {
            // let userid = config.sellerid;
            let paginationObj = { offset: 0, limit: 100 }
            let resp = await getMyPosts(userid, paginationObj);
            console.log(resp)
            // console.log(JSON.stringify(resp))
            let clo = [];
            // let clo = this.state.tableBodyData;
            if (resp && resp.type === 1) {
                clo = resp.data;
            }
            this.setState({ tableBodyData: clo, showLoader: false });
        } catch (error) {
            console.log(error);
        }
    }

    getTableCellClass(classes, index) {
        return classes.tableCell;
    }
    showPaymentsHistory(data) {
        this.setState({
            orderInfo: data,
            showPaymentsOfOrderModal: true
        })
    }


    formatDate(date, format) {
        // return date;
        let dd = moment(date, format).format('Do');
        return dd + " " + moment(date, format).format("MMM YYYY")
    }

    showDetails(e, obj) {
        e.preventDefault();
        try {
            this.props.history.push({
                pathname: '/ProductDetailsContainer',
                search: '',
                state: { detail: obj }
            })
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        const { classes } = this.props;
        const { showLoader, tableBodyData } = this.state;

        // d_lg_none_sm === small device show 
        //d575_none == small device hide
        return (<div>

            {!showLoader ?
                <div>
                    {tableBodyData && tableBodyData.length > 0 ?
                        <section className="sec_bg my_account_section col-lg-12 " style={{ minHeight: "70vh" }}>
                            <div className="row cus_sec_width cus_mb_120_sm">
                                <div className="col-12 mb-3 mt-4 " >
                                    <a href="#" className="cus_purple go_back d_lg_none_sm" onClick={(event) => { event.preventDefault(); this.props.history.goBack(); }}>
                                        <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                        &nbsp;&nbsp;GO BACK</a>
                                </div>
                                <div className="col-md-12 d_lg_none_sm">
                                    <h5 className="myPost_title">My Trades</h5>
                                </div>
                                {/* <div className="free_search col-12 mt-2">
                                    <div className="radio_toolbar">
                                        <input type="radio" id="inr" name="currency" value="inr" checked />
                                        <label for="inr">CIF</label>

                                        <input type="radio" id="usd" name="currency" value="usd" />
                                        <label for="usd">FOB</label>
                                    </div>
                                </div> */}

                                {tableBodyData && tableBodyData.length > 0 &&
                                    tableBodyData.map((obj, index) =>
                                        <div key={index + "small_poi"} className="card mt-3 myPost_card" style={{ cursor: 'pointer' }}>
                                            <div className="card-body d_lg_none_sm">
                                                <div className="row" >
                                                    <div className="col-6">
                                                        <p className="font-weight-bold mypost_id">#{obj.id}
                                                            <img src={id_go} alt="" style={{ float: "right", cursor: 'pointer', marginRight: "20%" }} onClick={(e) => this.showDetails(e, obj)} /></p>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className={obj.sell_commodity_status && (obj.sell_commodity_status.toLowerCase() === "live" || obj.sell_commodity_status.toLowerCase() === "open") ? "mypost_status_box" : "mypost_status_box_closed"}>
                                                            <p className={" cus_light_green text-right " + obj.sell_commodity_status && (obj.sell_commodity_status.toLowerCase() === "live" || obj.sell_commodity_status.toLowerCase() === "open") ? "mypost_status" : "mypost_status_closed"}    >{obj.sell_commodity_status && obj.sell_commodity_status.toUpperCase()}</p>
                                                        </div>
                                                    </div>

                                                    <div className="col-11 mypost_equity_terms">
                                                        <h5 className="mypost_comm_desc  text-ellipse200" style={{ width: "unset" }}>{obj.name + " - " + obj.commodity_desc}</h5>

                                                    </div>
                                                    <div className="col-12 mt-1 mb-1">
                                                        <span className="text-muted font-weight-normal mypost_equity_terms">Enquiry Terms</span>
                                                    </div>

                                                    <div className="col-12">
                                                        <div><span className="myPost_sub">Estimated Price:</span><span className="commodityWithTitle">{obj.price}&nbsp;{obj.price_currency}&nbsp;per&nbsp;{obj.price_unit}</span></div>
                                                        {/* <div><span className="myPost_sub">Shipment:</span><span className="commodityWithTitle">{obj.shipment}</span></div> */}
                                                        <div><span className="myPost_sub">Payment:</span> <span className="commodityWithTitle">{obj.payment_type}</span></div>
                                                    </div>



                                                    <div className="col-12 pt-3">
                                                        <p className="text-muted font-weight-normal mypost_volume mb-0">VOLUME PER MONTH: &nbsp;
                                        <span className="font-weight-bold mypost_volume_val">{obj.quantity}&nbsp;{obj.quantity_unit}</span>
                                                        </p>
                                                    </div>

                                                    <div className="col-6 mt-2">
                                                        <span className="text-muted font-weight-normal mypost_offer_tittle">Offer Created Date</span>
                                                        <p className="font-weight-bold mypost_offer_tittle_val">{this.formatDate(obj.createdtime, 'YYYY-MM-DD')}</p>
                                                    </div>
                                                    <div className="col-6 mt-2">
                                                        <span className="text-muted font-weight-normal mypost_offer_tittle">Offer Expiry Date</span>
                                                        <p className="font-weight-bold  mypost_offer_tittle_val">{this.formatDate(obj.offer_expiry_date, 'YYYY-MM-DD')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)}

                            </div>
                            <div className="container d575_none mt-3" style={{ marginBottom: "80px" }}>
                                {tableBodyData && tableBodyData.length > 0 &&
                                    <div className="card">

                                        <div className="card-body" style={{ padding: "0px" }}>
                                            <table className="table table-hover">
                                                <thead style={{ background: "#E7F6EC" }}>
                                                    <tr style={{ height: "72px" }}>
                                                        <th className="th_title myPosts_thead">Enquiry Number</th>
                                                        <th className="th_title myPosts_thead">Enquiry Status</th>
                                                        <th className="th_title myPosts_thead" style={{ width: "18s0px" }}>Commodity</th>
                                                        <th className="th_title myPosts_thead">Volume</th>
                                                        <th className="th_title myPosts_thead">Enquiry Terms</th>
                                                        <th className="th_title myPosts_thead">Date Of Posting</th>
                                                        <th className="th_title myPosts_thead">Offer Expiry Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tableBodyData && tableBodyData.length > 0 &&
                                                        tableBodyData.map((obj, index) =>
                                                            <tr key={index + "ss"}>
                                                                <td className="th_title" style={{ textAlign: "center" }}>{obj.id}
                                                                    <img src={id_go} alt="" style={{ float: "right", cursor: 'pointer', marginRight: "20%" }} onClick={(e) => this.showDetails(e, obj)} />
                                                                </td>
                                                                <td className="th_title"><div className={obj.sell_commodity_status && (obj.sell_commodity_status.toLowerCase() === "live" || obj.sell_commodity_status.toLowerCase() === "open") ? "enquiryStatus" : "enquiryStatusclosed"}>{obj.sell_commodity_status && obj.sell_commodity_status.toUpperCase()}</div></td>
                                                                <td className="commodityWithTitle text-ellipse200" >
                                                                    <Tooltip
                                                                        classes={{ tooltip: classes.tooltipCls }}
                                                                        title={obj.name + " - " + obj.commodity_desc} arrow>
                                                                        <span>{obj.name + " - " + obj.commodity_desc}</span>
                                                                        {/* <h5 className="mypost_comm_desc  text-ellipse200" style={{ width: "unset" }}></h5> */}
                                                                    </Tooltip>
                                                                </td>
                                                                <td className="commodityWithTitle">{obj.quantity}&nbsp;{obj.quantity_unit}</td>
                                                                <td className="enquiryTerms">
                                                                    <div style={{ display: "grid" }}>
                                                                        <div><span className="th_title">Estimated Price:</span><span className="commodityWithTitle">
                                                                            &nbsp;{obj.price + " " + obj.price_currency + " per " + obj.price_unit}
                                                                            {/* {obj.price}&nbsp;{obj.price_currency}&nbsp;per&nbsp;{obj.price_unit} */}
                                                                        </span></div>
                                                                        {/* <div><span className="th_title">Shipment:&nbsp;</span><span className="commodityWithTitle">{obj.shipment}</span></div> */}
                                                                        <div><span className="th_title">Payment:&nbsp;</span><span className="commodityWithTitle">{obj.payment_type}</span></div>
                                                                    </div>
                                                                </td>
                                                                <td className="th_title" style={{ color: obj.sell_commodity_status && (obj.sell_commodity_status.toLowerCase() === "live" || obj.sell_commodity_status.toLowerCase() === "open") ? "#198548" : "#A1131A" }}>{this.formatDate(obj.createdtime, 'YYYY-MM-DD')}</td>
                                                                <td className="th_title" style={{ fontWeight: "bold" }}>{this.formatDate(obj.offer_expiry_date, 'YYYY-MM-DD')}</td>
                                                            </tr>)}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>}
                            </div >

                        </section >
                        : <NoEnquires redirectToPath={'SellerEnquiryContainer'} titleText={"No Enquiries"} buttonText={"Send Enquiry"} headerTxt={"You have not submitted any enquiries"} />}
                </div> : <Loader />}
        </div >
        );
    }
}

MyPostsContainer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MyPostsContainer);