import React from 'react';
import { Auth, Hub } from 'aws-amplify';
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from 'react-router-dom';
import login_img from '../../assests/img/login.jpg';
import login_sm from '../../assests/icons/login_img_sm.png';
// import inputerror from '../../assests/icons/inputerror.svg';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttoncss: {
    width: '50%',
    borderRadius: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    backgroundColor: "#32ad79",
    color: "#fff",
    border: "none",
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: 'Lato, sans-serif',
    textTransform: "uppercase",
    paddingTop: "5px",
    paddingBottom: "5px",
    fontStretch: "normal",
    fontStyle: "normal",
    marginRight: "10px"
  },
  customInput: {
    width: "100%",
    background: "#fff"
  }
});

class LoginContainer extends React.Component {

  state = { user: null, customState: null };
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      loading: false,
      verifyCode: '',
      newPassword: '',
      showChallengeNewPasswordView: false,
      user: null,
      settings: null,
      code: '',
      challengeName: "login",
      isForgotPasswordViewActive: false,
      logoUrl: undefined,
      clientName: "",

      attributeType: '',
      attributeValue: '',
      showAttributeVericationScreen: false,
      showAttributeVericationScreenCode: false,
      attributeVerificationCode: '',
      errorFields: {},
      showLoginErrorMessage: false,
      emailValidation: {
        errorMsg: "",
        showErr: false,
        showSuccess: false
      }
    }

    this.handleSigninSubmit = this.handleSigninSubmit.bind(this)

  }

  componentDidMount() {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          this.setState({ user: data });
          // console.log("signIn");
          // alert("signIn")
          break;
        case "signOut":
          this.setState({ user: null });
          console.log("signOut");
          // alert("signOut")
          break;
        case "customOAuthState":
          this.setState({ customState: data });
          console.log("customOAuthState");
          // alert("customOAuthState");
          break;
        default:
          break;
      }
    });

    Auth.currentAuthenticatedUser()
      .then(user => {
        this.setState({ user });
        // alert(JSON.stringify(user)) 
      })
      .catch(() => {
        console.log("Not signed in");
        //  alert("notSignedin")
      });
  }

  checkIfValidEmail(emailId) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailId)) //eslint-disable-line
    {
      return true;
    }
    return false;
  }


  checkIfFormIsValid() {
    let errorFieldsVal = {};
    let isValid = true;

    if (!this.state.password || this.state.password.trim() === "") {
      errorFieldsVal["password"] = true;
      isValid = false;
    }
    if (!this.state.username || this.state.username.trim() === "") {
      errorFieldsVal["username"] = true;
      isValid = false;
    }else{
      if (this.state.username.trim() !== "" && !this.checkIfValidEmail(this.state.username)) {
        this.setState({
          emailValidation: {
            errorMsg: "Please Enter a vaild Email Address",
            showErr: true,
            showSuccess: false
          }
        })
        isValid = false;
      }
    }
  

    this.setState({ errorFields: errorFieldsVal })
    return isValid;

  }


  async handleSigninSubmit(e) {

    e.preventDefault();

    if (!this.checkIfFormIsValid()) {
      return;
    }
    this.setState({ loading: true });

    //console.log('Entered:', this.state)
    try {
      var data = await Auth.signIn(this.state.username, this.state.password);
      var response = data
      //console.log("Logged in" + JSON.stringify(response));
      if (response && response.challengeName === "NEW_PASSWORD_REQUIRED") {
        this.state.challengeName = response.challengeName;
        this.state.showChallengeNewPasswordView = true;
        this.state.user = response;
        this.state.loading = false;
        this.setState(this.state);
        return;
      }
      if (response && response.challengeName === 'SMS_MFA') {
        this.state.challengeName = response.challengeName;
        this.state.showChallengeNewPasswordView = true;
        this.state.user = response;
        this.state.loading = false;
        this.setState(this.state);
        return;
      }
      if (response) {
        this.setState({
          user: response,
          loading: false
        })
        Auth.currentAuthenticatedUser().then((user) => {
          console.log(JSON.stringify(user));
          console.log(user);
          console.log('user email = ' + user.attributes.email);
          window.localStorage.setItem('loggedInUserName', user.attributes.name);
          // window.sessionStorage.setItem('is_user_loggedIn', "true");

          // const user = await Auth.currentAuthenticatedUser();
          // const result = await Auth.updateUserAttributes(user, {
          //   'phone_number': '+918826536055'
          // });
          // console.log(result)
          // this.setState({ challengeName: "otp" })
          var authk = data.signInUserSession.idToken.jwtToken;
          window.localStorage.setItem('bijakGlobalToken', authk);
          window.localStorage.setItem('is_user_loggedIn', "true");
          window.localStorage.setItem("bijakGlobalExpTime", (new Date()).getTime());
          this.props.history.push(`/home`);
          // window.location.reload()
        });
        // this.redirectToDashboard(data);
      }
    } catch (e) {
      if (e && e.message && e.message.indexOf("Incorrect username or password")) {
        this.setState({ showLoginErrorMessage: true });
      } else {
        this.setState({ showLoginErrorMessage: true });
      }
      this.setState({ loading: false });
    }
  }

  redirectToDashboard(data) {
    this.props.history.push(`/home`);
  }

  handelInputChange(event) {
    event.preventDefault();
    try {
      let err = this.state.errorFields;
      let id = event.target.id;
      let val = event.target.value;
      console.log(id)
      err[id] = false;
      this.setState({
        [id]: val,
        errorFields: err,
        showLoginErrorMessage: false,
        emailValidation: {
          errorMsg: "",
          showErr: false,
          showSuccess: false
        }
      })
    } catch (err) {
      console.log(err);
    }
  }


  render() {
    const { classes } = this.props;
    const { username, password, errorFields, loading, emailValidation } = this.state;
    return (<>
      <section className="login_form">
        <div className="d-block d-sm-none">
          <div className="img_sm"> <img src={login_sm} alt="" width="100%"></img></div>
        </div>
        <div className="container cus_mb_120_sm">
          <form className="bootstrap-form needs-validation cus_sec_width section_padding" noValidate >

            <div className="row">
              <div className="col-sm-6">
                <h2 className="login_text">Log In</h2>
                <div className="form-row pt-5">
                  <div className="col-12">
                    <TextField
                      id="username"
                      label="Email Address"
                      fullWidth
                      value={username}
                      name="username"
                      InputProps={{
                        classes: {
                          root: classes.customInput,
                        },
                      }}
                      error={errorFields["username"] ? true : false}
                      placeholder="Email Address"
                      onChange={(event) => this.handelInputChange(event)}
                      variant="outlined"
                    >
                    </TextField>
                    <div className={"invalid-feedback " + (errorFields["username"] ? 'show_div' : '')}>This details cannot be empty</div>
                    <div className={"invalid-feedback " + (emailValidation["showErr"] ? 'show_div' : '')}>{emailValidation["errorMsg"]}</div>
                  </div>
                  <div className="col-12 mt-5">
                    <TextField
                      id="password"
                      label="Password"
                      fullWidth
                      value={password}
                      name="username"
                      InputProps={{
                        classes: {
                          root: classes.customInput,
                        },
                      }}
                      type={"password"}
                      error={errorFields["password"] ? true : false}
                      placeholder="Password"
                      // helperText={errorFields["commodity_id"] ? "This detail cannot be empty" : ''}
                      onChange={(event) => this.handelInputChange(event)}
                      // helperText="Please select your currency"
                      variant="outlined"
                    >

                    </TextField>
                    {/* <div className="valid-feedback">Details are okay</div> */}
                    <div className={"invalid-feedback " + (errorFields["password"] ? 'show_div' : '')}>This details cannot be empty</div>
                  </div>
                </div>
                {this.state.showLoginErrorMessage &&
                  <div className="pt-4">
                    <span className="d-inline-block text-danger" style={{ fontSize: '14px' }}>Incorrect username or password</span>
                  </div>}

                <div className="pt-4">
                  <span className="d-inline-block forget_password_txt">Forgot Password? <a
                    href="#" className="cus_purple font_reset"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push("/forgotpassword");
                    }}
                  >Reset it here</a></span>
                </div>

                <div className="text-right mt-4 d575_none">
                  <button className="btn btn-success px-4 text-uppercase cus_bg_green"
                    // type="submit"
                    disabled={loading}
                    onClick={(event) => { if (!loading) { this.handleSigninSubmit(event) } }}
                  >Login {loading && <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>}</button>
                </div>

                <div className="col-12 text-right mt-5 d_lg_none_sm fixed-bottom py-4 bg-white">
                  <button className="btn btn-success px-4 text-uppercase cus_bg_green w-100 py-3"
                    disabled={loading}
                    onClick={(event) => { if (!loading) { this.handleSigninSubmit(event) } }}
                  >Login</button>
                </div>

              </div>
              <div className="col-sm-6 d-flex justify-content-md-end d575_none" style={{ paddingRight: "0px" }}>
                <div>
                  <img src={login_img} className="w-100" alt="" height="586px" width="368px" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>);
  }
}

export default withStyles(styles)(withRouter(LoginContainer));
