import React, { Component } from 'react';
import './signup.css';
import Auth from '@aws-amplify/auth';
import { withRouter } from 'react-router-dom';
import { checkIfEmailOrPhoneAlreadyExists, getListOfCountries, removedUnConfirmedUser } from '../../services/commonService';
import successIcon from '../../assests/icons/ok_icon.svg';
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

var iti = "";


const styles = theme => ({
  snackbarRoot: {
    backgroundColor: "#fff"
  },
  inputRoot: {
    height: "54px"
  }
});



class SignUpContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: '+91',
      password: '',
      confirmPassword: '',
      emailId: '',
      phone_number: '',
      userOrgName: '',
      loading: false,
      verifyCode: '',
      newPassword: '',
      showChallengeNewPasswordView: false,
      user: null,
      settings: null,
      code: '',
      challengeName: "login",
      isForgotPasswordViewActive: false,
      logoUrl: undefined,
      clientName: "",
      showSnackbar: false,
      SnackbarMsg: '',
      "userOrganization": '',
      "userRole": '',
      mob_country_code: '91',
      errorFields: {},
      showPassword: {
        password: false,
        confirmPassword: false
      },
      preExistingDataCheck: {
        mobile: false,
        email: false
      },
      isEmailValid: true,
      signUpErrorMsg: '',
      confirmPasswordError: false,
      showPassowrdCriteria: false,
      phoneValidationError: false,
      passwordValidation: {
        errorMsg: "",
        showErr: false
      },
      openModal: false,
      otpValidation: {
        errorMsg: "",
        showErr: false,
        showSuccess: false
      },
      countryList: ['in'],
      countryListData: [],
      time: {},
      seconds: 120,
      selectedCountry: {
        createdtime: "2021-01-24T16:29:45.197Z",
        id: 95,
        is_enabled: true,
        mob_code: "91",
        name: "India",
        name_code: "IN",
        updatedtime: "2021-01-24T16:29:45.197Z"
      },
      countryListDataObj: {},
      showCountryList: false
    }

    this.timer = 0;
    this.countDown = this.countDown.bind(this);
    this.handleVerifySubmit = this.handleVerifySubmit.bind(this)
  }

  componentDidMount() {
    this.getCountryList();
  }


  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }

  startTimer() {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds == 0) {
      clearInterval(this.timer);
    }
  }


  async getCountryList(params) {
    try {
      let resp = await getListOfCountries(params);
      console.log(resp);
      let cArray = [];
      let cObj = {
        "IN": {
          createdtime: "2021-01-24T16:29:45.197Z",
          id: 95,
          is_enabled: true,
          mob_code: "91",
          name: "India",
          name_code: "IN",
          updatedtime: "2021-01-24T16:29:45.197Z"
        }
      };
      if (resp && resp.type === 1 && resp.data && resp.data.length > 0) {
        for (let i = 0; i < resp.data.length; i++) {
          if (resp.data[i]["is_enabled"]) {
            cArray.push(resp.data[i]["name_code"].toLowerCase());
            cObj[resp.data[i]["name_code"]] = resp.data[i];
          }
        }
      } else {
        cArray = ['in'];
      }

      this.setState({ countryList: cArray, countryListData: resp.data, countryListDataObj: cObj }, () => {
        this.initaiteCountryDropDown(cArray);
      })

    } catch (error) {
      console.log(error);
    }
  }

  initaiteCountryDropDown(cArray) {
    try {
      var input = document.querySelector("#phone_number");
      // if (input) {
      //   iti = window.intlTelInput(input, {
      //     initialCountry: "in",
      //     onlyCountries: cArray,
      //     separateDialCode: true,
      //     utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.3/build/js/utils.js"
      //   });
      //   window.iti = iti;
      // }
    } catch (er) {
      console.log(er)
    }
  }


  async checkIfEmailOrMobileAlreadyexists(params) {
    try {
      if (params.type === "mobile" && params.mobile.length === 0) {
        return;
      }
      if (params.type === "email" && !this.checkIfValidEmail(this.state.emailId)) {
        return;
      }

      if (params.type === "mobile") {
        // params.mob_country_code = iti.getSelectedCountryData()["dialCode"];
        params.mob_country_code = this.state.selectedCountry["mob_code"];
        // console.log(iti.isValidNumber())
        // console.log(iti.getNumber())
        // if (params.mobile.length ) {
        //   this.setState({ phoneValidationError: true });
        //   return;
        // }
        // if (iti.isValidNumber()) {
        //   this.setState({ phoneValidationError: false });
        // } else {
        //   this.setState({ phoneValidationError: true });
        //   return;
        // }
      }

      let resp = await checkIfEmailOrPhoneAlreadyExists(params);
      console.log(resp);
      let preExistingDataCheckVal = this.state.preExistingDataCheck;
      if (resp && resp.type === "success" && resp.data) {
        preExistingDataCheckVal[params.type] = true;
      } else {
        preExistingDataCheckVal[params.type] = false;
      }

      this.setState({
        preExistingDataCheck: preExistingDataCheckVal
      });
    } catch (error) {
      console.log(error);
    }
  }

  async handleVerifySubmit(e) {
    e.preventDefault();
    try {
      if (!this.state.code || this.state.code.trim() === "") {
        let errorField = this.state.errorFields;
        errorField["code"] = true;
        this.setState({ errorFields: errorField })
        return;
      }

      if (this.state.code.trim().length !== 6) {
        this.setState({
          otpValidation: {
            errorMsg: "Please a valid Otp",
            showErr: true,
            showSuccess: false
          }
        })
        return;
      }

      let username = this.state.emailId;
      await Auth.confirmSignUp(username, this.state.code);

      // this.setState({ openModal: true }, () => {
      //   setTimeout(() => {
      //     this.setState({ openModal: false }, () => {
      // this.onSignUpComplete();
      //     })
      //   }, 1000);
      // });

      this.setState({ showSnackBar: true }, () => {
        setTimeout(() => {
          this.setState({ showSnackBar: false }, () => {
            this.onSignUpComplete();
          })
        }, 2000);
      })

    } catch (error) {
      console.log('error confirming sign up', error);
      this.setState({
        otpValidation: {
          errorMsg: error.message.indexOf("Invalid verification code provided, please try again.") > -1 ? "Invalid OTP" : error.message,
          showErr: true,
          showSuccess: false
        }
      });

    }
  }

  async resendConfirmationCode() {
    try {
      let username = this.state.emailId;
      await Auth.resendSignUp(username);
      this.setState({
        otpValidation: {
          errorMsg: "OTP resent successfully",
          showErr: false,
          showSuccess: true
        }
      }, () => {
        this.setState({ seconds: 120 }, () => {
          clearInterval(this.timer);
          this.timer = 0;
          this.startTimer();
        })
      });

    } catch (err) {
      console.log('error resending code: ', err);
      this.setState({
        otpValidation: {
          errorMsg: err.message,
          showErr: true,
          showSuccess: false
        }
      });
    }
  }

  async signUp(event) {

    try {
      event.preventDefault();
      if (!this.checkIfFormIsValid()) {
        return;
      }

      let username = this.state.emailId;
      // let phone_number = '+' + iti.getSelectedCountryData()["dialCode"] + '' + this.state.phone_number;
      let password = this.state.password;
      let mobile = this.state.phone_number;
      // let role = this.state.userRole;
      // let mob_country_code = iti.getSelectedCountryData()["dialCode"]; //this.state.mob_country_code;
      let mob_country_code = this.state.selectedCountry["mob_code"]; //this.state.mob_country_code;
      // this.state.selectedCountry["mob_code"];
      let name = this.state.userOrgName;
      let organization = this.state.userOrganization;
      const { user, userConfirmed, userSub } = await Auth.signUp({
        username,
        password,
        attributes: {
          name,
          // phone_number,
          // 'custom:role': role,// other custom attributes 
          'custom:organization': organization,// other custom attributes 
          'custom:mob_country_code': mob_country_code,// other custom attributes 
          'custom:mobile': mobile// other custom attributes 
        }
      });
      console.log(user);
      console.log(userConfirmed);
      console.log(userSub);
      console.log(JSON.stringify(user));
      // alert(JSON.stringify(user));

      if (!userConfirmed) {
        this.setState({
          challengeName: "otp"
        }, () => {
          this.setState({ seconds: 120 }, () => {
            clearInterval(this.timer);
            this.timer = 0;
            this.startTimer();
          })
        })
      }
    } catch (error) {
      console.log('error signing up:', error);
      if (error && error.code) {
        if (error.message.indexOf("Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6") > -1) {
          this.setState({ signUpErrorMsg: "Please enter a valid password. Password must have length greater than or equal to 6" });
          return;
        }
        if (error && error.code === "UsernameExistsException") {
          // alert(error.message);
          this.handelAccountAlreadyExits(event);
          return;
        }
        this.setState({ signUpErrorMsg: error.message });
      }
    }
  }

  async handelAccountAlreadyExits(event) {
    try {
      let payload = { "email": this.state.emailId };
      let resp = await removedUnConfirmedUser(payload);
      // let resp = { type: 1 };
      console.log(resp);
      if (resp && resp.type === 1) {
        this.signUp(event);
      } else {
        this.setState({ signUpErrorMsg: "Oops an error occurred. Please try again later" });
      }
    } catch (err) {
      console.log(err);
    }
  }

  checkIfFormIsValid() {
    let errorFieldsVal = {};
    let confirmPasswordErrorVal = false;
    let isEmailValid = true;
    let isValid = true;
    if (!this.state.userOrgName || this.state.userOrgName.trim() === "") {
      errorFieldsVal["userOrgName"] = true;
      isValid = false;
    }
    if (!this.state.userOrganization || this.state.userOrganization.trim() === "") {
      errorFieldsVal["userOrganization"] = true;
      isValid = false;
    }
    if (!this.state.emailId || this.state.emailId.trim() === "") {
      errorFieldsVal["emailId"] = true;
      isValid = false;
    }
    if (!this.state.phone_number || this.state.phone_number.trim() === "") {
      errorFieldsVal["phone_number"] = true;
      isValid = false;
    }
    if (!this.state.password || this.state.password.trim() === "") {
      errorFieldsVal["password"] = true;
      isValid = false;
    }
    if (!this.state.confirmPassword || this.state.confirmPassword.trim() === "") {
      errorFieldsVal["confirmPassword"] = true;
      isValid = false;
    }
    if (this.state.phoneValidationError) {
      isValid = false;
    }

    if (!this.checkIfValidEmail(this.state.emailId)) {
      isValid = false;
      isEmailValid = false;
    }

    if (this.state.preExistingDataCheck["email"] || this.state.preExistingDataCheck["mobile"]) {
      isValid = false;
    }

    if (isValid && this.state.password !== this.state.confirmPassword) {
      confirmPasswordErrorVal = true;
      isValid = false;
    }

    this.setState({ errorFields: errorFieldsVal, isEmailValid: isEmailValid, confirmPasswordError: confirmPasswordErrorVal })
    return isValid;

  }

  handelInputChange(event) {
    event.preventDefault();
    try {
      let err = this.state.errorFields;
      let id = event.target.id;
      let val = event.target.value;
      let preExistingDataCheckVal = this.state.preExistingDataCheck;

      if (id === "phone_number" || id === "code") {
        if (!isNaN(val)) {
          // iti.setNumber("+91" + val);
        } else {
          return;
        }
      }
      if (id === "phone_number" && preExistingDataCheckVal['mobile']) {
        preExistingDataCheckVal["mobile"] = false;
        this.setState({
          preExistingDataCheck: preExistingDataCheckVal
        });
      }

      if (id === "emailId" && preExistingDataCheckVal['email']) {
        preExistingDataCheckVal["email"] = false;
        this.setState({
          preExistingDataCheck: preExistingDataCheckVal
        });
      }

      if (id === "emailId" && !this.state.isEmailValid) {
        this.setState({
          isEmailValid: true
        });
      }

      if (id === "code") {
        this.setState({
          otpValidation: {
            errorMsg: "",
            showErr: false,
            showSuccess: false
          }
        });

      }
      console.log(id)
      err[id] = false;
      this.setState({
        [id]: val,
        errorFields: err
      }, () => {
        if (id === "password") {
          this.checkForValidPassWord(this.state.password)
        }
      })
    } catch (err) {
      console.log(err);
    }
  }

  togglePasswordView(event, id) {
    event.preventDefault();
    let showPasswordVal = this.state.showPassword;
    showPasswordVal[id] = !showPasswordVal[id];
    try {
      this.setState({
        showPassword: showPasswordVal
      })
    } catch (err) {
      console.log(err);
    }
  }

  checkIfValidEmail(emailId) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailId)) //eslint-disable-line
    {
      return true;
    }
    return false;
  }

  checkForValidPassWord(pwd) {
    if (pwd && pwd.length > 0) {
      if (pwd.length >= 6) {
        this.setState({
          passwordValidation: {
            errorMsg: "",
            showErr: false
          }
        });
      } else {
        let inValidMsg = [];
        // if (!/^(?=.*[a-z])/.test(pwd)) {
        //   inValidMsg.push(" 1 lowercase character");
        // }
        // if (!/^(?=.*?[A-Z])/.test(pwd)) {
        //   inValidMsg.push(" 1 uppercase character");
        // }
        // if (!/^(?=.*?[0-9])/.test(pwd)) {
        //   inValidMsg.push(" 1 number");
        // }
        // if (!/^(?=.*?[#?!@$%^&*-])/.test(pwd)) {
        //   inValidMsg.push(" 1 special character");

        // }
        if (!/^[A-Za-z\d@$!%*?&]{6,}/.test(pwd)) {
          inValidMsg.push("Password length must be more than 5");
        }
        // inValidMsg = "Password must contain atleast" + inValidMsg.toString();
        inValidMsg = inValidMsg.toString();
        this.setState({
          passwordValidation: {
            errorMsg: inValidMsg,
            showErr: true
          }
        });
      }
    }
  }

  async onSignUpComplete() {
    try {
      var data = await Auth.signIn(this.state.emailId, this.state.password);
      Auth.currentAuthenticatedUser().then((user) => {
        console.log(JSON.stringify(user));
        console.log(user);
        console.log('user email = ' + user.attributes.email);
        var authk = data.signInUserSession.idToken.jwtToken;
        window.localStorage.setItem('bijakGlobalToken', authk);
        window.localStorage.setItem('loggedInUserName', user.attributes.name);
        window.localStorage.setItem('is_user_loggedIn', "true");
        window.localStorage.setItem("bijakGlobalExpTime", (new Date()).getTime());
        this.props.history.push(`/myprofile`);
      });
    } catch (err) {
      console.log(err)
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  showSnackBar() {
    this.setState({ showSnackBar: true }, () => {
      setTimeout(() => {
        this.setState({ showSnackBar: false })
      }, 2000);
    })
  }

  onCountryChange(event, obj) {
    try {
      event.preventDefault();
      let val = event.target.value;
      this.setState({ selectedCountry: obj, showCountryList: false })
    } catch (er) {
      console.log(er)
    }
  }

  render() {
    const { classes } = this.props;
    const { userOrgName, phone_number, emailId, userOrganization,
      password, code, confirmPassword, errorFields, showPassword, phoneValidationError, passwordValidation,
      preExistingDataCheck, isEmailValid, otpValidation, countryListData, selectedCountry, countryListDataObj } = this.state;

    // return (<SignupVerified/>)

    return (<>
      <section className="sec_bg signup_form">
        <div className="container cus_mb_120_sm">
          {this.state.challengeName === 'login' &&
            <form className="bootstrap-form needs-validation cus_sec_width tb_padding" novalidate>
              <h1 className="signUp_title">Create your Bijak Global Account</h1>
              <div className="form-row pt-5">

                <div className="col-md-6 mb-5 cus_15p_right">
                  <label htmlFor="userOrgName">Your Name</label>
                  <input type="text"
                    className={"form-control" + (errorFields["userOrgName"] ? " is-invalid" : '')}
                    id="userOrgName" name="userOrgName"
                    value={userOrgName}
                    onChange={(event) => this.handelInputChange(event)}
                    placeholder="Please Enter your name here"
                    autocomplete="off" />
                  <div className={"invalid-feedback " + (errorFields["userOrgName"] ? 'show_div' : 'hide_div')}>This details cannot be empty</div>
                </div>

                <div className="col-md-6 mb-5 cus_15p_left">
                  <label htmlFor="userOrganization">Name of your Organisation</label>
                  <input type="text" className={"form-control" + (errorFields["userOrganization"] ? " is-invalid" : '')}
                    id="userOrganization" name="userOrganization"
                    value={userOrganization}
                    onChange={(event) => this.handelInputChange(event)}
                    placeholder="Enter your organisation name"
                    autocomplete="off" />
                  {/* <div className="valid-feedback">Details are okay</div> */}
                  <div className={"invalid-feedback " + (errorFields["userOrganization"] ? 'show_div' : 'hide_div')}>This details cannot be empty</div>
                </div>

                <div className="col-md-6 mb-5 cus_15p_right">
                  <label htmlFor="emailId">Email ID <br className="d_lg_none_sm" /><span>(This will be your login ID)</span></label>
                  <input type="email"
                    className={"form-control" + (errorFields["emailId"] ? " is-invalid" : '')}
                    id="emailId" name="emailId"
                    placeholder="Please Enter your Email here"
                    value={emailId}
                    onBlur={() => this.checkIfEmailOrMobileAlreadyexists({ "type": "email", "email": emailId })}
                    onChange={(event) => this.handelInputChange(event)}
                    autoComplete="off" />
                  {errorFields["emailId"] && <div className="invalid-feedback show_div">This details cannot be empty</div>}
                  {preExistingDataCheck["email"] && <div className="invalid-feedback show_div">An account with this email already exists</div>}
                  {!isEmailValid && !preExistingDataCheck["email"] && !errorFields["emailId"] && <div className="invalid-feedback show_div">Please enter a valid email id</div>}
                </div>

                <div className="col-md-6 mb-5 cus_15p_left">


                  <label htmlFor="phone_number">Mobile Number</label><br />
                  {/* <div className="btn-group w-100">              

                    <ul className="iti__country-list iti__country-list--dropup" id="country-listbox" aria-expanded="true" role="listbox" aria-activedescendant="iti-item-in">
                      <li className="iti__country iti__standard" tabindex="-1" id="iti-item-us" role="option" data-dial-code="1" data-country-code="us" aria-selected="true"><div className="iti__flag-box"><div className="iti__flag iti__us"></div></div><span className="iti__country-name">Us</span><span className="iti__dial-code">+1</span></li>
                      <li className="iti__country iti__standard iti__active" tabindex="-1" id="iti-item-in" role="option" data-dial-code="91" data-country-code="in" aria-selected="true"><div className="iti__flag-box"><div className="iti__flag iti__in"></div></div><span className="iti__country-name">India (भारत)</span><span className="iti__dial-code">+91</span></li>
                    </ul>

                    <input id="phone_number"
                      name="phone_number"
                      placeholder="Mobile Number"
                      maxLength={10}
                      value={phone_number}
                      style={{ float: "right" }}
                      onBlur={() => this.checkIfEmailOrMobileAlreadyexists({ "type": "mobile", "mobile": phone_number })}
                      onChange={(event) => this.handelInputChange(event)} className={"form-control w-100 ml-3" + (errorFields["phone_number"] ? " is-invalid" : '')}
                      type="text" autocomplete="off" />
                 
                  </div> */}
                  {/* {
          createdtime: "2021-01-24T16:29:45.197Z",
          id: 95,
          is_enabled: true,
          mob_code: "91",
          name: "India",
          name_code: "IN",
          updatedtime: "2021-01-24T16:29:45.197Z"
        } */}
                  <div className="iti iti--allow-dropdown iti--separate-dial-code"><div className="iti__flag-container">
                    <div className="iti__selected-flag "
                      tabindex="-1"
                      onClick={() => this.setState({ showCountryList: !this.state.showCountryList })}
                      onKeyPress={(event) => {
                        try {
                          console.log(event.charCode )
                          if (event.key && event.charCode >= 65 && event.charCode <= 122) {
                            if (event.charCode < 91 || event.charCode >= 97) {
                              var list = document.getElementById("country-listbox"),
                                targetLi = document.getElementById(event.key.toLowerCase()); // id tag of the <li> element
                              list.scrollTop = (targetLi.offsetTop);
                            }
                          }
                        } catch (er) {
                          console.log(er)
                        }
                      }
                      }
                      onBlur={() => {
                        setTimeout(() => {
                          this.setState({ showCountryList: false })
                        }, 300);
                      }}
                      role="combobox"
                      aria-owns="country-listbox"
                    // tabindex="0"
                    >
                      <div className={"iti__flag iti__" + selectedCountry.name_code.toLowerCase()}></div>
                      <div className="iti__selected-dial-code">+{selectedCountry.mob_code}</div>
                      <div className={"iti__arrow iti__arrow--" + (this.state.showCountryList ? "up" : "down")}></div>
                    </div>
                    <ul className={"iti__country-list iti__country-list--dropup " + (!this.state.showCountryList ? "iti__hide" : "")} id="country-listbox" aria-expanded="true" role="listbox" aria-activedescendant="iti-item-in">

                      {countryListDataObj && Object.keys(countryListDataObj).map((key, cObjind) => (
                        <li className="iti__country iti__standard flaglist_hover " tabindex="-1"
                          onClick={(event) => this.onCountryChange(event, countryListDataObj[key])}
                          // id={"iti-item-" + key.toLowerCase()}
                          id={countryListDataObj[key]["name"][0].toLowerCase()}
                          role="option" data-dial-code={countryListDataObj[key]["mob_code"]} data-country-code={key.toLowerCase()} aria-selected="true">
                          <div className="iti__flag-box" >
                            <div className={"iti__flag iti__" + key.toLowerCase()}></div>
                          </div>
                          <span className="iti__country-name">{countryListDataObj[key]["name"]}</span><span className="iti__dial-code">+{countryListDataObj[key]["mob_code"]}</span>
                        </li>
                      ))}

                    </ul>
                  </div>
                    <input id="phone_number"
                      name="phone_number"
                      placeholder="Mobile Number"
                      maxLength={10}
                      value={phone_number}
                      style={{ float: "right" }}
                      onBlur={() => this.checkIfEmailOrMobileAlreadyexists({ "type": "mobile", "mobile": phone_number })}
                      onChange={(event) => this.handelInputChange(event)} className={"form-control w-100 ml-3" + (errorFields["phone_number"] ? " is-invalid" : '')}
                      type="text" autocomplete="off" />

                  </div>

                  <div className={"invalid-feedback " + (errorFields["phone_number"] ? 'show_div' : 'hide_div')}>This details cannot be empty</div>
                  {preExistingDataCheck["mobile"] && <div className="invalid-feedback show_div">An account with this Mobile already exists</div>}
                  {phoneValidationError && <div className="invalid-feedback show_div">Please enter a valid number</div>}
                </div>

                <div className="col-md-6 mb-5 cus_15p_right">
                  <label htmlFor="password">Set a password</label>
                  <div className="position-relative">
                    <input
                      className={"form-control " + (errorFields["password"] ? " is-invalid" : '')}
                      id="password" name="password"
                      type={showPassword['password'] ? "text" : "password"}
                      value={password}
                      // onFocus={() => this.setState({ showPassowrdCriteria: password.trim() === "" ? true : false })}
                      // onBlur={() => this.setState({ showPassowrdCriteria: password.trim() === "" ? true : false })}
                      onChange={(event) => this.handelInputChange(event)}
                      placeholder="Enter your password" autocomplete="off" />

                    <div className="position-absolute eye_icon">
                      <i
                        className={showPassword['password'] ? 'fa fa-eye-slash' : "fa fa-eye"}
                        onClick={(event) => this.togglePasswordView(event, "password")}
                        aria-hidden="true" id="togglePassword"
                        style={{ cursor: "pointer" }}></i>

                    </div>

                  </div>
                  {this.state.showPassowrdCriteria && <div className="valid-feedback show_div">Password length must be minimum six charcter and it should contain minimum 1 uppercase, 1 number and a special character</div>}
                  <div className={"invalid-feedback " + (errorFields["password"] ? 'show_div' : 'hide_div')}>This details cannot be empty</div>
                  <div className={"invalid-feedback " + (passwordValidation["showErr"] ? 'show_div' : 'hide_div')}>{passwordValidation["errorMsg"]}</div>

                </div>

                <div className="col-md-6 mb-5 cus_15p_left">
                  <label htmlFor="password">Confirm Password</label>
                  <div className="position-relative">
                    <input
                      className={"form-control" + (errorFields["confirmPassword"] ? " is-invalid" : '')}
                      id="confirmPassword" name="confirmPassword"
                      value={confirmPassword}
                      type={showPassword['confirmPassword'] ? "text" : "password"}
                      onChange={(event) => this.handelInputChange(event)}
                      placeholder="Enter your password"
                      autocomplete="off" />

                    <div className="position-absolute eye_icon">
                      <i className={showPassword['confirmPassword'] ? 'fa fa-eye-slash' : "fa fa-eye"}
                        onClick={(event) => this.togglePasswordView(event, "confirmPassword")}
                        aria-hidden="true" id="toggleCPassword" style={{ cursor: "pointer" }}></i>

                    </div>
                  </div>
                  {/* <div className="valid-feedback">Details are okay</div> */}
                  {/* <div className="invalid-feedback">This details cannot be empty</div> */}
                  <div className={"invalid-feedback " + (!errorFields["confirmPassword"] && this.state.confirmPasswordError ? 'show_div' : 'hide_div')}>Confirm password must be same as password</div>
                  <div className={"invalid-feedback " + (errorFields["confirmPassword"] ? 'show_div' : 'hide_div')}>This details cannot be empty</div>
                </div>
              </div>
              <div>
                {this.state.signUpErrorMsg}
              </div>
              <div className="text-right mt-5 d575_none">
                <button className="btn btn-success text-uppercase cus_bg_green"
                  // type="submit"
                  onClick={(event) => {
                    this.signUp(event);
                  }}
                >Become A Trader At Bijak</button>
              </div>
              <div className="col-12 text-right mt-5 d_lg_none_sm fixed-bottom py-4 bg-white">
                <button className="btn btn-success px-4 text-uppercase cus_bg_green w-100 py-3"
                  onClick={(event) => {
                    this.signUp(event)
                  }}
                >Become A Trader At Bijak</button>
              </div>
            </form>}

          {this.state.challengeName !== 'login' &&
            <form className="bootstrap-form needs-validation cus_sec_width tb_padding" style={{ padding: "45px 15px" }} novalidate>
              <a href="#" className="cus_purple closeBtn serach_goBack_txt" onClick={(event) => {
                event.preventDefault(); this.setState({ challengeName: "login" }, () =>
                  this.initaiteCountryDropDown(this.state.countryList));
                clearInterval(this.timer);
              }}>
                <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;
                                    Go back
                 </a>
              <h1 className="pt-4">Please enter OTP for email verification. Only verified traders will be registered</h1>
              <div className="form-row pt-5">

                <div className="col-md-6 mb-4 cus_15p_right">
                  <label htmlFor="name">OTP</label>
                  <input
                    className="form-control"
                    id="code" name="code"
                    type="text"
                    value={code}
                    style={otpValidation["showErr"] ? { border: "1px solid red", color: "red" } : {}}
                    maxLength={6}
                    onChange={(event) => this.handelInputChange(event)}
                    placeholder="Please enter the OTP received "
                    autocomplete="off" />
                  <div className={"invalid-feedback " + (errorFields["code"] ? 'show_div' : 'hide_div')}>This details cannot be empty</div>

                  {!errorFields["code"] && <div className={"invalid-feedback " + (otpValidation["showErr"] ? 'show_div' : 'hide_div')}>{otpValidation["errorMsg"]}</div>}
                  {<div className={"invalid-feedback text-success" + (otpValidation["showSuccess"] ? 'show_div' : 'hide_div')}>{otpValidation["errorMsg"]}</div>}



                  <div className="pt-4">
                    {/* <span className="d-inline-block forget_password_txt">Did not receive OTP? */}
                    {this.state.seconds && this.state.seconds !== 0 ?
                      <span className="d-inline-block forget_password_txt">OTP is valid for &nbsp;
                        <span>{parseInt(this.state.seconds / 60) + ":" + this.state.seconds % 60} min</span>
                      </span> : <span className="d-inline-block forget_password_txt">Did not receive OTP? &nbsp; <a
                        href="#" className="cus_purple font_reset"
                        onClick={(e) => {
                          e.preventDefault();
                          this.resendConfirmationCode();
                        }}
                      >Resend OTP</a>
                      </span>}
                  </div>

                  {/* <div className="d-flex align-items-center justify-content-between mt-5">
                    {this.state.seconds && this.state.seconds !== 0 ?
                      <p className="mb-0 myProfile_otp_valid_txt">OTP is valid for <span>{parseInt(this.state.seconds / 60) + ":" + this.state.seconds % 60} min</span></p> :
                      <p className="mb-0 myProfile_otp_valid_txt">
                        <span className="myprofile_resendotp"
                          onClick={(event) => {
                            event.preventDefault();
                            this.resendConfirmationCode(event);
                          }}>Resend OTP</span></p>}
                  </div> */}


                </div>



              </div>
              <div className=" mt-5 d575_none">
                <button className="btn btn-success text-uppercase cus_bg_green"
                  onClick={(event) => { this.handleVerifySubmit(event) }}
                > Verify the OTP</button>
              </div>
              <div className="col-12 text-right mt-5 d_lg_none_sm fixed-bottom py-4 bg-white">
                <button
                  onClick={(event) => { this.handleVerifySubmit(event) }}
                  className="btn btn-success px-4 text-uppercase cus_bg_green w-100 py-3"> Verify the OTP</button>
              </div>
            </form>}

        </div>
      </section>
      {/* <PopUpView modalText="Your email ID is successfully verified with us"
        open={this.state.openModal}
        popUpType={"success"}
        onPopUpViewClose={() => this.onSignUpComplete()} /> */}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={this.state.showSnackBar}
        style={{ backgroundColor: "#fff", top: "25%" }}
        ContentProps={{
          classes: {
            root: classes.snackbarRoot
          }
        }}
        onClose={() => this.setState({ showSnackBar: false })}
        message={
          <div className="d-flex">
            <div className="col-md-1 ml-auto">
              <img src={successIcon} alt="" />
            </div>
            <div className="col-11 ml-auto modal_text">
              Your email ID is successfully verified with us
            </div>
          </div>
        }
        key={"top" + "center"}
      />
    </>);
  }
}
export default withStyles(styles)(withRouter(SignUpContainer));