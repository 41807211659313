import aws_exports_prod from '../aws-exports-prod';
import aws_exports_staging from '../aws-exports-staging';



// checking if dev / staging / prod
export function getEnviornmentType() {
    var hostName = window.location.hostname;
    let envType = '';
    if ((hostName.indexOf("bijakglobal-ui-prod") > -1 || hostName.indexOf("www.bijakglobal.com") > -1 ||
        hostName.indexOf("bijakglobal.com") > -1 ||
        hostName.indexOf("www.bijakglobal.com") > -1 || hostName.indexOf("d15k410wdkutv7.cloudfront.net") > -1)
        && hostName.indexOf("dev") === -1) {
        envType = "prod";
    }    // else if (hostName.indexOf('dhd1ko7pyz1ni.cloudfront.net') > -1 ||
    //     hostName.indexOf('bijakglobal-ui-dev.s3-website.ap-south-1.amazonaws.com') > -1 || hostName.indexOf(`localhost`) > -1  ) {
    //     envType = "staging";
    // } 
    else {
        envType = "dev";
        // envType = "prod";

    }
    return envType;
    // if (hostName.indexOf('localhost') > -1 || hostName.indexOf('dev') > -1 || hostName.indexOf('d3p3xvthu08yeb') > -1) {

}

//  get bucket name
export function getAwsConfig() {
    let type = getEnviornmentType();
    console.log("Current app is running on : " + type);
    if (type === "dev") {
        return aws_exports_staging;
    } else {
        return aws_exports_prod;
    }
}

