import React from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from "@material-ui/core/styles/withStyles";
import no_doc from '../../assests/icons/no_doc.svg';

const styles = theme => ({
    buttoncss: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "18px",
        lineHeight: "24px",
        letterSpacing: "1.25px",
        textTransform: "uppercase",
        color: "#FFFFFF"
    }
});


class NoEnquires extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            redirectToPath: ''
        }
    }

    render() {
        // const { commodityListOptions, searchedText, showLoader } = this.state;
        const { classes } = this.props;

        return (

            <section>
                <div className="container d-flex align-items-center justify-content-center flex-column h-75" style={{ background: "rgb(241 244 247)", marginTop: '10px' }}>
                    {/* <!-- <div> --> */}
                    <div><img src={no_doc} style={{ marginTop: "96px" }} alt="" /></div>
                    <div className="text-center pt-5">
                        <h2 className="signup_verificationTxt">{this.props.titleText}</h2>
                        <p className="pt-3 signup_verificationTxt_small">{this.props.headerTxt ? this.props.headerTxt : ""} </p>
                        {this.props.redirectToPath &&
                            this.props.redirectToPath !== '' &&
                            <input type="submit" className={classes.buttoncss + " btn btn-success cus_btn"}
                                onClick={() => this.props.history.push(`/` + this.props.redirectToPath)}
                                style={{ background: "#198548" }}
                                value={this.props.buttonText ? this.props.buttonText : "CTA BUTTON"} />}
                    </div>
                </div>
            </section>
        );
    }

}

export default withStyles(styles)(withRouter(NoEnquires));