import React from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from "@material-ui/core/styles/withStyles";
import { fade } from '@material-ui/core/styles';
import { searchCommodity } from '../../services/commonService';
import searchicon from '../../assests/icons/searchicon.svg';
import white_search_icon from '../../assests/img/icons/white_search_icon.png';


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '60ch',
            '&:focus': {
                width: '70ch',
            },
        },
    },
    inputInp1ut: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
});


class SearchContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            options: [{ 'name': 'A' }, { 'name': 'B' }, { 'name': 'C' },],
            loading: false,
            dummyProductList: [{ product: 1, productName: "Rice" }, { product: 2, productName: "Dal" }, { product: 3, productName: "Wheat" }],
            searchedText: '',
            showSearchedDetails: false,
            commodityListOptions: [],
            typing: false,
            typingTimeout: 0,
            showLoader: false,
            showList: false,
            selectedItem: undefined
        }
    }

    componentDidMount() {
        this.searchCommodity();
    }

    async searchCommodity(event) {
        // e.preventDefault();
        try {
            // let resp = await searchPostedCommodity(1);
            let resp = await searchCommodity(this.state.searchedText);
            console.log(resp)
            let clo = [];
            if (resp && resp.type === 1) {
                clo = resp.data;
            }
            this.setState({ commodityListOptions: clo, showLoader: false });
        } catch (error) {
            console.log(error);
        }
    }

    onSearchKeyChanged(event) {
        let val = event.target.value;
        // const self = this;
        // if (self.state.typingTimeout) {
        //     clearTimeout(self.state.typingTimeout);
        // }
        // self.setState({
        //     searchedText: event.target.value,
        //     typing: false,
        //     showLoader: true,
        //     typingTimeout: setTimeout(function () {
        // self.sendToParent(self.state.name);
        // this.props.onSearchChange(event.target.value);
        this.searchCommodity(val);
        //     }, 500)
        // });
        // this.setState({ searchedText: val }, () => {


        // });

    }

    render() {
        const { commodityListOptions, searchedText, showList } = this.state;
        // const { classes, showSearchedDetails } = this.props;

        return (



            <section className="glbl_search_sec bg-white py-3 cus_btm_shadow">
                <div className="container">
                    <form action="" className="cus_sec_width" style={{ width: "89%" }}>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text border-right-0" id="inputGroupPrepend">
                                    <img src={searchicon} alt="" />
                                </span>
                            </div>
                            <div className="form-control border-left-0 glbl_srch pl-0 search_frame_input_updt">
                                <input
                                    // type="text"
                                    className="form-control border-left-0 glbl_srch pl-0 search_frame_input_updt"
                                    value={searchedText}
                                    onFocus={() => this.setState({ showList: true })}
                                    onBlur={() => {
                                        setTimeout(() => {
                                            this.setState({ showList: false })
                                        }, 300);
                                    }}
                                    style={{ border: '0px' }}
                                    onChange={(e) => this.setState({ searchedText: e.target.value })}
                                    id="searchGlobally"
                                    autoComplete="off"
                                    type="new-password"
                                    placeholder="Explore agricultural commodities to buy/sell globally" aria-describedby="inputGroupPrepend" required />
                                {showList && commodityListOptions && commodityListOptions.length > 0 &&
                                    <ul className="list-group" style={{ position: "absolute", zIndex: 1031, border: "0px", width: "100%", marginTop: "10px" }}>
                                        {commodityListOptions.filter(e => e.name.toLowerCase().indexOf(searchedText.trim().toLowerCase()) > -1).map((obj, index) =>
                                            <li
                                                key={"_" + index}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({ searchedText: obj.name, showList: false, selectedItem: obj });
                                                }}
                                                className="list-group-item pointer font_r_500 "
                                                style={{ cursor: "pointer" }}>
                                                {obj.name}
                                            </li>
                                        )}
                                        {commodityListOptions.filter(e => e.name.toLowerCase().indexOf(searchedText.trim().toLowerCase()) > -1).length === 0 &&
                                            <li
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                }}
                                                className="list-group-item pointer font_r_500 ">
                                                No results found
                                            </li>
                                        }
                                    </ul>
                                }
                            </div>
                            <input type="submit" className="btn btn-success cus_btn d575_none"
                                onClick={(e) => { e.preventDefault(); if (this.state.selectedItem) { this.props.onProductSelected(this.state.selectedItem); } }}
                                style={{ color: '#fff' }} value="Find Sellers" />

                            <button className="btn btn-success cus_btn d_lg_none_sm">
                                <img src={white_search_icon} alt=""
                                    onClick={(e) => { e.preventDefault(); if (this.state.selectedItem) { this.props.onProductSelected(this.state.selectedItem); } }} />
                            </button>
                        </div>
                    </form>
                </div>
            </section>
        );
    }

}

export default withStyles(styles)(withRouter(SearchContainer));