import React from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from "@material-ui/core/styles/withStyles";
import successIcon from '../../assests/icons/ok_icon.svg';
import errorIcon from '../../assests/icons/error_icon.svg';
import $ from 'jquery';

const styles = theme => ({
    buttoncss: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "18px",
        lineHeight: "24px",
        letterSpacing: "1.25px",
        textTransform: "uppercase",
        color: "#FFFFFF"
    }
});


class PopUpView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.open !== this.props.open && nextProps.open) {
            this.onModalOpen();
        }
    }

    onModalClose(e) {
        e.preventDefault();
        window.$('#bijakglobal_modal').modal('hide');
        this.props.onPopUpViewClose();
    }

    onModalOpen() {
        window.$('#bijakglobal_modal').modal('show');
    }

    render() {
        const { modalText, popUpType } = this.props;
        // isSuccess
        return (<>
            <div className="modal fade" id="bijakglobal_modal" tabindex="-1" role="dialog"
                onClick={(e) => this.onModalClose(e)}
                aria-labelledby="bijakglobal_modalLabel" >
                <div className="modal-dialog" style={{ paddingTop: "10%" }} role="document">
                    <div className="modal-content">
                        <div className="modal-header border-bottom-0">
                            <div className="col-md-1 ml-auto">
                                <img src={popUpType && popUpType !== '' && popUpType === "success" ? successIcon : errorIcon} alt="" />
                            </div>
                            <div className="col-11 ml-auto modal_text">
                                {modalText}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }

}

export default withStyles(styles)(withRouter(PopUpView));