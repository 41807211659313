import * as zlib from "react-zlib-js";
// import { Auth } from 'aws-amplify';


function decrypt(input) {
    var inflated = zlib.inflateSync(new Buffer(input, 'base64')).toString();
    return inflated
}


function checkIfKYCverified(type) { // seller // buyer
    var pfData = sessionStorage.getItem("profileData");
    if (pfData && pfData !== 'null' && pfData !== 'undefined') {
        let upfData = JSON.parse(pfData);
        if (type === 'buyer') {
            if (upfData["kyc_status_buyer"] === "accepted") {
                return true;
            } else if (upfData["kyc_status_buyer"] === "rejected") {
                return false;
            } else if (upfData["kyc_status_buyer"] === "pending") {
                return true;
            } else if (upfData["kyc_status_buyer"] === "nodata") {
                return false;
            } else {
                return false;
            }
            // return upfData["kyc_status_buyer"];
        } else {
            if (upfData["kyc_status_seller"] === "accepted") {
                return true;
            } else if (upfData["kyc_status_seller"] === "rejected") {
                return false;
            } else if (upfData["kyc_status_seller"] === "pending") {
                return true;
            } else if (upfData["kyc_status_seller"] === "nodata") {
                return false;
            } else {
                return false;
            }
            // return upfData["kyc_status_seller"];
        }
    }
    return false;
}

function CheckIfUserLoggedIn() {
    let isloggedIn = window.localStorage.getItem("is_user_loggedIn");
    let username = window.localStorage.getItem("loggedInUserName");
    if (isloggedIn && isloggedIn !== 'null') {
        return { isLoggedIn: isloggedIn === 'true' ? true : false, loggedInUserName: username };
    } else {
        return { isLoggedIn: false, loggedInUserName: '' };
    }
}

function getTommorowSDate() {
    let today = new Date()
    let tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    return tomorrow.getMonth() + 1 + "/" + tomorrow.getDate() + "/" + tomorrow.getFullYear();
}

function openLinkInNewTab(url) {
    try {
        window.open(url, '_blank');
    } catch (err) {
        console.log(err);
    }
}

function saveDataToLocal(key, val) {
    var ldata = window.localStorage.getItem("bijakLocalData");
    if (ldata && ldata !== null) {
        ldata = JSON.parse(ldata);
        ldata[key] = val;
    } else {
        ldata = {};
        ldata[key] = val;
    }
    console.log(ldata);
    ldata = JSON.stringify(ldata);
    window.localStorage.setItem("bijakLocalData", ldata);
}

function getDataFromLocal(key) {
    var ldata = window.localStorage.getItem("bijakLocalData");
    if (ldata && ldata !== null) {
        ldata = JSON.parse(ldata);
        console.log(ldata);
        return ldata[key];
    } else {
        return null;
    }
}

function saveDataToSession(key, val) {
    var ldata = window.sessionStorage.getItem("bijakSessionData");
    if (ldata && ldata !== null) {
        ldata = JSON.parse(ldata);
        ldata[key] = val;
    } else {
        ldata = {};
        ldata[key] = val;
    }
    console.log(ldata);
    ldata = JSON.stringify(ldata);
    window.sessionStorage.setItem("bijakSessionData", ldata);
}

function getDataFromSession(key) {
    var ldata = window.sessionStorage.getItem("bijakSessionData");
    if (ldata && ldata !== null) {
        ldata = JSON.parse(ldata);
        console.log(ldata);
        return ldata[key];
    } else {
        return null;
    }

}

const CommonUtil = {
    decrypt,
    checkIfKYCverified,
    CheckIfUserLoggedIn,
    getTommorowSDate,
    openLinkInNewTab,
    saveDataToLocal,
    getDataFromLocal,
    saveDataToSession,
    getDataFromSession
}

export default CommonUtil;