import axios from 'axios'
import ROOT_URL from './getRootUrl';
import Auth from '@aws-amplify/auth';
import CommonUtil from '../shared/CommonUtil';
// import { sendErrorNotification } from '../services/commonServices';
import userAuth from './authActions';

const HTTP_STATUS_CODES = {
    '200': 'OK',
    '201': 'Created',
    '202': 'Accepted',
    '203': 'Non-Authoritative Information',
    '204': 'No Content',
    '205': 'Reset Content',
    '206': 'Partial Content',
    '300': 'Multiple Choices',
    '301': 'Moved Permanently',
    '302': 'Found',
    '303': 'See Other',
    '304': 'Not Modified',
    '305': 'Use Proxy',
    '307': 'Temporary Redirect',
    '400': 'Bad Request',
    '401': 'Unauthorized',
    '402': 'Payment Required',
    '403': 'Forbidden',
    '404': 'Not Found',
    '405': 'Method Not Allowed',
    '406': 'Not Acceptable',
    '407': 'Proxy Authentication Required',
    '408': 'Request Timeout',
    '409': 'Conflict',
    '410': 'Gone',
    '411': 'Length Required',
    '412': 'Precondition Failed',
    '413': 'Request Entity Too Large',
    '414': 'Request-URI Too Long',
    '415': 'Unsupported Media Type',
    '416': 'Requested Range Not Satisfiable',
    '417': 'Expectation Failed',
    '500': 'Internal Server Error',
    '501': 'Not Implemented',
    '502': 'Bad Gateway',
    '503': 'Service Unavailable',
    '504': 'Gateway Timeout',
    '505': 'HTTP Version Not Supported'
};

const client = axios.create({
    baseURL: ROOT_URL.getRootUrl(),
    headers: { 'Authorization': 'Bearer ' + userAuth.getToken() }
});


const request = function (options, ifUsesOtherBaseUrl) {

    const errorHandlingForApiResponse = async function (response) {

        var status = response.data.status;
        if (!status && !response.data.error) {
            response.data.status = 'success';
            status = 'success'
        }


        if (status === 401) {
            Auth.signOut()
                .then(data => {
                    userAuth.resetAppOnLogout();
                    return;
                })
                .catch(err => console.log(err));
            window.localStorage.setItem('bijakGlobalToken', null);
            window.localStorage.setItem('pepProviderName',
                window.location.href
                    .replace("https://", "")
                    .replace("http://", "")
                    .replace(window.location.hostname + "/", "")
                    .split("/")[0] === 'localhost:3000' ? window.location.href
                        .replace("https://", "")
                        .replace("http://", "")
                        .replace(window.location.hostname + "/", "")
                        .split("/")[1] : window.location.href
                            .replace("https://", "")
                            .replace("http://", "")
                            .replace(window.location.hostname + "/", "")
                            .split("/")[0]);

            //   if(window.sessionStorage.getItem('pepProviderName') !== null && window.sessionStorage.getItem('pepProviderName') !== "null"  && window.sessionStorage.getItem('pepProviderName') !== ""){
            setTimeout(function () {
                userAuth.switchPathTo("login");
            }, 10);
            //   }
        }

        if (status === 404) {
            // await sendErrorNotification({
            //     "Subject": "PEPV2 Error in API status 404",
            //     "message": "Error in Api Response " + JSON.stringify(response) +
            //         "\n User - " + window.sessionStorage.getItem('name')
            // });

            return onError(response);
        }

        if (response.headers['content-type'] === 'text/csv') {
            if (response.status === 200) {
                return {
                    type: 'success', title: HTTP_STATUS_CODES[response.status],
                    message: 'Success', data: response.data
                }
            } else {

                // await sendErrorNotification({
                //     "Subject": "PEPV2 Error in API",
                //     "message": "Error in Api Response " + JSON.stringify(response) +
                //         "\n User - " + window.sessionStorage.getItem('name')
                // });

                return {
                    type: 'error', title: 'Error',
                    message: 'Request Failed', data: null
                }
            }
        }

        if (status === 'failed') {
            return onError(response);
        } else if (status === 'success') {
            return onSuccess(response);
        } else if (status === 1) {
            return onSuccess(response);
        } else {
            return {
                type: 'error', title: 'Error',
                message: 'Request Failed', data: null
            }
        }
    }
    const onSuccess = function (response) {
        // return {
        //     type: response.data.status, title: HTTP_STATUS_CODES[response.data.statusCode],
        //     message: (response.data.result && response.data.result.message) ?
        //         response.data.result.message : 'Success',
        //     data: response.data.result ? response.data.result : response.data["data"],
        //     extraMsg: response.data.message
        // }
        if (response["data"].hasOwnProperty("iscomp") && response["data"]["iscomp"]) {
            return {
                type: response.data.status, title: HTTP_STATUS_CODES[response.data.statusCode],
                message: (response.data.result && response.data.result.message) ?
                    response.data.result.message : 'Success',
                data: response.data.result ? JSON.parse(CommonUtil.decrypt(response.data.result)) : JSON.parse(CommonUtil.decrypt(response.data["data"])),
                extraMsg: response.data.message
            }
        } else {
            return {
                type: response.data.status, title: HTTP_STATUS_CODES[response.data.statusCode],
                message: (response.data.result && response.data.result.message) ?
                    response.data.result.message : 'Success',
                data: response.data.result ? response.data.result : response.data["data"],
                extraMsg: response.data.message
            }
        }
    }

    const onError = async function (response) {
        if (response.data.status === 404) {
            if (response.data.redirectServerName && response.data.redirectServerName.server && response.data.redirectServerName.labname) {
                window.localStorage.setItem('pepProviderName', response.data.redirectServerName.server);
                window.localStorage.setItem('clientname', response.data.redirectServerName.labname);
                let urlArray = window.location.href.split('/');
                setTimeout(function () { window.location = window.location.origin + '/' + response.data.redirectServerName.server + '/' + urlArray[urlArray.length - 1]; }, 100);
                return;
            } else {
                setTimeout(function () { window.location = window.location.origin + '/404' }, 100);
                return;
            }
        } else {

            // await sendErrorNotification({
            //     "Subject": "PEPV2 Error in API",
            //     "message": "Error in Api Response " + JSON.stringify(response) +
            //         "\n User - " + window.sessionStorage.getItem('name')
            // });

            return {
                type: 'error', title: typeof response.data.error.message === 'object' ? 'Error' : HTTP_STATUS_CODES[response.data.statusCode],
                code: response.data.statusCode,
                message: typeof response.data.error.message === 'object' ? "Some error occured, please try again" : response.data.error.message, data: null
            }
        }


    }
    const onErrorOfAuthentication = function (error) {
        try {
            var errorStatus = error.response.data.statusCode;
            var errorMsg = error.response.data.message || "Oops there is an error.";
            if (parseInt(errorStatus, 10) === 401) {
                Auth.signOut()
                    .then(data => {
                        userAuth.resetAppOnLogout();
                        return;
                    })
                    .catch(err => console.log(err));
                window.sessionStorage.setItem('bijakGlobalToken', null);
                window.location.href = window.location.origin + "/" + sessionStorage.getItem("pepProviderName") + "/login";
            }
            return { message: errorMsg };
        } catch (er) {
            console.log(er)
            return { message: "Oops an error occured" };
        }
    }



    return client(options)
        .then(errorHandlingForApiResponse)
        .catch(onErrorOfAuthentication);



}

export default request;
