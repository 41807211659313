// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

// AWS Mobile Hub Project Constants  
// const awsmobile = {
//         // 'aws_app_analytics': 'disable',
//         'aws_cognito_identity_pool_id': 'ap-south-1:0e93e920-2373-4554-b006-42d3db6dbb10',
//         'aws_cognito_region': 'ap-south-1',
//         // // 'aws_mobile_analytics_app_id': '2cdad0b12d854e4599d8b8fa8f53c784',
//         // // 'aws_mobile_analytics_app_region': 'us-east-1',
//         'aws_project_id': '693g386btnc0c4l31ef1970nua',
//         'aws_project_name': 'test-email-sms',
//         'aws_project_region': 'ap-south-1',
//         // 'aws_resource_name_prefix': 'thbadmininternal-mobilehub-1816394749',
//         'aws_sign_in_enabled': 'enable',
//         'aws_user_pools': 'enable',
//         'aws_user_pools_id': 'ap-south-1_XdeOb1t0d',
//         'aws_user_pools_web_client_id': '693g386btnc0c4l31ef1970nua',
//         "oauth": {
//                 "domain": "demotestemail.auth.ap-south-1.amazoncognito.com",
//                 "scope": [
//                         'email',
//                         'openid',
//                         //         'aws.cognito.signin.user.admin'
//                 ],
//                 redirectSignIn: "http://localhost:3001/",
//                 redirectSignOut: "http://localhost:3001/",
//                 // redirect_uri:'http://localhost:3001',
//                 "responseType": "token"
//         }

// }

const awsmobile = {
        // 'aws_app_analytics': 'disable',
        'aws_cognito_identity_pool_id': 'ap-south-1:cf68d427-2e77-4a9b-8ca0-c556e8585fc4',
        // 'aws_cognito_identity_pool_id': 'ap-south-1:366e15b7-4dca-4a9c-bbb2-f780a8d7daa9', // new 
        'aws_cognito_region': 'ap-south-1',
        // // 'aws_mobile_analytics_app_id': '2cdad0b12d854e4599d8b8fa8f53c784',
        // // 'aws_mobile_analytics_app_region': 'us-east-1',
        'aws_project_id': '1ldnok71s1bcqm6q9bagh7n6vd',
        'aws_project_name': 'bijakglobalCI',
        'aws_project_region': 'ap-south-1',
        // 'aws_resource_name_prefix': 'thbadmininternal-mobilehub-1816394749',
        'aws_sign_in_enabled': 'enable',
        'aws_user_pools': 'enable',
        'aws_user_pools_id': 'ap-south-1_OrR7yPfjk',
        'aws_user_pools_web_client_id': '1ldnok71s1bcqm6q9bagh7n6vd',
        'aws_user_files': 'enable',
        'aws_user_files_s3_bucket': 'bijakglobal-userfiles-dev',
        'aws_user_files_s3_bucket_region': 'ap-south-1',
        // "oauth": {
        //         "domain": "demotestemail.auth.ap-south-1.amazoncognito.com",
        //         "scope": [
        //                 'email',
        //                 'openid',
        //                 //         'aws.cognito.signin.user.admin'
        //         ],
        //         redirectSignIn: "http://localhost:3001/",
        //         redirectSignOut: "http://localhost:3001/",
        //         // redirect_uri:'http://localhost:3001',
        //         "responseType": "token"
        // }

}

export default awsmobile;
