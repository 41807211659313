import { getEnviornmentType } from "../config/appConfig";
const envType = getEnviornmentType();
function getRootUrl(req) {
  // eg. http://localhost:3000/athena-layer/extension/add-enquiry
  // var hostName = window.location.hostname;  // hostName.indexOf('localhost') !== -1 || 
  const ROOT_URL = envType === "prod" ?
    `https://a8ghvsxr7l.execute-api.ap-south-1.amazonaws.com/prod/` :
    `https://j66tutk7s4.execute-api.ap-south-1.amazonaws.com/dev/`;
  return ROOT_URL;
}


let ROOT_URL = {
  getRootUrl
}

export default ROOT_URL;
