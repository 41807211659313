import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from "@material-ui/core/styles/withStyles";
import '../signup/signup.css';
import securitylock from '../../assests/icons/securitylock.svg';
import verified_icn from '../../assests/icons/verified_icn.svg';
import vphone from '../../assests/icons/vphone.png';
import infoicon from '../../assests/icons/infoicon.svg';
import uploadbox from '../../assests/icons/uploadbox.svg';
import { getMyProfile, updateMyProfile, updateMyKYCDetails, addKYCDetails, uplaodFileToServer } from '../../services/commonService';
import { Auth } from 'aws-amplify';
import Loader from '../common/Loader';
import $ from 'jquery';
import { FileDrop } from 'react-file-drop';
import PopUpView from '../common/PopUpView';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import errorIcon from '../../assests/icons/error_icon.svg';
import mobile_upload from '../../assests/icons/mobile_upload.svg';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CommonUtil from '../../shared/CommonUtil';
import Snackbar from '@material-ui/core/Snackbar';
import successIcon from '../../assests/icons/ok_icon.svg';

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    label: {
        background: "white"
    },
    snackbarRoot: {
        backgroundColor :"#fff"
      }
});

class MyProfileContainer extends Component {

    dropRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            profileData: {},
            isOtpSent: false,
            attributeVerificationCode: '',
            errorFields: {},
            attachmentArray: [],
            isKycOpen: false,
            fileTobeUploaded: {
                pan: false,
                iec_code: false
            },
            registerAsSellerDetails: {
                iec_code: '',
                pan: '',
                panError: false,
                iec_codeError: false,
                certificate_images: { data: [] }
            },
            retryValue: 0,
            userid: '',
            loading: false,
            OtpErrorMsgs: '',
            isImageLoading: false,
            time: {},
            seconds: 120,
            fileSizeError: {
                "showErrorcertificate_images": false,
                "showErrorMSgcertificate_images": ""
            },
            openModal: false,
            cognitoPhone_numberDetail: {
                isMobileAdded: false,
                isVerified: false
            },
            mobileViewOtp: false,
            activeOpenContainer: {
                myprofile: true,
                registerasSeller: false,
                registerasBuyer: false

            },
            showSnackbar: false,
        };
        this.timer = 0;
        // this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    };

    async componentDidMount() {
        this.setState({ showLoader: true }, () => {
            this.getId();
        });

        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({ time: timeLeftVar });
    }

    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }

    startTimer() {
        if (this.timer == 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (seconds == 0) {
            clearInterval(this.timer);
        }
    }


    activateExpanCloseIcon() {
        // try {
        //     $('.myprofile').click(function () {
        //         $(this).find('i').toggleClass('fa fa-minus fa fa-plus');
        //     });

        //     $('.registerasSeller').click(function () {
        //         $(this).find('i').toggleClass('fa fa-plus fa fa-minus');
        //     });

        //     $('.registerasBuyer').click(function () {
        //         $(this).find('i').toggleClass('fa fa-plus fa fa-minus');
        //     });
        // } catch (err) {
        //     console.log(err);
        // }
    }

    handleDrop = (e) => {
        console.log(e);
    }


    handelRegisterAsSellerOpen(e) {
        try {
            e.preventDefault();
            this.setState({ isKycOpen: !this.state.isKycOpen })
        } catch (e) {

        }
    }

    async getId() {
        await Auth.currentAuthenticatedUser().then((user) => {
            console.log(user)
            if (user.attributes && user.attributes["custom:id"]) {
                let cognitoPhone_numberDetailVal = this.state.cognitoPhone_numberDetail;
                if (user.attributes.hasOwnProperty("phone_number") &&
                    user.attributes.hasOwnProperty("phone_number_verified")) {
                    cognitoPhone_numberDetailVal["isMobileAdded"] = user.attributes["phone_number"] && user.attributes["phone_number"] !== "" ? true : false;
                    if (user.attributes["phone_number_verified"]) {
                        cognitoPhone_numberDetailVal["isVerified"] = user.attributes["phone_number_verified"];
                    }
                    // cognitoPhone_numberDetail

                }
                this.setState({ userid: user.attributes["custom:id"], cognitoPhone_numberDetail: cognitoPhone_numberDetailVal }, () => {
                    this.getMyProfileData(user.attributes["custom:id"]);
                })
            } else {
                if (this.state.retryValue < 2) {
                    setTimeout(() => {
                        this.setState({ retryValue: 2 }, () => {
                            this.getId();
                        });
                    }, 3000);
                }

            }

        });
    }

    async getMyProfileData(userid) {
        try {
            let resp = await getMyProfile(userid);
            console.log(resp);
            let data = [{}];
            let registerAsSellerDetailsVal = this.state.registerAsSellerDetails;
            let activeView = this.state.activeOpenContainer;
            if (resp && resp.type === 1 && resp.data.length > 0) {
                data = resp.data;
                registerAsSellerDetailsVal["iec_code"] = data[0]["iec_code"];
                registerAsSellerDetailsVal["pan"] = data[0]["pan"];
                registerAsSellerDetailsVal["certificate_images"] = !data[0]["certificate_images"] || typeof (data[0]["certificate_images"]) === 'string' ? {data:[]} : data[0]["certificate_images"];
                activeView["registerasSeller"] = data[0]["kyc_status_seller"] !== 'nodata';

            }

            this.setState({
                profileData: data[0],
                activeOpenContainer: activeView,
                registerAsSellerDetails: registerAsSellerDetailsVal,
                showLoader: false
            }, () => {
                sessionStorage.setItem("profileData", JSON.stringify(data[0]));
                this.activateExpanCloseIcon();
            });
        } catch (err) {
            console.log(err);
        }
    }

    handelInputChange(event) {
        event.preventDefault();
        try {
            let err = this.state.errorFields;
            let id = event.target.id;
            let val = event.target.value;
            if (val === '' || !isNaN(val) && val.length < 7) {
                console.log(id)

                err[id] = false;


                this.setState({
                    [id]: val,
                    errorFields: err,
                    OtpErrorMsgs: ''
                })
            } else {
                return;
            }

        } catch (err) {
            console.log(err);
        }
    }

    handelInputRegisterAsSellerChange(event) {
        event.preventDefault();
        try {
            let registerAsSellerDetailsVal = this.state.registerAsSellerDetails;
            // let profileDataVal = this.state.profileData;
            // let err = this.state.errorFields;
            let id = event.target.id;
            let val = event.target.value;

            if (id === "pan" && val) {
                val = val.toUpperCase()
            }
            // profileDataVal[id] = val;
            registerAsSellerDetailsVal[id] = val;
            registerAsSellerDetailsVal[id + 'Error'] = false;
            registerAsSellerDetailsVal[id + 'PatternError'] = false;
            this.setState({
                registerAsSellerDetails: registerAsSellerDetailsVal
                // , profileData: profileDataVal
            });

        } catch (err) {
            console.log(err);
        }
    }

    async sendOtpToVerify(e) {
        e.preventDefault();
        this.setState({ loading: true });
        try {
            let phoneNumber = '+' + this.state.profileData["mob_country_code"] + '' + this.state.profileData["mobile"];
            const user = await Auth.currentAuthenticatedUser();
            const result = await Auth.updateUserAttributes(user, {
                'phone_number': phoneNumber
            });
            console.log(result);
            this.setState({ isOtpSent: true, loading: false, seconds: 120 }, () => {
                clearInterval(this.timer);
                this.timer = 0;
                this.startTimer();
            })
        } catch (err) {
            console.log(err);
        }
    }

    async verifyUserToResend(e) {
        e.preventDefault();
        this.setState({ loading: true });
        try {
            let phoneNumber = '+' + this.state.profileData["mob_country_code"] + '' + this.state.profileData["mobile"];
            const user = await Auth.currentAuthenticatedUser();
            // const result = await Auth.updateUserAttributes(user, {
            //     'phone_number': phoneNumber
            // });
            Auth.verifyUserAttribute(user, 'phone_number').then((result) => {
                // should go inside here and send the verification code
                console.log(result);
                this.setState({ isOtpSent: true, loading: false, seconds: 120 }, () => {
                    clearInterval(this.timer);
                    this.timer = 0;
                    this.startTimer();
                })
            }).catch((error) => {
                this.setState({ OtpErrorMsgs: error.message });
                console.error(error) // hitting error here 
            })

        } catch (err) {
            console.log(err);
        }
    }

    async verifyOtpSent(e) {
        e.preventDefault();
        try {
            this.setState({ loading: true, OtpErrorMsgs: '' });
            await Auth.verifyCurrentUserAttributeSubmit('phone_number', this.state.attributeVerificationCode)
                .then(() => {
                    console.log(this.state.attributeType + ' verified');
                    this.setState({ isOtpSent: false, loading: false, mobileViewOtp: false, attributeVerificationCode: '' }, () => {
                        this.updateStatusInAPi({ 'mobile_verified': true });
                    })

                }).catch(err => {
                    console.log('failed with error', err);
                    if (err.message.indexOf('Invalid code provided') > -1) {
                        this.setState({ OtpErrorMsgs: "Invalid OTP" });
                    } else {
                        this.setState({ OtpErrorMsgs: "Invalid OTP" });
                    }

                });
            this.setState({ loading: false });
        } catch (err) {
            console.log(err);
        }
    }

    async updateStatusInAPi(data) {
        try {
            // "name":"SANCHIT"
            // let data = { 'mobile_verified': true };
            let resp = await updateMyProfile(this.state.profileData["id"], data);
            console.log(resp);
            if (resp && resp.type === 1) {

            } else {

            }
            this.setState({ showLoader: true }, () => {
                this.getMyProfileData(this.state.userid);
            })

        } catch (err) {
            console.log(err);
        }
    }

    async updateSellerKYC(registerAsSellerDetailsVal) {
        // e.preventDefault();
        try {


            let payload = {
                'iec_code': registerAsSellerDetailsVal['iec_code'],
                pan: registerAsSellerDetailsVal['pan'],
                // "gstn": null,
                "certificate_images": registerAsSellerDetailsVal["certificate_images"]
            };
            let resp = await updateMyKYCDetails(this.state.profileData["id"], payload);
            console.log(resp);
            if (resp && resp.type === 1) {

            } else {

            }
            this.setState({ showLoader: true, loading: false }, () => {
                this.getMyProfileData(this.state.userid);
            })

        } catch (error) {
            console.log(error)
        }
    }

    async addKycDetail(registerAsSellerDetailsVal) {
        console.log(registerAsSellerDetailsVal)
        
        try {
            let payload = {
                'iec_code': registerAsSellerDetailsVal['iec_code'],
                pan: registerAsSellerDetailsVal['pan'],
                // "gstn": null,
                "certificate_images": registerAsSellerDetailsVal["certificate_images"]
            };
            console.log(payload)
            
            let resp = await addKYCDetails(this.state.profileData["id"], payload);
            // let resp = {};
            console.log(resp);
            if (resp && resp.type === 1) {

            } else {

            }
            // this.setState({ showLoader: true, loading: false, openModal: true }, () => {
            //     // this.getMyProfileData(this.state.userid);
            // })
            this.setState({ showLoader: true, loading: false, showSnackBar: true }, () => {
                setTimeout(() => {
                    this.setState({ showSnackBar: false }, () => {
                        this.getMyProfileData(this.state.userid);
                    })
                }, 2000);

            })

        } catch (error) {
            console.log(error)
        }
    }

    checkAndUpdateKYCdetails(e) {
        try {
            e.preventDefault();
            
            var panregex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
            var iecRegex = /^([a-zA-Z0-9]){10,10}$/;
            let isvalid = true;
            let registerAsSellerDetailsVal = this.state.registerAsSellerDetails;
            console.log(registerAsSellerDetailsVal)
            if (!registerAsSellerDetailsVal['iec_code'] || registerAsSellerDetailsVal['iec_code'].trim() === "") {
                registerAsSellerDetailsVal["iec_codeError"] = true;
                isvalid = false;
            } else if (!iecRegex.test(registerAsSellerDetailsVal['iec_code'])) {
                isvalid = false;
                registerAsSellerDetailsVal["iec_codePatternError"] = true;
            }


            if (!registerAsSellerDetailsVal['pan'] || registerAsSellerDetailsVal['pan'].trim() === "") {
                registerAsSellerDetailsVal["panError"] = true;
                isvalid = false;
            } else if (!panregex.test(registerAsSellerDetailsVal['pan'])) {
                registerAsSellerDetailsVal["panPatternError"] = true;
                isvalid = false;
            }

            if (!registerAsSellerDetailsVal['certificate_images'] || !registerAsSellerDetailsVal['certificate_images']["data"] ||
                registerAsSellerDetailsVal['certificate_images']["data"].length === 0) {
                registerAsSellerDetailsVal["certificate_imagesError"] = true;
                isvalid = false;
            }

            console.log(registerAsSellerDetailsVal)
            if (!isvalid) {
                this.setState({ registerAsSellerDetails: registerAsSellerDetailsVal, loading: false });
                return;
            }
            // if (this.state.profileData.hasOwnProperty("pan") && this.state.profileData['pan']) {
            //     this.updateSellerKYC(registerAsSellerDetailsVal);
            // } else {
                let upData = Object.assign({}, registerAsSellerDetailsVal)
            this.addKycDetail(upData);
            // }
        } catch (error) {
            console.log(error);
        }
    }

    imageDragDropChangedHandler = (certificateType, files) => {
        console.log(certificateType)
        console.log(files)

        try {
            let fiNme = files[0] && files[0].name ? files[0].name : '';
            let cFtype = fiNme.slice((fiNme.lastIndexOf(".") - 1 >>> 0) + 2)
            if (['jpg', 'png', 'pdf', 'jpeg'].indexOf(cFtype) > -1) {


                this.state.image = files[0];
                this.state.registerAsSellerDetails[certificateType + "Error"] = false;
                let image = '';
                this.setState(this.state)
                let fileSize = files[0].size;
                fileSize = Math.round((fileSize / 1024) / 1024); // mb
                console.log(fileSize)
                if (fileSize < 5) {
                    this.formatImageDataToBase64(files[0], (result) => {
                        image = result;
                        // console.log(image);
                        let data = {
                            data: image,
                            type: this.state.image.name.slice((this.state.image.name.lastIndexOf(".") - 1 >>> 0) + 2)
                        }
                        this.setState({ isImageLoading: true }, () => {
                            this.uploadImageData(this.state.userid, data, fiNme, certificateType);
                        });
                    });
                } else {
                    // file size exceeds 
                    let fileSizeErrorVal = this.state.fileSizeError;
                    fileSizeErrorVal["showErrorMSg" + certificateType] = "File size is more than 5 MB";
                    fileSizeErrorVal["showError" + certificateType] = true;
                    this.setState({
                        fileSizeError: fileSizeErrorVal
                    });
                }
            } else {
                alert("Filetype jpg, jpeg, png and pdf are allowed only")
            }
        } catch (err) {
            console.log(err)
        }
    }

    imageChangedHandler = (certificateType, event) => {
        try {
            let fiNme = event.target.files[0] && event.target.files[0].name ? event.target.files[0].name : '';
            let cFtype = fiNme.slice((fiNme.lastIndexOf(".") - 1 >>> 0) + 2)
            if (['jpg', 'png', 'pdf', 'jpeg'].indexOf(cFtype) > -1) {
                let fileSize = event.target.files[0].size;
                fileSize = Math.round((fileSize / 1024) / 1024); // mb
                console.log(fileSize)
                if (fileSize < 5) {
                    this.state.image = event.target.files[0];
                    this.state.registerAsSellerDetails["certificate_imagesError"] = false;
                    let image = '';
                    this.setState(this.state)
                    this.formatImageDataToBase64(event.target.files[0], (result) => {
                        image = result;
                        // console.log(image);
                        let data = {
                            data: image,
                            type: this.state.image.name.slice((this.state.image.name.lastIndexOf(".") - 1 >>> 0) + 2)
                        }
                        event.target.value = '';
                        this.setState({ isImageLoading: true }, () => {
                            this.uploadImageData(this.state.userid, data, fiNme, certificateType);
                        });
                    });
                } else {
                    // file size exceeds 
                    let fileSizeErrorVal = this.state.fileSizeError;
                    fileSizeErrorVal["showErrorMSg" + certificateType] = "File size is more than 5 MB";
                    fileSizeErrorVal["showError" + certificateType] = true;
                    this.setState({
                        fileSizeError: fileSizeErrorVal
                    });
                }

            } else {
                alert("Filetype jpg, jpeg, png and pdf are allowed only")
            }
        } catch (err) {
            console.log(err)
        }
    }

    async uploadImageData(id, data, fiNme, certificateType) {
        try {
            this.setState({
                fileSizeError: {
                    "showErrorcertificate_images": false,
                    "showErrorMSgcertificate_images": ""
                }
            })
            let resp = await uplaodFileToServer(id, data);
            console.log(resp);
            if (resp && resp.type === 1) {
                let registerAsSellerDetailVAl = this.state.registerAsSellerDetails;
                let profiData = this.state.profileData;
                // registerAsSellerDetailVAl[certificateType].push({ url: resp.data, fname: fiNme });
  
                if (!profiData[certificateType] || typeof (profiData[certificateType]) === 'string') {
                    profiData[certificateType] = {};
                    profiData[certificateType]['data'] = [];
                    profiData[certificateType]['data'].push({ url: resp.data, fname: fiNme });
                    // profiData[certificateType].push({ url: resp.data, fname: fiNme });
                } else {
                    profiData[certificateType]['data'].push({ url: resp.data, fname: fiNme });
                }
                registerAsSellerDetailVAl[certificateType]["data"] =  profiData[certificateType]['data'];
                // this.updateStatusInAPi({ type: resp.data });
                console.log(registerAsSellerDetailVAl[certificateType]["data"]);
                console.log(profiData[certificateType]['data'])
                this.setState({ profileData: profiData ,registerAsSellerDetails: registerAsSellerDetailVAl},()=>{
                    // if (!registerAsSellerDetailVAl[certificateType] || !registerAsSellerDetailVAl[certificateType]["data"] || typeof (registerAsSellerDetailVAl[certificateType]) === 'string') {
                    //     registerAsSellerDetailVAl[certificateType] = { data: [] };
                    //     registerAsSellerDetailVAl[certificateType]["data"].push({ url: resp.data, fname: fiNme });
                    //     // profiData[certificateType].push({ url: resp.data, fname: fiNme });
                    // } else {
                    //     registerAsSellerDetailVAl[certificateType]["data"].push({ url: resp.data, fname: fiNme });
                    // }
                    // this.setState({ registerAsSellerDetails: registerAsSellerDetailVAl});
                })
            }
            this.setState({ isImageLoading: false });
        } catch (error) {
            console.log(error);
            this.setState({ isImageLoading: false })
        }
    }

    formatImageDataToBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    onClickToggleContainer(event, id) {
        console.log(id)
        event.preventDefault();
        event.stopPropagation();
        let activeOpenContainerVal = this.state.activeOpenContainer;
        activeOpenContainerVal[id] = !activeOpenContainerVal[id];
        this.setState({ activeOpenContainer: activeOpenContainerVal });
        // activeOpenContainer: {
        //     myprofile: true,
        //     registerasSeller: false,
        //     registerasBuyer: false

        // }

    }

    onDocImgDeleted = (event, indexOfdoc, obj, docId) => {
        try {
            event.preventDefault();
            console.log(indexOfdoc, obj, docId);
            let profileDataVal = this.state.profileData;
            // let registerAsSellerDetailVAl = this.state.registerAsSellerDetails;

            // registerAsSellerDetailVAl[docId]["data"].splice(indexOfdoc, 1);
            profileDataVal[docId]["data"].splice(indexOfdoc, 1);

            console.log(profileDataVal)
            // console.log(registerAsSellerDetailVAl)
            this.setState({
                profileData: profileDataVal,
                //  registerAsSellerDetails: registerAsSellerDetailVAl 
            }, () => {
                let registerAsSellerDetailVAl = this.state.registerAsSellerDetails;
                registerAsSellerDetailVAl[docId]["data"] = profileDataVal[docId]["data"];
                this.setState({ registerAsSellerDetails: registerAsSellerDetailVAl })
            })
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        const { classes } = this.props;
        const { profileData, isOtpSent, attributeVerificationCode, registerAsSellerDetails,
            errorFields, showLoader, loading, isImageLoading, fileSizeError, cognitoPhone_numberDetail,
            mobileViewOtp, activeOpenContainer } = this.state;
        // profileData["kyc_status_seller"] ="nodata"
        return (<>
            {!showLoader ?
                <section className="sec_bg my_account_section">
                    {!mobileViewOtp ? <div className="container">
                        <div className="accordion cus_sec_width tb_padding cus_mb_120_sm" id="accordionData">
                            <div>
                                <div id="headingOne" className="cursor-pointer" onClick={(e) => this.onClickToggleContainer(e, "myprofile")} >
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h5 className="font-weight-bold cus_black mb-0">My Accounts</h5>
                                        <a href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.props.history.push(`/changepassword`);
                                            }}
                                            className="text-uppercase d-inline-block chng_pass_text d575_none myprofile_changePassword_txt">
                                            <img src={securitylock} className="mr-2" alt="" /> Change password</a>
                                        <button type="button"
                                            className="btn btn-default myprofile text-uppercase chng_pass_text cus_bg_transparent d_lg_none_sm"
                                            onClick={(e) => this.onClickToggleContainer(e, "myprofile")}>
                                            <i className={"cus_purple " + (activeOpenContainer["myprofile"] ? " fa fa-minus-circle" : "fa fa-plus-circle")} style={{ fontSize: "20px" }}></i>
                                        </button>
                                    </div>
                                </div>
                                <div id="collapseOne" className={"collapse " + (this.state.activeOpenContainer["myprofile"] ? "show" : "")} aria-labelledby="headingOne" data-parent="#accordionData">
                                    <hr />
                                    <div className="pt-2" style={{ paddingBottom: "50px" }}>
                                        <div className="row mb-4">
                                            <label className="col-sm-4 myProfile_title">Your Name</label>
                                            <span className="col-sm-8 font-weight-bold myProfile_title_value">{profileData.name ? profileData.name : ''}</span>
                                        </div>
                                        <div className="row mb-4">
                                            <label className="col-sm-4 myProfile_title">Name of your organization</label>
                                            <span className="col-sm-8 font-weight-bold myProfile_title_value">{profileData.organization ? profileData.organization : ''}</span>
                                        </div>
                                        <div className="row mb-4">
                                            <label className="col-sm-4 mb-0 myProfile_title">Email ID (This will be your login ID)</label>
                                            <span className="col-lg-3 col-sm-5 font-weight-bold myProfile_title_value" style={{ width: '65%' }}>{profileData.email ? profileData.email : ''}</span>

                                            <span className="col-sm-3 d575_none">
                                                <a href="#" className="text-uppercase vrify_mail_text"
                                                    style={{ cursor: !profileData["email_verified"] ? "pointer" : "default" }}
                                                    onClick={(e) => { e.preventDefault(); }}>
                                                    <img src={verified_icn}
                                                        className="mr-1 d-inline-block" alt="" />
                                                </a>
                                            </span>

                                            {/* <!-- For sm device (width<576) --> */}
                                            <span className="col-sm-3 d_lg_none_sm w-25" >
                                                <a href="#" style={{ cursor: !profileData["email_verified"] ? "pointer" : "default" }}
                                                    className="text-uppercase vrify_mail_text">
                                                    <img src={verified_icn} className="mr-1 d-inline-block" alt="" /></a>
                                            </span>
                                            {/* <!-- For sm device --> */}


                                        </div>
                                        <div className="row mb-4">
                                            <label className="col-sm-4 mb-0 myProfile_title">Mobile Number</label>
                                            <span style={{ width: !profileData["mobile_verified"] ? '' : '65%' }} className={"col-sm-3 font-weight-bold myProfile_title_value "}>+{profileData.mob_country_code ? profileData.mob_country_code : ''}{profileData.mobile ? profileData.mobile : ''}</span>
                                            {profileData && profileData.hasOwnProperty("mobile_verified") &&
                                                <span className="col-sm-3 d575_none" >
                                                    <a href="#" className="text-uppercase vrify_mail_text" style={{ cursor: !profileData["mobile_verified"] ? "pointer" : "default" }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            if (!profileData["mobile_verified"] && !loading && !isOtpSent) {
                                                                if (cognitoPhone_numberDetail["isMobileAdded"]) {
                                                                    this.verifyUserToResend(e);
                                                                } else {
                                                                    this.sendOtpToVerify(e);
                                                                }
                                                            }
                                                        }}>
                                                        <img src={profileData["mobile_verified"] ? verified_icn : vphone} className="mr-1" alt="" />
                                                    </a>
                                                </span>}

                                            {/* mobile view phone */}
                                            {profileData && profileData.hasOwnProperty("mobile_verified") &&
                                                <span className="col-sm-3 d_lg_none_sm w-25" >
                                                    <a href="#" style={{ cursor: !profileData["mobile_verified"] ? "pointer" : "default" }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            if (!profileData["mobile_verified"] && !loading && !isOtpSent) {
                                                                this.setState({ mobileViewOtp: true }, () => {
                                                                    if (cognitoPhone_numberDetail["isMobileAdded"]) {
                                                                        this.verifyUserToResend(e);
                                                                    } else {
                                                                        this.sendOtpToVerify(e);
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                        className="text-uppercase vrify_mail_text">
                                                        <img src={profileData["mobile_verified"] ? verified_icn : vphone} className="mr-1 d-inline-block" alt="" /></a>
                                                </span>}

                                            {/* <!-- hiding on sm device (width<576) --> */}
                                            {isOtpSent &&
                                                <div className="offset-sm-4 col-sm-6 mt-5 email_verify d575_none" style={{ padding: "0px 60px" }} id="emailVerify">
                                                    <div className="d-flex align-items-start">
                                                        <img src={infoicon} className="mt-1 mr-3" alt="" />
                                                        <p className="cus_light_green"> We have sent OTP on your mobile number please
                                            enter your OTP to verify</p>
                                                    </div>
                                                    <form action="">
                                                        <FormControl variant="outlined" style={{ width: '100%', marginTop: '2rem' }}>
                                                            <InputLabel htmlFor="outlined-adornment-password" style={{ color: errorFields["attributeVerificationCode"] || this.state.OtpErrorMsgs !== "" ? "#f44336" : "unset" }}>Enter OTP</InputLabel>
                                                            <OutlinedInput
                                                                id="attributeVerificationCode"
                                                                name="attributeVerificationCode"
                                                                type={"text"}
                                                                style={{ background: "#fff" }}
                                                                fullWidth
                                                                minLength={0}
                                                                error={errorFields["attributeVerificationCode"] || this.state.OtpErrorMsgs !== ""}
                                                                maxLength={6}
                                                                value={attributeVerificationCode}
                                                                onChange={(event) => this.handelInputChange(event)}
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        {/* {1 ? <Visibility /> : <VisibilityOff />} */}
                                                                        {(errorFields["attributeVerificationCode"] || this.state.OtpErrorMsgs !== "") && <img src={errorIcon} alt="" />}
                                                                    </InputAdornment>
                                                                }
                                                                labelWidth={70}
                                                            />
                                                        </FormControl>
                                                        {errorFields["attributeVerificationCode"] && <div className="myProfile_error_text">This details cannot be empty</div>}
                                                        <div style={{ display: !errorFields["attributeVerificationCode"] && this.state.OtpErrorMsgs !== "" ? 'block' : 'none' }} className="myProfile_error_text"> {this.state.OtpErrorMsgs}</div>

                                                        <div className="d-flex align-items-center justify-content-between mt-5">
                                                            {this.state.seconds && this.state.seconds !== 0 ?
                                                                <p className="mb-0 myProfile_otp_valid_txt">OTP is valid for <span>{parseInt(this.state.seconds / 60) + ":" + this.state.seconds % 60} min</span></p> :
                                                                <p className="mb-0 myProfile_otp_valid_txt"><span className="myprofile_resendotp" onClick={(event) => {
                                                                    event.preventDefault();
                                                                    this.verifyUserToResend(event);
                                                                }}>Resend OTP</span></p>}
                                                            <input type="button" className="btn btn-light" value="Verify" style={{ background: this.state.attributeVerificationCode && this.state.attributeVerificationCode.length > 5 ? "#198548" : "#A0A4A8" }}
                                                                onClick={(event) => {
                                                                    event.preventDefault();
                                                                    if (this.state.attributeVerificationCode && this.state.attributeVerificationCode.length > 5 && !loading) {
                                                                        this.verifyOtpSent(event)
                                                                    }
                                                                }} />
                                                        </div>
                                                    </form>
                                                </div>}

                                        </div>
                                        <a href="#" className="text-uppercase d-inline-block chng_pass_text d_lg_none_sm mb-3"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.props.history.push(`/changepassword`);
                                            }}
                                        >
                                            <img src={securitylock} className="mr-2" alt="" /> Change password</a>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div>
                                <div id="headingTwo" className="cursor-pointer" onClick={(e) => this.onClickToggleContainer(e, "registerasSeller")} >
                                    <div className="d-flex align-items-center justify-content-between custm_col_text">
                                        <h5 className="font-weight-bold cus_black mb-0 myProfile_register_as_text">Register As a Seller</h5>
                                        {profileData["kyc_status_seller"] && profileData["kyc_status_seller"] === 'nodata' ?
                                            <button type="button" className="btn btn-outline-success d575_none myProfile_register_as_btn" style={{ background: "#fff" }}>{profileData["kyc_status_seller"] && profileData["kyc_status_seller"] === 'nodata' ? "Register AS A Seller NOW" : "Registration " + profileData["kyc_status_seller"]}
                                            </button> : <div className="text-uppercase d-inline-block chng_pass_text d575_none myprofile_changePassword_txt"> {"Registration " + profileData["kyc_status_seller"]}</div>}


                                        <button type="button"
                                            className="btn btn-default registerasSeller cus_bg_transparent text-uppercase d_lg_none_sm"
                                            onClick={(e) => this.onClickToggleContainer(e, "registerasSeller")}>
                                            <i className={"cus_purple " + (activeOpenContainer["registerasSeller"] ? " fa fa-minus-circle" : "fa fa-plus-circle")} style={{ fontSize: "20px" }}></i>
                                        </button>
                                    </div>
                                </div>
                                <div id="collapseTwo" className={"collapse " + (this.state.activeOpenContainer["registerasSeller"] ? "show" : "")} aria-labelledby="headingTwo" data-parent="#accordionData">
                                    <div className="row pt-4 mt-4">
                                        <div className="col-md-6 mb-3 mb-md-0">
                                            <h6 className="myprofile_asSeller_title_txt">Company PAN number</h6>
                                            <input
                                                id="pan"
                                                name="pan"
                                                type="text"
                                                disabled={profileData["kyc_status_seller"] && profileData["kyc_status_seller"] !== 'nodata'}
                                                style={{ width: "100%" }}
                                                value={registerAsSellerDetails.pan || ''}
                                                onChange={(event) => this.handelInputRegisterAsSellerChange(event)}
                                                className="form-control otp_input"
                                                placeholder="Enter your pan card number" />
                                            {registerAsSellerDetails["panError"] && <div className="inputFrameinputError_txt">This details cannot be empty</div>}
                                            {registerAsSellerDetails["panPatternError"] && <div className="inputFrameinputError_txt">Please enter a valid pan number</div>}
                                        </div>
                                        <div className="col-md-6">
                                            <h6 className="myprofile_asSeller_title_txt">Import-Export Code number</h6>
                                            <input
                                                id="iec_code"
                                                name="iec_code"
                                                type="text"
                                                style={{ width: "100%" }}
                                                disabled={profileData["kyc_status_seller"] && profileData["kyc_status_seller"] !== 'nodata'}
                                                value={registerAsSellerDetails.iec_code || ''}
                                                onChange={(event) => this.handelInputRegisterAsSellerChange(event)}
                                                className="form-control otp_input"
                                                placeholder="Enter your import-export code number" />
                                            {registerAsSellerDetails["iec_codeError"] && <div className="inputFrameinputError_txt">This details cannot be empty</div>}
                                            {registerAsSellerDetails["iec_codePatternError"] && <div className="inputFrameinputError_txt">Please enter a valid 10-digit Import-Export Code</div>}
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <h6 className="myprofile_asSeller_title_txt">Upload the following documents</h6>
                                            <div className="row text-center">
                                                <div className="col-md-12 mb-3 mb-md-0">

                                                    {profileData["certificate_images"] && profileData["certificate_images"]["data"] && profileData["certificate_images"]["data"].length > 0 &&
                                                        profileData["certificate_images"]["data"].map((obj, reIndex) =>

                                                            <span key={reIndex + "w"} className="col-sm-6 font-weight-bold key_value mb-3 mt-2 d_lg_none_sm" style={{ padding: "0px" }}>

                                                                {obj.fname.indexOf(".pdf") > -1 ?
                                                                    <div key={reIndex + "--"} style={{ position: "relative" }}>
                                                                        <img className="delete_upload_btn"
                                                                            style={{ display: profileData.kyc_status_seller === 'nodata' ? "block" : "none" }}
                                                                            src="http://cdn1.iconfinder.com/data/icons/diagona/icon/16/101.png"
                                                                            onClick={(e) => { this.onDocImgDeleted(e, reIndex, obj, "certificate_images") }} />
                                                                        <div className="card flex-row align-items-center px-2 py-3 mt-2 w-100"
                                                                            onClick={() => CommonUtil.openLinkInNewTab(obj.url)}
                                                                            style={{ margin: "0 auto", cursor: 'pointer' }}>
                                                                            <div className="col-2 pl-0">
                                                                                <img src="/static/media/pdf_icon.f3935add.png" width="35" alt=" " />
                                                                            </div>
                                                                            <div className="col-10 pr-5">
                                                                                <p className="mb-0">{obj.fname} </p>
                                                                            </div>
                                                                        </div>
                                                                    </div> :
                                                                    <div key={reIndex + "--"} style={{ position: "relative" }}>
                                                                        <img
                                                                            style={{ display: profileData.kyc_status_seller === 'nodata' ? "block" : "none" }}
                                                                            className="delete_upload_btn" src="http://cdn1.iconfinder.com/data/icons/diagona/icon/16/101.png"
                                                                            onClick={(e) => { this.onDocImgDeleted(e, reIndex, obj, "certificate_images") }} />
                                                                        <img src={obj.url} alt=""
                                                                            onClick={() => CommonUtil.openLinkInNewTab(obj.url)}
                                                                            className="mt-2 w-100" style={{ cursor: 'pointer' }} />
                                                                    </div>
                                                                }
                                                            </span>
                                                        )
                                                    }
                                                    {/* Mobile view */}
                                                    {profileData["certificate_images"] && profileData["certificate_images"]["data"] && profileData["certificate_images"]["data"].length > 0 &&
                                                        profileData["certificate_images"]["data"].map((obj, reIndex) =>
                                                            <span key={reIndex + "w"} className="col-sm-6 font-weight-bold key_value mb-3 mt-2 d575_none" style={{ padding: "0px" }}>

                                                                {obj.fname.indexOf(".pdf") > -1 ?
                                                                    <div key={reIndex + "--"} style={{ position: "relative" }}>
                                                                        <img alt=""
                                                                            style={{ display: profileData.kyc_status_seller === 'nodata' ? "unset" : "none", left: "25%" }}
                                                                            className="delete_upload_btn" src="http://cdn1.iconfinder.com/data/icons/diagona/icon/16/101.png"
                                                                            onClick={(e) => { this.onDocImgDeleted(e, reIndex, obj, "certificate_images") }} />
                                                                        <div className="card flex-row align-items-center px-2 py-3 mt-2"
                                                                            onClick={() => CommonUtil.openLinkInNewTab(obj.url)}
                                                                            style={{ width: "50%", margin: "0 auto", cursor: 'pointer' }}>
                                                                            <div className="col-2 pl-0">
                                                                                <img src="/static/media/pdf_icon.f3935add.png" width="35" alt=" " />
                                                                            </div>
                                                                            <div className="col-10 pr-5">
                                                                                <p className="mb-0">{obj.fname} </p>
                                                                            </div>
                                                                        </div>
                                                                    </div> :
                                                                    <div key={reIndex + "--"} style={{ position: "relative" }}>
                                                                        <img alt=""
                                                                            style={{ display: profileData.kyc_status_seller === 'nodata' ? "unset" : "none" }}
                                                                            className="delete_upload_btn" src="http://cdn1.iconfinder.com/data/icons/diagona/icon/16/101.png"
                                                                            onClick={(e) => { this.onDocImgDeleted(e, reIndex, obj, "certificate_images") }} />
                                                                        <img src={obj.url} alt=""
                                                                            onClick={() => CommonUtil.openLinkInNewTab(obj.url)}
                                                                            className="mt-2" style={{ cursor: 'pointer', width: '50%' }} />
                                                                    </div>}
                                                            </span>
                                                        )
                                                    }


                                                    {profileData.kyc_status_seller === 'nodata' &&
                                                        <FileDrop
                                                            onFrameDragEnter={(event) => { }}
                                                            onFrameDragLeave={(event) => { }}
                                                            onFrameDrop={(event) => { }}
                                                            onDragOver={(event) => { }}
                                                            onDragLeave={(event) => { }}
                                                            onDrop={(files, event) => {
                                                                if (profileData["kyc_status_seller"] === 'nodata') {
                                                                    this.imageDragDropChangedHandler('certificate_images', files)
                                                                }
                                                            }}
                                                        >
                                                            <span className="col-sm-6 font-weight-bold key_value mt-3" style={{ padding: "0px" }}>
                                                                <span>
                                                                    <input type="file" id="pan_image"
                                                                        disabled={isImageLoading || profileData["kyc_status_seller"] !== 'nodata'}
                                                                        onChange={this.imageChangedHandler.bind(this, 'certificate_images')}
                                                                        style={{ display: "none" }} />
                                                                    <label htmlFor='pan_image' className="p-0">
                                                                        {isImageLoading ? <div className="myProfile_image_loader"><Loader display="contents" primaryText="Uploading.. please wait" /></div> :
                                                                            <div>
                                                                                <img src={uploadbox} alt="" className="d575_none" style={{ cursor: 'pointer', width: '100%' }} />
                                                                                <img src={mobile_upload} alt="" className="d_lg_none_sm" style={{ cursor: 'pointer', width: '100%' }} />
                                                                            </div>
                                                                        }
                                                                    </label>
                                                                </span>
                                                                {registerAsSellerDetails["certificate_imagesError"] && <div className="inputFrameinputError_txt">Please upload the import export code certificate</div>}
                                                                {fileSizeError["showErrorcertificate_images"] && <div className="error_text">{fileSizeError["showErrorMSgcertificate_images"]} </div>}
                                                            </span>
                                                        </FileDrop>}
                                                </div>
                                                <div className="col-md-6 mb-3">

                                                </div>
                                            </div>
                                        </div>

                                        < div className="col-12 text-right mt-4 d575_none">

                                            <button className="btn btn-success px-3 text-uppercase cus_bg_green"
                                                disabled={profileData.kyc_status_seller !== 'nodata'}
                                                onClick={(e) => {
                                                    if (!loading) {
                                                        if (profileData.kyc_status_seller === 'nodata') {
                                                            this.checkAndUpdateKYCdetails(e);
                                                        }

                                                    }
                                                }}>Submit now</button>
                                            {profileData.kyc_status_seller === 'pending' && <div className="error_msg">Your kyc is pending</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div>
                                <div id="headingThree" className="cursor-pointer" onClick={(e) => this.onClickToggleContainer(e, "registerasBuyer")}>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h5 className="font-weight-bold cus_black mb-0 myProfile_register_as_text">Register As a Buyer</h5>
                                        <div className="custm_col_text">
                                            <button type="button" className="btn btn-outline-success d575_none myProfile_register_as_btn"
                                                onClick={() => this.updateStatusInAPi({ 'kyc_status_buyer': "accepted" })}
                                                disabled={profileData["kyc_status_buyer"] === 'accepted'}
                                                style={{ background: "#fff" }}>
                                                {profileData["kyc_status_buyer"] === "nodata" ? 'Register AS A Buyer NOW' : profileData["kyc_status_buyer"]}
                                            </button>
                                            <button type="button"
                                                className="btn btn-default registerasBuyer cus_bg_transparent text-uppercase d_lg_none_sm"
                                                onClick={(e) => this.onClickToggleContainer(e, "registerasBuyer")}>
                                                <i className={"cus_purple " + (activeOpenContainer["registerasBuyer"] ? " fa fa-minus-circle" : "fa fa-plus-circle")} style={{ fontSize: "20px" }}></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div id="collapseThree" className={"collapse " + (this.state.activeOpenContainer["registerasBuyer"] ? "show" : "")} aria-labelledby="headingThree"
                                    data-parent="#accordionData">
                                    <button type="button" className="btn btn-outline-success d_lg_none_sm myProfile_register_as_btn ml-5 w-75 mt-4"
                                        onClick={() => this.updateStatusInAPi({ 'kyc_status_buyer': "accepted" })}
                                        disabled={profileData["kyc_status_buyer"] === 'accepted'}
                                        style={{ background: "#fff" }}>
                                        {profileData["kyc_status_buyer"] === "nodata" ? 'Register AS A Buyer NOW' : profileData["kyc_status_buyer"]}
                                    </button>
                                    {/* <div className="">
                                    <p>CSS stands for Cascading Style Sheet. CSS allows you to specify various style properties
                                for a given HTML element such as colors, backgrounds, fonts etc. <a
                                            href="https://www.tutorialrepublic.com/css-tutorial/" target="_blank">Learn
                                    more.</a></p>
                                </div> */}
                                </div>
                                <hr />
                                {activeOpenContainer["registerasSeller"] &&
                                    <div className="col-12 text-right mt-5 d_lg_none_sm fixed-bottom py-3 bg-white">
                                        {profileData.kyc_status_seller === 'pending' && <div className="error_msg text-center pb-2">Your kyc is pending</div>}
                                        <button className="btn btn-secondary px-4 text-uppercase cus_bg_green w-100 py-3"
                                            disabled={profileData.kyc_status_seller !== 'nodata'}
                                            onClick={(e) => {
                                                if (!loading) {
                                                    if (profileData.kyc_status_seller === 'nodata') {
                                                        this.checkAndUpdateKYCdetails(e);
                                                    }

                                                }
                                            }}
                                        >Submit Now</button>
                                    </div>}
                            </div>
                        </div>
                    </div> :
                        <div className="container tb_padding">
                            <div className="d-block d-sm-none mb-4">
                                <a href="#" className="cus_purple closeBtn serach_goBack_txt"
                                    onClick={() => this.setState({ mobileViewOtp: false, isOtpSent: false })}>
                                    <i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;
                                    Go back
                                    </a>
                            </div>
                            <div className="myProfile_resendMail">Verify Mobile Number</div>
                            <form className="row cus_sec_width needs-validation cus_mb_120_sm mt-4" >
                                <div className="col-12 email_verify" id="emailVerify">
                                    <div className="d-flex align-items-start">
                                        {/* <img src="img/icons/green_alert.png" className="mt-1 mr-3" alt="" /> */}
                                        <img src={infoicon} className="mt-1 mr-3" alt="" />
                                        <p className="cus_light_green">  We have sent OTP on your mobile number please
                                            enter your OTP to verify</p>
                                    </div>
                                    <div className="mt-3">
                                        <FormControl variant="outlined" style={{ width: '100%', marginTop: '2rem' }}>
                                            <InputLabel htmlFor="attributeVerificationCode" style={{ color: errorFields["attributeVerificationCode"] || this.state.OtpErrorMsgs !== "" ? "#f44336" : "unset", background: "#fff" }}>
                                                Enter OTP to verify</InputLabel>
                                            <OutlinedInput
                                                id="attributeVerificationCode"
                                                name="attributeVerificationCode"
                                                type={"text"}
                                                style={{ background: "#fff" }}
                                                fullWidth
                                                minLength={0}
                                                error={errorFields["attributeVerificationCode"] || this.state.OtpErrorMsgs !== ""}
                                                maxLength={6}
                                                value={attributeVerificationCode}
                                                onChange={(event) => this.handelInputChange(event)}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        {/* {1 ? <Visibility /> : <VisibilityOff />} */}
                                                        {(errorFields["attributeVerificationCode"] || this.state.OtpErrorMsgs !== "") && <img src={errorIcon} alt="" />}
                                                    </InputAdornment>
                                                }
                                                labelWidth={70}
                                            />
                                        </FormControl>
                                        {errorFields["attributeVerificationCode"] && <div className="myProfile_error_text">This details cannot be empty</div>}
                                        <div style={{ display: !errorFields["attributeVerificationCode"] && this.state.OtpErrorMsgs !== "" ? 'block' : 'none' }} className="myProfile_error_text"> {this.state.OtpErrorMsgs}</div>

                                        <div className="d-flex align-items-center justify-content-between mt-5">
                                            {this.state.seconds && this.state.seconds !== 0 ?
                                                <p className="mb-0 myProfile_otp_valid_txt">OTP is valid for <span>{parseInt(this.state.seconds / 60) + ":" + this.state.seconds % 60} min</span></p> :
                                                <p className="mb-0 myProfile_otp_valid_txt">
                                                    <span className="myprofile_resendotp"
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            this.verifyUserToResend(event);
                                                        }}>Resend OTP</span></p>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 text-right mt-5 d_lg_none_sm fixed-bottom py-4 bg-white">
                                    <button className="btn btn-secondary px-4 text-uppercase w-100 py-3"
                                        disabled={this.state.attributeVerificationCode && this.state.attributeVerificationCode.length < 5}
                                        style={{ background: this.state.attributeVerificationCode && this.state.attributeVerificationCode.length > 5 ? "#198548" : "#A0A4A8" }}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            if (this.state.attributeVerificationCode && this.state.attributeVerificationCode.length > 5 && !loading) { this.verifyOtpSent(event) }
                                        }}
                                    >verify OTP
                                    </button>
                                </div>
                            </form>
                        </div>}

                </section> : <Loader />
            }
            {/* <PopUpView modalText="Request for seller registration recieved. Bijak team will evaluate and get back to you."
                open={this.state.openModal}
                popUpType={"success"}
                onPopUpViewClose={() => this.getMyProfileData(this.state.userid)} /> */}
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={this.state.showSnackBar}
                style={{ backgroundColor: "#fff", top: "25%" }}
                ContentProps={{
                    classes: {
                        root: classes.snackbarRoot
                    }
                }}
                onClose={() => this.setState({ showSnackBar: false })}
                message={
                    <div className="d-flex">
                        <div className="col-md-1 ml-auto">
                            <img src={successIcon} alt="" />
                        </div>
                        <div className="col-11 ml-auto modal_text">
                            Request for seller registration recieved. Bijak team will evaluate and get back to you.
            </div>
                    </div>
                }
                key={"top" + "center"}
            />
        </>
        );
    }
}

export default withStyles(styles)(withRouter(MyProfileContainer))