import React, { Component } from 'react';
// import SideNavigationPanel from '../layouts/sideNavigation/SideNavigationPanel';
import Header from '../layouts/Header/Header';
import "./mainpanel.css";
import { Route } from "react-router-dom";
import appRoutes from './RouteMappings';
// import Box from '@material-ui/core/Box';
import { Auth } from 'aws-amplify';


// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center" style={{
//       bottom: "7px",
//       width: "100%",
//       position: "absolute"
//     }}>
//       {'Copyright © '}
//       <Link color="inherit" href="#">
//         BijakGlobal
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

export default class MainPanel extends Component {

  state = {}

  constructor(props) {
    super(props);
    this.state = {
      showSideNav: false,
      loggedInUserName: "",
      isLoggedIn: false
    }
  }

  componentWillMount() {
    Auth.currentAuthenticatedUser().then((user) => {
      // alert(user.attributes.name );
      this.setState({ loggedInUserName: user.attributes.name, isLoggedIn: true });
    }).catch(err => {
      // alert(JSON.stringify(err) );
      this.setState({ isLoggedIn: false });
    })

  }

  render() {
    const { showSideNav, loggedInUserName, isLoggedIn } = this.state;
    return (
      <>

        {/* <SideNavigationPanel className={showSideNav ? "" : "margin"}
          showSideNav={showSideNav}
          onToggleBtnClicked={() => this.setState({ showSideNav: !showSideNav })}
          activePath={this.props.location["pathname"]} /> */}

        <Header
          showSideNav={showSideNav}
          loggedInUserName={loggedInUserName}
          isLoggedIn={isLoggedIn}
          onToggleBtnClicked={() => this.setState({ showSideNav: !showSideNav })} />

        {appRoutes.routeMappings.map((val, index) =>
          <Route key={val.sideNaveName + "_" + index} path={val.path} exact component={val.componentName} />
        )}

        {/* {appRoutes.redirectUrl.map((obj, inde) =>
          <Route key={obj.redirectPath + "__" + inde} path={obj.redirectPath} exact component={obj.componentName} />
        )} */}

        {/* <Box mt={8}> */}
        {/* <Copyright /> */}
        {/* </Box> */}
      </>
    )
  }
}
