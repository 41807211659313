import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from '@material-ui/core/TextField';
import pdf_icon from '../../assests/img/icons/pdf_icon.png';
import Tooltip from '@material-ui/core/Tooltip';
import info_icon from '../../assests/icons/info_icon.svg';
import Grid from '@material-ui/core/Grid';
import CommonUtil from '../../shared/CommonUtil';
import { Auth } from 'aws-amplify';

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    buttoncss: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "18px",
        lineHeight: "24px",
        letterSpacing: "1.25px",
        textTransform: "uppercase",
        color: "#FFFFFF"
    },
    customInput: {
        width: "100%",
        background: "#fff"
    },
    tooltipCls: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.4px",
        color: "rgba(255, 255, 255, 0.88)",
        flex: "none",
        order: 1,
        flexGrow: 0,
        margin: "0px 8px"
    }
});

class ProductDetailsContainer extends Component {

    state = {};
    constructor(props) {
        super(props);
        this.state = {
            errorFields: {},
            selectedCommodity: {},
            postCommodityPayload: {},
            showPostEnquiryModal: false,
            commodityDetails: {},
            buyerQuery: {
                "sell_id": "",
                "buyer_id": "",
                buyer_quantity: "",
                buyer_unit: "",
                buyer_shipment: "",
                buyer_payment_type: "",
                buyer_advance_percentage: '',//this.state.postCommodityPayload[""],
                buyer_comments: '',
                // offer_status: "",
                "buyer_price_unit": "",
                buyer_price: "",
                buyer_currency: "",
            },
            showTooltip: false
        }
    }

    componentDidMount() {
        if (this.props.location.state && this.props.location.state.detail && this.props.location.state.detail.hasOwnProperty("name")) {
            this.setState({ postCommodityPayload: this.props.location.state.detail });
        } else {
            this.checkSession();
        }
    }

    async checkSession() {
        await Auth.currentAuthenticatedUser().then((user) => {
            this.props.history.push(`/home`);
        }).catch((err) => {
            this.props.history.push(`/`);
        });
    }


    checkIfFormIsValid() {
        try {
            let isValid = true;
            let errorFieldsVal = {};
            let ignoreKeyCheck = ['buyer_comments'];
            for (var key in this.state.buyerQuery) {
                if (!this.state.buyerQuery[key] || this.state.buyerQuery[key] === "") {
                    if (key === "buyer_advance_percentage") {
                        if (this.state.buyerQuery["buyer_payment_type"] === "Advance payment") {
                            isValid = false;
                            errorFieldsVal[key] = true;
                        }

                    } else if (ignoreKeyCheck.indexOf(key) > -1) {

                    } else {
                        isValid = false;
                        errorFieldsVal[key] = true;
                    }
                }
            }

            this.setState({ errorFields: errorFieldsVal });
            console.log(errorFieldsVal)
            return isValid;
        } catch (err) {
            console.log(err);
        }
    }

    handleInputChange(event) {
        try {
            var floatIds = []; // this values need to be float
            var errors = this.state.errorFields;
            let buyerQueryVal = this.state.buyerQuery;
            let id = event.target.id;
            let val = event.target.value;

            if (!id && id === undefined) {
                id = event.target.name;
            }
            // alert(val)
            console.log(id)
            console.log(val)
            if (floatIds.indexOf(id) > -1) {
                // if (val === "" || !isNaN(val)) {
                if (val === "" || val.match(/^(\d+\.?\d{0,9}|\.\d{1,9})$/)) {
                    buyerQueryVal[id] = val;
                }
            } else {
                buyerQueryVal[id] = val;
            }

            if (id === "payment_type") {
                if (val !== "Advance payment") {
                    buyerQueryVal["advance_percentage"] = '';
                }
            }

            if (errors.hasOwnProperty(id)) {
                delete errors[id];
            }

            this.setState({
                buyerQuery: buyerQueryVal,
                errorFields: errors,
                showFormError: false
            })

        } catch (err) {
            console.log(err);
        }
    }

    render() {
        const { classes } = this.props;
        const { errorFields, postCommodityPayload } = this.state;
        console.log(postCommodityPayload)
        return (<>
            <section className="sec_bg my_account_section">
                <div className="container">
                    <div className="cus_sec_width tb_padding">
                        <div className="pt-2">
                            <div className="row mb-4">
                                <div className="col-sm-4 pb-4">
                                    <div className="comm_upload_title"> Commodity Docs </div>
                                    <div className="comm_img_upload_title pt-4 pb-1"> Commodity Image </div>
                                    {postCommodityPayload.commodity_images && postCommodityPayload.commodity_images["data"] &&
                                        postCommodityPayload.commodity_images["data"].length > 0 &&
                                        postCommodityPayload.commodity_images["data"].map((item, img_index) =>
                                            <img key={img_index + "--"} src={item["url"]} alt=""
                                                onClick={() => CommonUtil.openLinkInNewTab(item.url)}
                                                className="mt-2 w-100 cursor-pointer" style={{ maxWidth: '100%' }}
                                            />
                                        )
                                    }
                                    <div className="comm_img_upload_title pt-4 pb-2"> Documents </div>
                                    {postCommodityPayload.other_doc_images && postCommodityPayload.other_doc_images["data"] &&
                                        postCommodityPayload.other_doc_images["data"].length > 0 ?
                                        postCommodityPayload.other_doc_images["data"].map((item, img_indexw) => (
                                            item["fname"].indexOf(".pdf") > -1 ?
                                                <div className="card flex-row align-items-center px-2 py-3 mt-2 mb-4 cursor-pointer"
                                                    onClick={() => CommonUtil.openLinkInNewTab(item.url)}>
                                                    <div className="col-2 pl-0">
                                                        <img src={pdf_icon} width="35" alt=" " />
                                                    </div>
                                                    <div className="col-10">
                                                        <p className="mb-0">{item["fname"]}</p>
                                                    </div>
                                                </div> :
                                                <img key={img_indexw + "--"} src={item["url"]} alt="" onClick={() => CommonUtil.openLinkInNewTab(item.url)}
                                                    className="mt-2 w-100  mb-4  cursor-pointer" style={{ maxWidth: '100%' }} />)
                                        ) : <div className="no_document">&nbsp;No documents</div>
                                    }
                                </div>

                                <div className="col-sm-8 ">
                                    <div className="comm_upload_title cus_sec_width">Commodity Details</div>
                                    <form className="bootstrap-form needs-validation cus_sec_width pt-4" novalidate>

                                        <div>
                                            <TextField
                                                id="outlined-select-currency-native"
                                                // select
                                                label="Select Commodity"
                                                value={postCommodityPayload.name}
                                                fullWidth
                                                disabled
                                                InputProps={{
                                                    classes: {
                                                        root: classes.customInput,
                                                    },
                                                }}
                                                error={errorFields["commodity_id"] ? true : false}
                                                helperText={errorFields["commodity_id"] ? "This detail cannot be empty" : ''}
                                                variant="outlined"
                                            >
                                            </TextField>
                                        </div>
                                        <div className="mt-4">
                                            <TextField
                                                id="commodity_desc"
                                                label="Commodity Description"
                                                name="commodity_desc"
                                                error={errorFields["commodity_desc"] ? true : false}
                                                helperText={errorFields["commodity_desc"] ? "This detail cannot be empty" : ''}
                                                value={postCommodityPayload.commodity_desc}
                                                fullWidth
                                                disabled
                                                InputProps={{
                                                    classes: {
                                                        root: classes.customInput,
                                                    },
                                                }}
                                                variant="outlined"
                                            />

                                        </div>
                                        <div className="mt-4" style={{ display: "flex" }}>
                                            <TextField
                                                id="quantity"
                                                error={errorFields["quantity"] ? true : false}
                                                type="number"
                                                helperText={errorFields["quantity"] ? "This detail cannot be empty" : ''}
                                                label="Selling commodity volume per month"
                                                InputProps={{
                                                    classes: {
                                                        root: classes.customInput,
                                                    },

                                                    inputProps: { min: 0 }

                                                }}
                                                fullWidth
                                                disabled
                                                style={{ width: '60%' }}
                                                value={postCommodityPayload.quantity}
                                                onChange={this.handleInputChange.bind(this)}
                                                variant="outlined"
                                            /> &nbsp;&nbsp;
                                            <TextField
                                                id="quantity_unit"
                                                name="quantity_unit"
                                                label="Unit"
                                                error={errorFields["quantity_unit"] ? true : false}
                                                helperText={errorFields["quantity_unit"] ? "This detail cannot be empty" : ''}
                                                type="text"
                                                disabled
                                                InputProps={{
                                                    classes: {
                                                        root: classes.customInput,
                                                    },
                                                }}
                                                fullWidth
                                                value={postCommodityPayload.quantity_unit}
                                                style={{ width: '39%' }}
                                                variant="outlined"
                                            >
                                            </TextField>
                                        </div>
                                        <div className="mt-4">
                                            <TextField
                                                label="Location of Commodity"
                                                InputProps={{
                                                    classes: {
                                                        root: classes.customInput,
                                                    },
                                                }}
                                                fullWidth
                                                disabled
                                                variant="outlined"
                                                id="locality"
                                                name="locality"
                                                error={errorFields["locality"] ? true : false}
                                                helperText={errorFields["locality"] ? "This detail cannot be empty" : ''}
                                                type="text"
                                                value={postCommodityPayload.locality}
                                                onChange={this.handleInputChange.bind(this)}
                                            >
                                            </TextField>

                                        </div>
                                        <div className="mt-4">
                                            <TextField
                                                id="year_of_harvest"
                                                name="year_of_harvest"
                                                disabled
                                                label="Year of harvest"
                                                error={errorFields["year_of_harvest"] ? true : false}
                                                helperText={errorFields["year_of_harvest"] ? "This detail cannot be empty" : ''}
                                                style={{ width: '49%' }}

                                                InputProps={{
                                                    classes: {
                                                        root: classes.customInput,
                                                    },
                                                }}
                                                value={postCommodityPayload.year_of_harvest}
                                                onChange={this.handleInputChange.bind(this)}
                                                variant="outlined"
                                            >
                                            </TextField>
                                        </div>

                                        <div className="mt-4 postEnquiry_title">Enquiry Details</div>
                                        <div className="mt-3" style={{ display: "flex" }}>
                                            <TextField
                                                id="price"
                                                error={errorFields["price"] ? true : false}
                                                helperText={errorFields["price"] ? "This detail cannot be empty" : ''}
                                                name="price"
                                                label="Estimated FOB price"
                                                type="text"
                                                disabled
                                                value={postCommodityPayload.price + " " + postCommodityPayload.price_currency + " per " + postCommodityPayload.price_unit}
                                                InputProps={{
                                                    classes: {
                                                        root: classes.customInput,
                                                    },
                                                    inputProps: { min: 0 }
                                                }}
                                                fullWidth
                                                variant="outlined"
                                            />
                                        </div>

                                        <div className="mt-4 postEnquiry_title">Payment Preference&nbsp;&nbsp;
                                        <Tooltip
                                                classes={{ tooltip: classes.tooltipCls }}
                                                open={this.state.showTooltip}
                                                title="Please choose one of L/C or Advance Payment as your preferred payment mode. If Advance Payment is your preferred payment mode, mention the portion of total amount (%) that you would like to receive in advance." arrow>
                                                <img src={info_icon} alt=""
                                                    onMouseOver={() => this.setState({ showTooltip: !this.state.showTooltip })}
                                                    onMouseOut={() => this.setState({ showTooltip: !this.state.showTooltip })}
                                                    onClick={() => this.setState({ showTooltip: !this.state.showTooltip })} />
                                            </Tooltip>
                                        </div>

                                        <div className="free_search mt-4">
                                            <div className="radio_toolbar">
                                                <input type="radio" id="L/C" name="L/C" value={"L/C"}
                                                    // style={{ color: postCommodityPayload.payment_type === "L/C" ? "#25282B" : "#FFFFFF" }}
                                                    checked={postCommodityPayload.payment_type === "L/C"}
                                                />
                                                <label for="L/C" className="radio_ps"
                                                    style={{
                                                        background: postCommodityPayload.payment_type === "L/C" ? "#247BC7" : "#FFFFFF",
                                                        color: postCommodityPayload.payment_type !== "L/C" ? "#25282B" : "#FFFFFF"
                                                    }}
                                                >L/c</label>



                                                <input type="radio" id="Advance payment" name="Advance payment"
                                                    // style={{ color: postCommodityPayload.payment_type === "Advance payment" ? "#25282B" : "#FFFFFF" }}
                                                    value="Advance payment"
                                                    checked={postCommodityPayload.payment_type === "Advance payment"}
                                                />
                                                <label for="Advance payment" className="radio_ps"
                                                    style={{
                                                        marginLeft: "10px",
                                                        background: postCommodityPayload.payment_type === "Advance payment" ? "#247BC7" : "#FFFFFF",
                                                        color: postCommodityPayload.payment_type !== "Advance payment" ? "#25282B" : "#FFFFFF"
                                                    }} >Advance Payment</label>
                                            </div>
                                            {errorFields["payment_type"] ? <div className="inputFrameinputError_txt">Please select an option</div> : ''}
                                        </div>

                                        {postCommodityPayload.payment_type && postCommodityPayload.payment_type === "Advance payment" &&
                                            <div className="mt-4">
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        id="advance_percentage"
                                                        error={errorFields["advance_percentage"] ? true : false}
                                                        helperText={errorFields["advance_percentage"] ? "This detail cannot be empty" : ''}
                                                        name="price"
                                                        label="Advance payment percentage"
                                                        type="number"
                                                        disabled
                                                        value={postCommodityPayload.advance_percentage}
                                                        // onChange={this.handleInputChange.bind(this)}
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.customInput,
                                                            },
                                                            inputProps: { min: 0 }
                                                        }}
                                                        // style={{ width: '59%' }}
                                                        fullWidth
                                                        // helperText="Some important text"
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </div>
                                        }
                                        <div className="mt-4">
                                            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        disableToolbar
                                                        variant="outlined"
                                                        format="yyyy-MM-dd"
                                                        margin="normal"
                                                        id="offer_expiry_date"
                                                        name="offer_expiry_date"
                                                        error={errorFields["offer_expiry_date"] ? true : false}
                                                        helperText={errorFields["offer_expiry_date"] ? "This detail cannot be empty" : ''}
                                                        label="Date picker inline"
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.customInput,
                                                            },
                                                        }} value={postCommodityPayload["offer_expiry_date"]}
                                                        // onChange={(dateval) => this.handleDateInputChange(dateval)}
                                                        // onChange={handleDateChange}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider> */}
                                            <Grid item xs={12} sm={6}>

                                                <TextField
                                                    id="offer_expiry_date"
                                                    error={errorFields["offer_expiry_date"] ? true : false}
                                                    helperText={errorFields["offer_expiry_date"] ? "This detail cannot be empty" : ''}
                                                    name="price"
                                                    label="Offer expiry date"
                                                    type="text"
                                                    disabled
                                                    value={postCommodityPayload["offer_expiry_date"]}
                                                    // onChange={this.handleInputChange.bind(this)}
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.customInput,
                                                        },
                                                        inputProps: { min: 0 }
                                                    }}
                                                    fullWidth
                                                    // style={{ width: '59%' }}
                                                    // helperText="Some important text"
                                                    variant="outlined"
                                                />

                                            </Grid>
                                        </div>

                                        <div className="mt-4 postEnquiry_title">Commodity Parameters</div>
                                        <div className="mt-4">
                                            {postCommodityPayload["other_quality_details"] && postCommodityPayload["other_quality_details"]["data"] &&
                                                postCommodityPayload["other_quality_details"]["data"].length > 0 &&
                                                postCommodityPayload["other_quality_details"]["data"].map((item, indobj) =>
                                                    // <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        key={indobj + "__"}
                                                        id={item.name}
                                                        name={item.name}
                                                        disabled
                                                        error={errorFields[item.name] ? true : false}
                                                        label={item.name + " " + (item.unit ? item.unit : "")}
                                                        // style={{ marginLeft: indobj % 2 === 1 ? "2%" : '0%', marginTop: "10px" }}
                                                        style={{
                                                            width: "49%",
                                                            textTransform: "capitalize",
                                                            marginLeft: indobj % 2 === 1 ? "2%" : '0%', marginTop: "10px"
                                                        }}
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.customInput,
                                                            },
                                                        }}
                                                        // fullWidth
                                                        // helperText="Some important text"
                                                        value={item["value"]}
                                                        // onChange={this.handleQualityParamsInputChange.bind(this, item, indobj)}
                                                        variant="outlined"
                                                    />
                                                    //  </Grid>
                                                )}


                                        </div>


                                        <div className="mt-4">
                                            {<TextField
                                                id="other_comment"
                                                label="Any other remarks"
                                                InputProps={{
                                                    classes: {
                                                        root: classes.customInput,
                                                    },
                                                }}
                                                disabled
                                                fullWidth
                                                multiline
                                                rows={4}
                                                // helperText="Some important text"
                                                error={errorFields["other_comment"] ? true : false}
                                                type="text"

                                                value={postCommodityPayload.other_comment}
                                                // onChange={this.handleInputChange.bind(this)}
                                                variant="outlined"
                                            />}

                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
        );
    }
}

export default withStyles(styles)(withRouter(ProductDetailsContainer))