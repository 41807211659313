/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Loader from '../common/Loader';
import { makeCounterOffer } from '../../services/commonService';
import { postSellCommodity } from '../../services/postSellService';
import config from '../../api/config';

const styles = theme => ({
    heading: {
        fontSize: '21px',
        fontWeight: '500',
        marginTop: '0',
        marginBottom: '0',
        fontFamily: 'Montserrat, sans-serif',
    },
    dialogPaper: {
        minWidth: '700px',
        // maxWidth: theme.,
        minHeight: '400px',
        // maxHeight: '500px'
        [theme.breakpoints.down('sm')]: {
            minWidth: '94%',
        },
    },
    textInputFullWidth: {
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    formAddBtn: {
        width: '90%',
        borderRadius: '10px',
        fontSize: '20px',
        textTransform: 'uppercase',
        backgroundColor: '#4d9fa0 ',
        color: '#fff',
        height: '45px',
        marginBottom: '15px',
        marginTop: "11px",
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    formRoot: {
        // display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        // marginLeft: '25%',
        border: '1px solid #ccc',
        boxShadow: '2px 2px 6px 0px  rgba(0,0,0,0.3)',
        borderRadius: '4px',
        marginBottom: '20px',
        marginTop: '8%',
        padding: '25px',
        textAlign: 'center'
    },
    input: {
        display: 'none',
    },
    buttoncss: {
        borderRadius: "20px",
        paddingLeft: "20px",
        paddingRight: "20px",
        backgroundColor: "#32ad79",
        color: "#fff",
        border: "none",
        fontWeight: 400,
        marginTop: "10px",
        marginBottom: "5%",
        fontSize: "14px",
        fontFamily: 'Lato, sans-serif',
        textTransform: "uppercase",
        paddingTop: "5px",
        paddingBottom: "5px",
        fontStretch: "normal",
        fontStyle: "normal",
        marginRight: "10px"
    },

});
// const statusOption = ["pending", "settled", "partial_settled"];

const CustomOption = props => {
    const { data, innerRef, innerProps } = props;
    return data && data.is_primary ? (
        <div ref={innerRef} {...innerProps} className='custom_option'>
            <i className="fa fa-check" style={{ color: "#60c1d8" }} aria-hidden="true"></i> &nbsp; {data.label ? data.label : ""}
        </div>
    ) : (
            <div ref={innerRef} {...innerProps} className='custom_option' >
                {data.label ? data.label : ""}
            </div>

        );
};

class CounterOfferModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,

            buyerid: "",
            supplierid: "",
            brokerid: "",
            tempVar: {},
            errorFields: {},
            attachmentArray: [],
            commodityList: { options: [], optionN_E: {}, optionE_N: {} },
            showLoader: false,
            subId: "",

            showSweetAlert: false,
            sweetAlertData: {
                "type": "",
                "title": "",
                "text": ""
            },
            showFormError: false,

            tagsOption: [],
            orderTypeOptions: [],
            commodityListOptions: [],
            counterOfferPrice: {
                id: Number(this.props.selectedEnquery["id"]),
                seller_co_quantity: '',//number
                seller_co_unit: '',//string
                seller_co_shipment: '',//string
                seller_co_price: '',//number
                seller_co_currency: '',//string
                seller_co_price_unit: '',
                seller_co_payment_type: '',//string
                seller_co_advance_percentage: '',//number
                offer_status: 'counter offer'
                // seller_co_status: '',//string
            },
            selectedCommodity: '',
            selectedCommodityObj: {},
            loading: false

        }
        this.theme = undefined
        this.fullScreen = undefined;

    }

    handleDialogCancel(event) {
        this.props.onAddModalCancel();

    }


    handleInputChange(event) {
        try {
            var floatIds = ['seller_co_quantity', 'seller_co_price', 'seller_co_advance_percentage']; // this values need to be float
            var errors = this.state.errorFields;
            let counterOfferPriceVal = this.state.counterOfferPrice;
            let id = event.target.id;
            let val = event.target.value;
            if (!id && id === undefined) {
                id = event.target.name;
            }
            // alert(val)
            if (floatIds.indexOf(id) > -1) {
                // if (val === "" || !isNaN(val)) {
                if (val === "" || val.match(/^(\d+\.?\d{0,9}|\.\d{1,9})$/)) {
                    counterOfferPriceVal[id] = val === "" ? "" : Number(val);
                }
            } else {
                counterOfferPriceVal[id] = val;
            }

            console.log(id)
            console.log(val)
            console.log(counterOfferPriceVal)

            if (id === "payment_type") {
                if (val !== "Advance payment") {
                    counterOfferPriceVal["advance_percentage"] = '';
                }
            }

            if (id === "advance_percentage") {
                if (val !== '') {
                    if (val < 1 || val > 100) {
                        return;
                    }
                }
            }

            if (errors.hasOwnProperty(id)) {
                delete errors[id];
            }

            this.setState({
                counterOfferPrice: counterOfferPriceVal,
                errorFields: errors,
                showFormError: false
            })

        } catch (err) {
            console.log(err);
        }
    }

    // async postCommodityToPortal() {
    //     try {
    //         let resp = await postSellCommodity(this.state.counterOfferPrice);
    //         console.log(resp);

    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    handleInputRadioChange(event, value, id) {
        try {
            event.preventDefault();
            console.log(id);
            console.log(value);
            var floatIds = []; // this values need to be float
            var errors = this.state.errorFields;
            errors[id] = false;
            let counterOfferPriceVal = this.state.counterOfferPrice;
            counterOfferPriceVal[id] = value
            this.setState({ counterOfferPrice: counterOfferPriceVal })
        } catch (error) {
            console.log(error);
        }
    }

    async makeCounterOffer() {
        try {
            let payload =
            {
                id: 1,//number
                seller_co_quantity: 1,//number
                seller_co_unit: '',//string
                seller_co_shipment: '',//string
                seller_co_price: 1,//number
                seller_co_currency: '',//string
                seller_co_payment_type: '',//string
                seller_co_advance_percentage: 1,//number,
                offer_status: 'counter offer'
                // seller_co_status: '',//string
            }
            if (!this.checkIfFormValid()) {
                this.setState({ loading: false });
                return;
            }
            let resp = await makeCounterOffer(this.state.counterOfferPrice);
            console.log(resp);
            if (resp && resp.type === 1) {

            }
            this.setState({ loading: false }, () => {
                this.props.onEnquiryAdded()
            })

        } catch (err) {
            console.log(err);
        }
    }

    checkIfFormValid() {
        try {
            let isValid = true;
            let errors = {};
            let keysToIgnore = ["seller_co_shipment"];
            for (var keys in this.state.counterOfferPrice) {
                if (keysToIgnore.indexOf(keys) === -1) {
                    if (!this.state.counterOfferPrice[keys] || this.state.counterOfferPrice[keys] === '') {
                        if (keys === "seller_co_advance_percentage") {
                            if (this.state.counterOfferPrice["seller_co_payment_type"] === "Advance payment") {
                                isValid = false;
                                errors[keys] = true;
                            }
                        } else {
                            isValid = false;
                            errors[keys] = true;
                        }
                    }
                }
            }
            console.log(errors);
            this.setState({ errorFields: errors })
            return isValid;
        } catch (error) {
            console.group(error);
        }
    }


    render() {
        const { classes, selectedEnquery } = this.props;
        const { commodityListOptions, showLoader, counterOfferPrice, supplierid, buyerid,
            commodityList, tempVar, errorFields, showSweetAlert, sweetAlertData, showFormError,
            loading,
            selectedCommodity } = this.state;
        console.log(selectedEnquery)
        return (<div>
            <Grid container spacing={3}>
                <Grid item xs={12}>

                    <Dialog style={{ zIndex: '1300' }}
                        open={this.state.open}
                        classes={{ paper: classes.dialogPaper }}
                        className="counterOfferModal"
                        disableBackdropClick={true}
                        onClose={this.handleDialogCancel.bind(this)}
                        aria-labelledby="form-dialog-title"                >
                        {!showLoader ?
                            <div>
                                <DialogTitle
                                    style={{ background: '#198548', textAlign: 'center', height: '50px' }}
                                    id="form-dialog-title">
                                    <p style={{ color: '#FFF', marginTop: '-10px', fontFamily: 'Roboto', fontSize: '18px' }}>
                                        Make counter offer</p>
                                </DialogTitle>
                                <DialogContent>

                                    <div className="mt-4" >
                                        <TextField
                                            id="Commodity"
                                            type="text"
                                            disabled
                                            label="Commodity"
                                            InputProps={{
                                                classes: {
                                                    root: classes.customInput,
                                                },
                                                inputProps: { min: 0 }
                                            }}
                                            fullWidth

                                            value={selectedEnquery.name}
                                            onChange={this.handleInputChange.bind(this)}
                                            variant="outlined"
                                        />
                                    </div>
                                    <div className="mt-4" style={{ display: "flex" }}>
                                        <TextField
                                            id="seller_co_quantity"
                                            error={errorFields["seller_co_quantity"] ? true : false}
                                            helperText={errorFields["seller_co_quantity"] ? "This detail cannot be empty" : ''}
                                            type="number"
                                            label="Quantity requirement"
                                            InputProps={{
                                                classes: {
                                                    root: classes.customInput,
                                                },
                                                inputProps: { min: 0 }
                                            }}
                                            fullWidth
                                            style={{ width: '60%' }}
                                            value={counterOfferPrice.seller_co_quantity}
                                            onChange={this.handleInputChange.bind(this)}
                                            variant="outlined"
                                        /> &nbsp;&nbsp;
                                <TextField
                                            select
                                            id="seller_co_unit"
                                            name="seller_co_unit"
                                            label="Unit"
                                            error={errorFields["seller_co_unit"] ? true : false}
                                            helperText={errorFields["seller_co_unit"] ? "This detail cannot be empty" : ''}
                                            type="text"
                                            // label="Commodity Description"
                                            InputProps={{
                                                classes: {
                                                    root: classes.customInput,
                                                },
                                            }}
                                            fullWidth
                                            value={counterOfferPrice.seller_co_unit}
                                            onChange={this.handleInputChange.bind(this)}
                                            style={{ width: '39%' }}

                                            // helperText="Some important text"
                                            variant="outlined"
                                        >
                                            {["kg", "Quintal", "MT"].map((key, i) => (
                                                <MenuItem key={i} value={key} selected={true}>
                                                    {key}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>

                                    <div className="mt-3 d575_none" style={{ display: "flex" }}>
                                        <TextField
                                            id="seller_co_price"
                                            error={errorFields["seller_co_price"] ? true : false}
                                            helperText={errorFields["seller_co_price"] ? "This detail cannot be empty" : ''}
                                            name="seller_co_price"
                                            label="Quote your FOB price"
                                            type="number"

                                            value={counterOfferPrice.seller_co_price}
                                            onChange={this.handleInputChange.bind(this)}
                                            InputProps={{
                                                classes: {
                                                    root: classes.customInput,
                                                },
                                                inputProps: { min: 0 }
                                            }}
                                            style={{ width: '50%' }}
                                            // fullWidth
                                            // helperText="Some important text"
                                            variant="outlined"
                                        />
                                            &nbsp;
                                            &nbsp;
                                            <TextField
                                            select
                                            id="seller_co_currency"
                                            name="seller_co_currency"
                                            label="Currency"
                                            error={errorFields["seller_co_currency"] ? true : false}
                                            helperText={errorFields["seller_co_currency"] ? "This detail cannot be empty" : ''}
                                            type="text"
                                            style={{ width: '22%' }}
                                            value={counterOfferPrice.seller_co_currency}
                                            onChange={this.handleInputChange.bind(this)}

                                            InputProps={{
                                                classes: {
                                                    root: classes.customInput,
                                                },
                                            }}
                                            variant="outlined"
                                        >
                                            {["INR", "USD", "AED"].map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                         &nbsp;
                                         <div className="per_text d575_none ">
                                            Per
                                            </div>
                                            &nbsp;
                                            <TextField
                                            select
                                            id="seller_co_price_unit"
                                            name="seller_co_price_unit"
                                            label="unit"
                                            error={errorFields["seller_co_price_unit"] ? true : false}
                                            helperText={errorFields["seller_co_price_unit"] ? "This detail cannot be empty" : ''}
                                            type="text"
                                            style={{ width: '22%' }}
                                            value={counterOfferPrice.seller_co_price_unit}
                                            onChange={this.handleInputChange.bind(this)}

                                            InputProps={{
                                                classes: {
                                                    root: classes.customInput,
                                                },
                                            }}
                                            variant="outlined"
                                        >
                                            {["kg", "Quintal", "MT"].map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </TextField>

                                    </div>
                                    <div className="mt-3 d_lg_none_sm">
                                        <TextField
                                            id="seller_co_price"
                                            error={errorFields["seller_co_price"] ? true : false}
                                            helperText={errorFields["seller_co_price"] ? "This detail cannot be empty" : ''}
                                            name="seller_co_price"
                                            label="Quote your FOB price"
                                            type="number"

                                            value={counterOfferPrice.seller_co_price}
                                            onChange={this.handleInputChange.bind(this)}
                                            InputProps={{
                                                classes: {
                                                    root: classes.customInput,
                                                },
                                                inputProps: { min: 0 }
                                            }}
                                            // style={{ width: '50%' }}
                                            fullWidth
                                            // helperText="Some important text"
                                            variant="outlined"
                                        />
                                        <div className="free_search mt-1 ">
                                            <div className="radio_toolbar" id="seller_co_currency" style={{ margin: "0px" }}>
                                                <input type="radio" id="INR" name="INR" value={"INR"}
                                                    // style={{ color: postCommodityPayload.payment_type === "L/C" ? "#25282B" : "#FFFFFF" }}
                                                    checked={counterOfferPrice.seller_co_currency === "INR"}
                                                />
                                                <label htmlFor="INR" className="radio_ps"
                                                    style={{
                                                        background: counterOfferPrice.seller_co_currency === "INR" ? "#247BC7" : "#FFFFFF",
                                                        color: counterOfferPrice.seller_co_currency !== "INR" ? "#25282B" : "#FFFFFF"
                                                    }}
                                                    onClick={(ev) => this.handleInputRadioChange(ev, "INR", "seller_co_currency")} >INR</label>

                                                <input type="radio" id="USD" name="USD"
                                                    // style={{ color: counterOfferPrice.payment_type === "Advance payment" ? "#25282B" : "#FFFFFF" }}
                                                    value="USD"
                                                    checked={counterOfferPrice.seller_co_currency === "USD"}
                                                />
                                                <label htmlFor="USD" className="radio_ps"
                                                    style={{
                                                        marginLeft: "10px",
                                                        background: counterOfferPrice.seller_co_currency === "USD" ? "#247BC7" : "#FFFFFF",
                                                        color: counterOfferPrice.seller_co_currency !== "USD" ? "#25282B" : "#FFFFFF"
                                                    }} onClick={(ev) => this.handleInputRadioChange(ev, "USD", "seller_co_currency")}>USD</label>

                                                <input type="radio" id="AED" name="AED"
                                                    // style={{ color: counterOfferPrice.payment_type === "Advance payment" ? "#25282B" : "#FFFFFF" }}
                                                    value="AED"
                                                    checked={counterOfferPrice.seller_co_currency === "AED"}
                                                />
                                                <label htmlFor="AED" className="radio_ps"
                                                    style={{
                                                        marginLeft: "10px",
                                                        background: counterOfferPrice.seller_co_currency === "AED" ? "#247BC7" : "#FFFFFF",
                                                        color: counterOfferPrice.seller_co_currency !== "AED" ? "#25282B" : "#FFFFFF"
                                                    }} onClick={(ev) => this.handleInputRadioChange(ev, "AED", "seller_co_currency")}>AED</label>
                                            </div>
                                            {errorFields["seller_co_currency"] ? <div className="inputFrameinputError_txt">Please select an option</div> : ''}
                                        </div>

                                        <div className="per_text text-center d_lg_none_sm" style={{ lineHeight: 2 }}>Per</div>

                                        <div className="free_search mt-1 d_lg_none_sm">
                                            <div className="radio_toolbar" id="seller_co_price_unit" style={{ margin: "0px" }}>
                                                <input type="radio" id="kg" name="kg" value={"kg"}
                                                    // style={{ color: postCommodityPayload.payment_type === "L/C" ? "#25282B" : "#FFFFFF" }}
                                                    checked={counterOfferPrice.seller_co_price_unit === "kg"}
                                                />
                                                <label htmlFor="kg" className="radio_ps"
                                                    style={{
                                                        background: counterOfferPrice.seller_co_price_unit === "kg" ? "#247BC7" : "#FFFFFF",
                                                        color: counterOfferPrice.seller_co_price_unit !== "kg" ? "#25282B" : "#FFFFFF"
                                                    }}
                                                    onClick={(ev) => this.handleInputRadioChange(ev, "kg", "seller_co_price_unit")} >kg</label>

                                                <input type="radio" id="Quintal" name="Quintal"
                                                    // style={{ color: counterOfferPrice.payment_type === "Advance payment" ? "#25282B" : "#FFFFFF" }}
                                                    value="Quintal"
                                                    checked={counterOfferPrice.seller_co_price_unit === "Quintal"}
                                                />
                                                <label htmlFor="Quintal" className="radio_ps"
                                                    style={{
                                                        marginLeft: "10px",
                                                        background: counterOfferPrice.seller_co_price_unit === "Quintal" ? "#247BC7" : "#FFFFFF",
                                                        color: counterOfferPrice.seller_co_price_unit !== "Quintal" ? "#25282B" : "#FFFFFF"
                                                    }} onClick={(ev) => this.handleInputRadioChange(ev, "Quintal", "seller_co_price_unit")}>Quintal</label>

                                                <input type="radio" id="MT" name="MT"
                                                    // style={{ color: postCommodityPayload.payment_type === "Advance payment" ? "#25282B" : "#FFFFFF" }}
                                                    value="MT"
                                                    checked={counterOfferPrice.seller_co_price_unit === "MT"}
                                                />
                                                <label htmlFor="MT" className="radio_ps"
                                                    style={{
                                                        marginLeft: "10px",
                                                        background: counterOfferPrice.seller_co_price_unit === "MT" ? "#247BC7" : "#FFFFFF",
                                                        color: counterOfferPrice.seller_co_price_unit !== "MT" ? "#25282B" : "#FFFFFF"
                                                    }} onClick={(ev) => this.handleInputRadioChange(ev, "MT", "seller_co_price_unit")}>MT</label>
                                            </div>
                                            {errorFields["seller_co_price_unit"] ? <div className="inputFrameinputError_txt">Please select an option</div> : ''}
                                        </div>
                                    </div>


                                    <div className="mt-4 postEnquiry_title">Payment Preference</div>
                                    {/* <div className="mt-4">

                                        <TextField
                                            select
                                            id="seller_co_payment_type"
                                            name="seller_co_payment_type"
                                            label="Payment type"
                                            error={errorFields["seller_co_payment_type"] ? true : false}
                                            helperText={errorFields["seller_co_payment_type"] ? "This detail cannot be empty" : ''}
                                            type="text"
                                            style={{ width: '50%' }}
                                            value={counterOfferPrice.seller_co_payment_type}
                                            onChange={this.handleInputChange.bind(this)}

                                            InputProps={{
                                                classes: {
                                                    root: classes.customInput,
                                                },
                                            }}
                                            variant="outlined"
                                        >
                                            {["L/C", "Advance payment"].map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div> */}
                                    <div className="free_search mt-4">
                                        <div className="radio_toolbar">
                                            <input type="radio" id="L/C" name="L/C" value={"L/C"}
                                                // style={{ color: postCommodityPayload.payment_type === "L/C" ? "#25282B" : "#FFFFFF" }}
                                                checked={counterOfferPrice.seller_co_payment_type === "L/C"}
                                            />
                                            <label htmlFor="L/C" className="radio_ps"
                                                style={{
                                                    background: counterOfferPrice.seller_co_payment_type === "L/C" ? "#247BC7" : "#FFFFFF",
                                                    color: counterOfferPrice.seller_co_payment_type !== "L/C" ? "#25282B" : "#FFFFFF"
                                                }}
                                                onClick={(ev) => this.handleInputRadioChange(ev, "L/C", "seller_co_payment_type")} x>L/c</label>



                                            <input type="radio" id="Advance payment" name="Advance payment"
                                                // style={{ color: counterOfferPrice.payment_type === "Advance payment" ? "#25282B" : "#FFFFFF" }}
                                                value="Advance payment"
                                                checked={counterOfferPrice.seller_co_payment_type === "Advance payment"}
                                            />
                                            <label htmlFor="Advance payment" className="radio_ps"
                                                style={{
                                                    marginLeft: "10px",
                                                    background: counterOfferPrice.seller_co_payment_type === "Advance payment" ? "#247BC7" : "#FFFFFF",
                                                    color: counterOfferPrice.seller_co_payment_type !== "Advance payment" ? "#25282B" : "#FFFFFF"
                                                }} onClick={(ev) => this.handleInputRadioChange(ev, "Advance payment", "seller_co_payment_type")}>Advance Payment</label>
                                        </div>
                                        {errorFields["seller_co_payment_type"] ? <div className="inputFrameinputError_txt">Please select an option</div> : ''}
                                    </div>

                                    {counterOfferPrice.seller_co_payment_type &&
                                        counterOfferPrice.seller_co_payment_type === "Advance payment" &&
                                        <div className="mt-3 d_lg_none_sm" style={{ display: "flex" }}>
                                            <TextField
                                                id="seller_co_advance_percentage"
                                                error={errorFields["seller_co_advance_percentage"] ? true : false}
                                                helperText={errorFields["seller_co_advance_percentage"] ? "This detail cannot be empty" : ''}
                                                name="seller_co_advance_percentage"
                                                label="Advance payment percentage"
                                                type="number"

                                                value={counterOfferPrice.seller_co_advance_percentage}
                                                onChange={this.handleInputChange.bind(this)}
                                                InputProps={{
                                                    classes: {
                                                        root: classes.customInput,
                                                    },
                                                    inputProps: { min: 1, max: 100 }
                                                }}
                                                className=""
                                                style={{ width: '100%' }}
                                                // fullWidth
                                                // helperText="Some important text"
                                                variant="outlined"
                                            />

                                        </div>}

                                    {counterOfferPrice.seller_co_payment_type &&
                                        counterOfferPrice.seller_co_payment_type === "Advance payment" &&
                                        <div className="mt-3 d575_none" style={{ display: "flex" }}>
                                            <TextField
                                                id="seller_co_advance_percentage"
                                                error={errorFields["seller_co_advance_percentage"] ? true : false}
                                                helperText={errorFields["seller_co_advance_percentage"] ? "This detail cannot be empty" : ''}
                                                name="seller_co_advance_percentage"
                                                label="Advance payment percentage"
                                                type="number"

                                                value={counterOfferPrice.seller_co_advance_percentage}
                                                onChange={this.handleInputChange.bind(this)}
                                                InputProps={{
                                                    classes: {
                                                        root: classes.customInput,
                                                    },
                                                    inputProps: { min: 1, max: 100 }
                                                }}
                                                className=""
                                                style={{ width: '59%' }}
                                                // fullWidth
                                                // helperText="Some important text"
                                                variant="outlined"
                                            />
                                        </div>}

                                    {showFormError &&
                                        <div style={{
                                            fontFamily: 'Montserrat, sans-serif',
                                            fontSize: "12px",
                                            color: "red",
                                            textAlign: "right"
                                        }}
                                        > Please fill the mandatory fields highlighted above.</div>}

                                    <div className="col-md-12 p-0 mt-4 " style={{ textAlign: "end" }}>
                                        <input type="submit"
                                            disabled={loading}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                console.log(counterOfferPrice);
                                                this.setState({ loading: true }, () => {
                                                    this.makeCounterOffer();
                                                })
                                            }}
                                            className={" mt-3 btn btn-success cus_btn font_r"}
                                            style={{ background: "#198548", padding: "16px 24px" }} value="Send Offer" />
                                        <input type="submit"
                                            disabled={loading}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                console.log(counterOfferPrice);
                                                this.handleDialogCancel(this);
                                                // this.sendEnquiry();
                                            }}
                                            className={" ml-3 mt-3 btn btn-error cus_btn font_r_500"}
                                            style={{ background: "red", color: '#fff', padding: "16px 24px" }} value="Close" />
                                    </div>
                                </DialogContent>
                            </div> :
                            <Loader primaryText="Please wait.." />}
                    </Dialog>

                </Grid>
            </Grid>
        </div >
        );
    }
}

CounterOfferModal.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CounterOfferModal);